import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import Burger from "../component/Burger";
import { NavLink } from "react-router-dom";
import Draggable from "react-draggable";

export default function Oxybiotop() {
    const ref = useRef(null);
    const [x, setX] = useState(null);
    const [scrollObj, setScrollObj] = useState(null);

    useEffect(() => {
        //console.log(x);
        if (ref && !scrollObj) {
            const scroll = new LocomotiveScroll({
                el: ref.current,
                smooth: true,
                direction: "horizontal",
                lerp: 0.05,
                reloadOnContextChange: true,
                tablet: {
                    breakpoint: 1224,
                    lerp: 0.1,
                    multiplier: 3
                },
            });
            scroll.on("scroll", (args) => {
                // Get all current elements : args.currentElements
                setX(args.scroll.x);
                //console.log(args);
            });

            setScrollObj(scroll);
        }
    }, []);

    const [classBig, setClassBig] = useState("");
    const visualizer = document.querySelector(".visualizer");
    const imgVisual = document.querySelector(".img-visual");
    const tabSrc = ["../image/oxybiotop/oxybiotopstart.webp", "../image/oxybiotop/oxybiotoptab.webp", "../image/oxybiotop/oxybiotopplaquette.webp"];
    const [imgCounter, setImgCounter] = useState("");

    const openVisual = e => {
        if (window.innerWidth <= 800) {
            visualizer.style.display = "block";
            setImgCounter(parseInt(e.currentTarget.id) + 1);
            visualizer.setAttribute("id", e.currentTarget.id);
            imgVisual.src = tabSrc[e.currentTarget.id];
        }

    };

    const closeVisual = () => {
        visualizer.style.display = "none";
        setClassBig("");
    };

    const previous = () => {
        if (imgCounter > 1) {
            setImgCounter(imgCounter - 1);
            visualizer.setAttribute("id", imgCounter - 2);
            imgVisual.src = tabSrc[imgCounter - 2];
            setClassBig("");
        }

    };

    const next = () => {
        if (imgCounter < tabSrc.length) {
            setImgCounter(imgCounter + 1);
            visualizer.setAttribute("id", imgCounter);
            imgVisual.src = tabSrc[imgCounter];
            setClassBig("");
        }
    };

    return (
        <div className="projet">
            <div className="visualizer">
                <div className="count">
                    <span>{imgCounter}</span>
                    <span>/</span>
                    <span>{tabSrc.length}</span>
                </div>
                <button className="close" onClick={closeVisual}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.414" height="26.414" viewBox="0 0 26.414 26.414">
                        <g id="Groupe_2" data-name="Groupe 2" transform="translate(-470.293 -91.928)">
                            <line id="Ligne_7" data-name="Ligne 7" x2="25" y2="25" transform="translate(471 92.635)" fill="none" stroke="var(--white)" strokeWidth="2" />
                            <line id="Ligne_8" data-name="Ligne 8" x2="25" y2="25" transform="translate(496 92.635) rotate(90)" fill="none" stroke="var(--white)" strokeWidth="2" />
                        </g>
                    </svg>
                </button>
                <button className="right" onClick={next}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <button className="left" onClick={previous}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <div className="img-ctn">
                    <img className="img-visual" id={classBig} src="../image/poissonpapier.webp"></img>
                </div>
            </div>
            <CustomCursor />
            <Burger />
            <div className="fixed">
                <h1>Oxybiotop</h1>
                <h2>
                    <span className="color green">Oxybiotop développe et commercialise</span> des procédés de restauration de la fertilité biologique des sols.
                </h2>
                <p>Nous avons construit l'identité visuelle et développé les outils de communication print et web de cette PME innovante.</p>
                <div className="nav">
                    <button className="back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                        Retour
                        <div className="back-choice">
                            <NavLink to="../realisations/pommeraie">Projet précédent</NavLink>
                            <NavLink to="../realisations">Galerie principale</NavLink>
                        </div>
                    </button>
                    <NavLink className="forward" to="../realisations/terres-et-cereales">Projet suivant<svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                        <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#fff" />
                    </svg>
                    </NavLink>
                </div>
            </div>
            <div className="scroll-container" data-scroll-container ref={ref}>

                <div className="start-img">
                    <img className="visual" id="0" onClick={openVisual} data-scroll data-scroll-speed={-1} data-scroll-direction="horizontal" src="../image/oxybiotop/oxybiotopstart.webp"></img>
                </div>
                <div className="projet-gallery-ctn">
                    <div className="col elm-center mr-100">
                            <div className="logotype xl" data-scroll data-scroll-speed={0.4} data-scroll-direction="horizontal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="459.262" height="299.07" viewBox="0 0 459.262 299.07">
                                <g id="Groupe_43" data-name="Groupe 43" transform="translate(800.324 -130.59)">
                                    <g id="Groupe_40" data-name="Groupe 40" transform="translate(-635.3 130.59)">
                                        <g id="Groupe_39" data-name="Groupe 39">
                                            <g id="Groupe_22" data-name="Groupe 22" transform="translate(27.541)">
                                                <g id="Groupe_21" data-name="Groupe 21">
                                                    <path id="Tracé_151" data-name="Tracé 151" d="M-535.414,178.081a8.909,8.909,0,0,0,5.958,2.272,8.942,8.942,0,0,0,6.662-2.977l28.488-31.891a8.942,8.942,0,0,0-.711-12.622,8.9,8.9,0,0,0-5.956-2.272,8.942,8.942,0,0,0-6.662,2.98l-28.5,31.9A8.957,8.957,0,0,0-535.414,178.081Z" transform="translate(538.389 -130.59)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Groupe_24" data-name="Groupe 24" transform="translate(52.456 21.901)">
                                                <g id="Groupe_23" data-name="Groupe 23">
                                                    <path id="Tracé_152" data-name="Tracé 152" d="M-501.517,201.93a8.916,8.916,0,0,0,5.956,2.278,8.945,8.945,0,0,0,6.668-2.982l23.174-25.948a8.882,8.882,0,0,0,2.253-6.464,8.835,8.835,0,0,0-2.966-6.156,8.9,8.9,0,0,0-5.951-2.278,8.933,8.933,0,0,0-6.666,2.986l-23.183,25.945a8.881,8.881,0,0,0-2.252,6.46A8.863,8.863,0,0,0-501.517,201.93Z" transform="translate(504.499 -160.38)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Groupe_26" data-name="Groupe 26" transform="translate(0 69.339)">
                                                <g id="Groupe_25" data-name="Groupe 25">
                                                    <path id="Tracé_153" data-name="Tracé 153" d="M-527.7,227.179a8.907,8.907,0,0,0-5.95-2.272,8.944,8.944,0,0,0-6.672,2.984l-33.263,37.252a8.876,8.876,0,0,0-2.257,6.449,8.873,8.873,0,0,0,2.974,6.164,8.951,8.951,0,0,0,5.955,2.271,8.934,8.934,0,0,0,6.653-2.977l33.268-37.249A8.946,8.946,0,0,0-527.7,227.179Z" transform="translate(575.852 -224.907)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Groupe_28" data-name="Groupe 28" transform="translate(43.326 68.599)">
                                                <g id="Groupe_27" data-name="Groupe 27">
                                                    <path id="Tracé_154" data-name="Tracé 154" d="M-433.543,226.165a8.912,8.912,0,0,0-5.941-2.264,8.964,8.964,0,0,0-6.679,2.98l-68.486,76.7a8.818,8.818,0,0,0-2.256,6.425,8.915,8.915,0,0,0,2.969,6.188,8.928,8.928,0,0,0,5.944,2.264,8.941,8.941,0,0,0,6.667-2.983l68.487-76.692A8.951,8.951,0,0,0-433.543,226.165Z" transform="translate(516.919 -223.901)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Groupe_30" data-name="Groupe 30" transform="translate(84.853 112.089)">
                                                <g id="Groupe_29" data-name="Groupe 29">
                                                    <path id="Tracé_155" data-name="Tracé 155" d="M-418.483,285.338a8.944,8.944,0,0,0-5.956-2.28,8.939,8.939,0,0,0-6.666,2.991l-27.066,30.3a8.972,8.972,0,0,0,.717,12.629,8.95,8.95,0,0,0,5.945,2.26,8.929,8.929,0,0,0,6.666-2.983l27.066-30.293a8.9,8.9,0,0,0,2.258-6.466A8.859,8.859,0,0,0-418.483,285.338Z" transform="translate(460.432 -283.058)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Groupe_32" data-name="Groupe 32" transform="translate(4.542 53.373)">
                                                <g id="Groupe_31" data-name="Groupe 31">
                                                    <path id="Tracé_156" data-name="Tracé 156" d="M-566.7,223.06a8.928,8.928,0,0,0,5.958,2.269,8.974,8.974,0,0,0,6.672-2.969l3.795-4.263a8.9,8.9,0,0,0,2.261-6.469,8.888,8.888,0,0,0-2.97-6.16,8.913,8.913,0,0,0-5.942-2.278,8.973,8.973,0,0,0-6.679,3l-3.806,4.264A8.944,8.944,0,0,0-566.7,223.06Z" transform="translate(569.673 -203.19)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Groupe_34" data-name="Groupe 34" transform="translate(82.276 43.719)">
                                                <g id="Groupe_33" data-name="Groupe 33">
                                                    <path id="Tracé_157" data-name="Tracé 157" d="M-460.957,220.042A8.937,8.937,0,0,0-455,222.321a8.935,8.935,0,0,0,6.664-2.982l12.86-14.389a8.961,8.961,0,0,0-.722-12.624,8.922,8.922,0,0,0-5.952-2.269,8.942,8.942,0,0,0-6.669,2.986l-12.855,14.39a8.877,8.877,0,0,0-2.25,6.453A8.856,8.856,0,0,0-460.957,220.042Z" transform="translate(463.937 -190.058)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Groupe_36" data-name="Groupe 36" transform="translate(53.749 79.591)">
                                                <g id="Groupe_35" data-name="Groupe 35">
                                                    <path id="Tracé_158" data-name="Tracé 158" d="M-478.511,241.113a8.924,8.924,0,0,0-5.94-2.26,8.958,8.958,0,0,0-6.677,2.986l-9.341,10.453a8.861,8.861,0,0,0-2.256,6.456,8.883,8.883,0,0,0,2.969,6.156,8.9,8.9,0,0,0,5.955,2.281,8.935,8.935,0,0,0,6.663-2.982l9.34-10.463A8.961,8.961,0,0,0-478.511,241.113Z" transform="translate(502.741 -238.853)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Groupe_38" data-name="Groupe 38" transform="translate(17.199 111.414)">
                                                <g id="Groupe_37" data-name="Groupe 37">
                                                    <path id="Tracé_159" data-name="Tracé 159" d="M-520.342,284.4a8.915,8.915,0,0,0-5.935-2.262,8.962,8.962,0,0,0-6.682,2.988l-17.223,19.291a8.844,8.844,0,0,0-2.261,6.453,8.867,8.867,0,0,0,2.968,6.153,8.911,8.911,0,0,0,5.95,2.269,8.914,8.914,0,0,0,6.658-2.975l17.237-19.3A8.966,8.966,0,0,0-520.342,284.4Z" transform="translate(552.457 -282.14)" fill="#fff" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Groupe_41" data-name="Groupe 41" transform="translate(-800.208 410.897)">
                                        <path id="Tracé_160" data-name="Tracé 160" d="M-792.631,528.51h-7.535V516.1h2.66v9.927h4.876Z" transform="translate(800.166 -512.994)" fill="#fff" />
                                        <path id="Tracé_161" data-name="Tracé 161" d="M-782.8,520.2a6.012,6.012,0,0,0,1.17-1.542,1.512,1.512,0,0,1-1.489-1.507,1.516,1.516,0,0,1,1.508-1.5,1.514,1.514,0,0,1,1.506,1.5,5.385,5.385,0,0,1-1.773,3.829Z" transform="translate(795.652 -512.873)" fill="#fff" />
                                        <path id="Tracé_162" data-name="Tracé 162" d="M-770.829,527.391v-12.41h8.424v2.48h-5.764v2.485h4.876v2.482h-4.876v2.48h5.764v2.483Zm2.375-13.175-.354-.709,3.013-1.633.939,1.256Z" transform="translate(792.397 -511.875)" fill="#fff" />
                                        <path id="Tracé_163" data-name="Tracé 163" d="M-742.364,531.6a6.367,6.367,0,0,1-3.21-2.928,6.289,6.289,0,0,1-1.186.109c-3.264,0-5.675-2.1-5.675-6.635s2.411-6.63,5.675-6.63,5.673,2.089,5.673,6.63c0,2.678-.833,4.5-2.179,5.549a3.811,3.811,0,0,0,2.074,1.792Zm-1.384-9.453c0-3.156-1.224-4.148-3.012-4.148s-3.015.992-3.015,4.148,1.225,4.151,3.015,4.151S-743.749,525.306-743.749,522.149Z" transform="translate(787.525 -512.84)" fill="#fff" />
                                        <path id="Tracé_164" data-name="Tracé 164" d="M-721.615,516.1h2.658v8.279c0,3.047-2.428,4.559-5,4.559s-5-1.513-5-4.559V516.1h2.661v8.438c0,1.473,1.275,1.917,2.339,1.917s2.342-.443,2.342-1.917Z" transform="translate(781.307 -512.994)" fill="#fff" />
                                        <path id="Tracé_165" data-name="Tracé 165" d="M-706.584,528.51V516.1h2.659v12.41Z" transform="translate(775.382 -512.994)" fill="#fff" />
                                        <path id="Tracé_166" data-name="Tracé 166" d="M-686.515,528.51h-7.538V516.1h2.661v9.927h4.878Z" transform="translate(772.064 -512.994)" fill="#fff" />
                                        <path id="Tracé_167" data-name="Tracé 167" d="M-676.283,528.51V516.1h2.66v12.41Z" transform="translate(767.357 -512.994)" fill="#fff" />
                                        <path id="Tracé_168" data-name="Tracé 168" d="M-663.748,528.51V516.1h4.7c2.2,0,4.077,1.237,4.077,3.6a2.76,2.76,0,0,1-1.314,2.516,2.735,2.735,0,0,1,1.668,2.7c0,2.363-1.879,3.6-4.079,3.6Zm4.7-7.446c.725,0,1.418-.146,1.418-1.244,0-1.063-.656-1.241-1.418-1.241h-2.039v2.485Zm.353,4.962c.764,0,1.419-.174,1.419-1.242,0-1.1-.693-1.239-1.419-1.239h-2.392v2.48Z" transform="translate(764.038 -512.994)" fill="#fff" />
                                        <path id="Tracé_169" data-name="Tracé 169" d="M-636.661,528.51l-2.554-4.255h-1.241v4.255h-2.66V516.1h5.056a3.786,3.786,0,0,1,4.077,4.093,3.631,3.631,0,0,1-2.466,3.813l2.822,4.505Zm-1.4-6.737c.725,0,1.416-.179,1.416-1.581s-.656-1.613-1.416-1.613h-2.4v3.194Z" transform="translate(758.574 -512.994)" fill="#fff" />
                                        <path id="Tracé_170" data-name="Tracé 170" d="M-622.454,528.51V516.1h8.422v2.48h-5.762v2.485h4.877v2.482h-4.877v2.48h5.762v2.483Z" transform="translate(753.102 -512.994)" fill="#fff" />
                                        <path id="Tracé_171" data-name="Tracé 171" d="M-582.133,528.51l-5.337-7.1v7.1h-2.661V516.1h1.774l5.336,7.09V516.1h2.661v12.41Z" transform="translate(744.541 -512.994)" fill="#fff" />
                                        <path id="Tracé_172" data-name="Tracé 172" d="M-560.252,528.51l-.9-2.127h-4.876l-.887,2.127h-2.821l5.269-12.41h1.772l5.263,12.41Zm-2.607-6.207-.726-2.621-.726,2.621-.675,1.6h2.8Z" transform="translate(739.141 -512.994)" fill="#fff" />
                                        <path id="Tracé_173" data-name="Tracé 173" d="M-545.636,528.51V518.58h-3.1V516.1h8.868v2.48h-3.1v9.931Z" transform="translate(733.579 -512.994)" fill="#fff" />
                                        <path id="Tracé_174" data-name="Tracé 174" d="M-521.92,516.1h2.658v8.279c0,3.047-2.428,4.559-5,4.559s-5-1.513-5-4.559V516.1h2.661v8.438c0,1.473,1.278,1.917,2.341,1.917s2.341-.443,2.341-1.917Z" transform="translate(728.421 -512.994)" fill="#fff" />
                                        <path id="Tracé_175" data-name="Tracé 175" d="M-500.415,528.51l-2.552-4.255h-1.242v4.255h-2.66V516.1h5.055a3.785,3.785,0,0,1,4.077,4.093,3.63,3.63,0,0,1-2.465,3.813l2.822,4.505Zm-1.4-6.737c.725,0,1.417-.179,1.417-1.581s-.656-1.613-1.417-1.613h-2.4v3.194Z" transform="translate(722.491 -512.994)" fill="#fff" />
                                        <path id="Tracé_176" data-name="Tracé 176" d="M-486.208,528.51V516.1h8.424v2.48h-5.764v2.485h4.874v2.482h-4.874v2.48h5.764v2.483Z" transform="translate(717.019 -512.994)" fill="#fff" />
                                        <path id="Tracé_177" data-name="Tracé 177" d="M-459.314,528.51h-7.539V516.1h2.662v9.927h4.877Z" transform="translate(711.893 -512.994)" fill="#fff" />
                                        <path id="Tracé_178" data-name="Tracé 178" d="M-441.546,528.51h-7.535V516.1h2.659v9.927h4.876Z" transform="translate(707.186 -512.994)" fill="#fff" />
                                        <path id="Tracé_179" data-name="Tracé 179" d="M-431.319,528.51V516.1h8.422v2.48h-5.764v2.485h4.877v2.482h-4.877v2.48h5.764v2.483Z" transform="translate(702.482 -512.994)" fill="#fff" />
                                        <path id="Tracé_180" data-name="Tracé 180" d="M-401.775,528.51v-6.9l-2.66,3.706h-1.775l-2.66-3.706v6.9h-2.659V516.1h1.772l4.435,6.2,4.432-6.2h1.773v12.41Z" transform="translate(697.241 -512.994)" fill="#fff" />
                                        <path id="Tracé_181" data-name="Tracé 181" d="M-385.735,528.51V516.1h8.424v2.48h-5.762v2.485h4.874v2.482h-4.874v2.48h5.762v2.483Z" transform="translate(690.41 -512.994)" fill="#fff" />
                                        <path id="Tracé_182" data-name="Tracé 182" d="M-357.946,528.51l-5.335-7.1v7.1h-2.662V516.1h1.773l5.337,7.09V516.1h2.661v12.41Z" transform="translate(685.169 -512.994)" fill="#fff" />
                                        <path id="Tracé_183" data-name="Tracé 183" d="M-341.989,528.51V518.58h-3.1V516.1h8.869v2.48h-3.106v9.931Z" transform="translate(679.647 -512.994)" fill="#fff" />
                                        <path id="Tracé_184" data-name="Tracé 184" d="M-312.935,528.51V516.1h3.741c3.547,0,6.171,1.948,6.171,6.2s-2.624,6.207-6.171,6.207Zm3.688-2.483c2.183,0,3.566-.921,3.566-3.724,0-2.781-1.382-3.724-3.566-3.724h-1.029v7.447Z" transform="translate(671.13 -512.994)" fill="#fff" />
                                        <path id="Tracé_185" data-name="Tracé 185" d="M-284.065,516.1h2.657v8.279c0,3.047-2.426,4.559-5,4.559s-5-1.513-5-4.559V516.1h2.659v8.438c0,1.473,1.278,1.917,2.341,1.917s2.341-.443,2.341-1.917Z" transform="translate(665.429 -512.994)" fill="#fff" />
                                        <path id="Tracé_186" data-name="Tracé 186" d="M-262.559,528.51l-2.554-4.255h-1.24v4.255h-2.661V516.1h5.054a3.786,3.786,0,0,1,4.077,4.093,3.631,3.631,0,0,1-2.464,3.813l2.822,4.505Zm-1.4-6.737c.726,0,1.417-.179,1.417-1.581s-.658-1.613-1.417-1.613h-2.393v3.194Z" transform="translate(659.498 -512.994)" fill="#fff" />
                                        <path id="Tracé_187" data-name="Tracé 187" d="M-239.956,528.51l-.9-2.127h-4.877l-.887,2.127h-2.819l5.267-12.41h1.773l5.264,12.41Zm-2.608-6.207-.723-2.621-.727,2.621-.676,1.6h2.8Z" transform="translate(654.315 -512.994)" fill="#fff" />
                                        <path id="Tracé_188" data-name="Tracé 188" d="M-225.6,528.51V516.1h4.7c2.2,0,4.079,1.237,4.079,3.6a2.758,2.758,0,0,1-1.315,2.516,2.735,2.735,0,0,1,1.67,2.7c0,2.363-1.88,3.6-4.08,3.6Zm4.7-7.446c.726,0,1.421-.146,1.421-1.244,0-1.063-.656-1.241-1.421-1.241h-2.039v2.485Zm.354,4.962c.765,0,1.418-.174,1.418-1.242,0-1.1-.691-1.239-1.418-1.239h-2.393v2.48Z" transform="translate(648.001 -512.994)" fill="#fff" />
                                        <path id="Tracé_189" data-name="Tracé 189" d="M-197.452,528.51h-7.535V516.1h2.657v9.927h4.879Z" transform="translate(642.542 -512.994)" fill="#fff" />
                                        <path id="Tracé_190" data-name="Tracé 190" d="M-187.223,528.51V516.1h8.425v2.48h-5.764v2.485h4.874v2.482h-4.874v2.48h5.764v2.483Z" transform="translate(637.837 -512.994)" fill="#fff" />
                                    </g>
                                    <g id="Groupe_42" data-name="Groupe 42" transform="translate(-800.324 331.659)">
                                        <path id="Tracé_191" data-name="Tracé 191" d="M-748.457,412.815c-5.5-5.788-12.828-8.723-21.777-8.723-9.007,0-16.336,2.938-21.779,8.723-5.4,5.686-8.2,13.073-8.311,22.023.113,8.877,2.909,16.261,8.3,21.937,5.452,5.793,12.782,8.736,21.789,8.736,8.949,0,16.278-2.939,21.789-8.738,5.283-5.62,8.075-12.987,8.3-22.047C-740.37,425.82-743.161,418.45-748.457,412.815Zm-9.037,35.2c-3.077,3.469-7.244,5.156-12.74,5.156s-9.663-1.686-12.748-5.168c-3.056-3.411-4.54-7.731-4.54-13.2s1.484-9.792,4.549-13.216c3.077-3.474,7.241-5.161,12.74-5.161s9.662,1.687,12.747,5.169c3.058,3.416,4.542,7.737,4.542,13.208S-754.429,444.592-757.494,448.017Z" transform="translate(800.324 -404.092)" fill="#fff" />
                                        <path id="Tracé_192" data-name="Tracé 192" d="M-687.729,434.858l16.538-20.408a6.292,6.292,0,0,0,1.168-3.781,5.857,5.857,0,0,0-1.89-4.281,6.432,6.432,0,0,0-4.55-1.77,5.536,5.536,0,0,0-4.667,2.344l-14.106,18.053L-709.263,407.2a6.034,6.034,0,0,0-5-2.586,6.4,6.4,0,0,0-4.668,1.809,6.054,6.054,0,0,0-1.811,4.36,6.579,6.579,0,0,0,1.341,4.1l16.287,20.055-16.512,20.3a6.416,6.416,0,0,0-1.27,3.935,5.939,5.939,0,0,0,1.8,4.271,6.127,6.127,0,0,0,4.439,1.816,6.183,6.183,0,0,0,4.86-2.5l14.12-18.066,14.26,17.921a6.5,6.5,0,0,0,5.493,2.644,5.675,5.675,0,0,0,4.321-2.012,6.311,6.311,0,0,0,1.654-4.229,6.149,6.149,0,0,0-1.341-3.946Z" transform="translate(779.289 -404.231)" fill="#fff" />
                                        <path id="Tracé_193" data-name="Tracé 193" d="M-600.986,404.618a6.239,6.239,0,0,0-5.276,3.028L-620.1,427.779-633.9,407.7a6.276,6.276,0,0,0-5.313-3.085A6.251,6.251,0,0,0-644,406.551a6.218,6.218,0,0,0-1.691,4.273,7.294,7.294,0,0,0,1.153,3.993l18,25.231V458.9a6.179,6.179,0,0,0,1.929,4.554,6.4,6.4,0,0,0,4.512,1.807,6.268,6.268,0,0,0,4.509-1.845,6.246,6.246,0,0,0,1.851-4.516v-18.85l18.186-25.4a7.213,7.213,0,0,0,1.045-3.83,6.227,6.227,0,0,0-1.692-4.278A6.258,6.258,0,0,0-600.986,404.618Z" transform="translate(759.372 -404.231)" fill="#fff" />
                                        <path id="Tracé_194" data-name="Tracé 194" d="M-530.306,433.527a15.148,15.148,0,0,0,4.654-11.474,15.681,15.681,0,0,0-5.4-12.318c-3.472-3.044-8.465-4.588-14.844-4.588h-18.3a6.164,6.164,0,0,0-4.549,1.924,6.379,6.379,0,0,0-1.811,4.513v47.07a6.229,6.229,0,0,0,1.845,4.507,6.24,6.24,0,0,0,4.515,1.854h18.767c7.589,0,13.378-1.638,17.2-4.857a14.686,14.686,0,0,0,4.321-5.971,18.834,18.834,0,0,0,1.282-6.858,17.131,17.131,0,0,0-1.082-6.315A16.087,16.087,0,0,0-530.306,433.527Zm-15.744,7.139c3.947,0,6.894.566,8.759,1.684.976.587,2.1,1.6,2.1,4.285a6.388,6.388,0,0,1-.5,2.586c-1.134,2.858-6.3,3.46-10.435,3.46h-11.63V440.667Zm7.536-15.558c-.831,2.292-3.941,3.455-9.24,3.455h-10V417.482h11.319c2.918,0,5.118.538,6.542,1.6a4.039,4.039,0,0,1,1.753,3.515A8.2,8.2,0,0,1-538.514,425.108Z" transform="translate(739.473 -404.371)" fill="#fff" />
                                        <path id="Tracé_195" data-name="Tracé 195" d="M-495.9,406.554a6.376,6.376,0,0,0-1.814,4.516v47.843a6.23,6.23,0,0,0,1.85,4.512,6.245,6.245,0,0,0,4.511,1.849,6.4,6.4,0,0,0,4.51-1.807,6.176,6.176,0,0,0,1.93-4.554V411.07a6.294,6.294,0,0,0-1.889-4.549A6.465,6.465,0,0,0-495.9,406.554Z" transform="translate(720.182 -404.244)" fill="#fff" />
                                        <path id="Tracé_196" data-name="Tracé 196" d="M-443.088,404.092c-9.007,0-16.337,2.938-21.779,8.723-5.4,5.686-8.2,13.073-8.314,22.023.116,8.877,2.909,16.261,8.3,21.937,5.451,5.793,12.782,8.736,21.79,8.736,8.948,0,16.277-2.939,21.789-8.738,5.279-5.62,8.076-12.987,8.3-22.047-.223-8.907-3.016-16.277-8.314-21.912C-426.816,407.027-434.142,404.092-443.088,404.092Zm12.737,43.925c-3.074,3.469-7.244,5.156-12.737,5.156s-9.666-1.686-12.75-5.168c-3.058-3.411-4.541-7.731-4.541-13.2s1.484-9.792,4.552-13.216c3.073-3.474,7.241-5.161,12.739-5.161s9.663,1.687,12.749,5.169c3.053,3.416,4.54,7.737,4.54,13.208S-427.286,444.592-430.35,448.017Z" transform="translate(713.685 -404.092)" fill="#fff" />
                                        <path id="Tracé_197" data-name="Tracé 197" d="M-343.539,411.353a6.1,6.1,0,0,0-1.812-4.4,6.087,6.087,0,0,0-4.4-1.808h-38.615a5.968,5.968,0,0,0-4.4,1.85,6.166,6.166,0,0,0-1.731,4.355,6.019,6.019,0,0,0,1.773,4.357,6.03,6.03,0,0,0,4.358,1.773h12.943V459.04a6.229,6.229,0,0,0,1.85,4.512,6.248,6.248,0,0,0,4.514,1.849,6.38,6.38,0,0,0,4.507-1.807,6.176,6.176,0,0,0,1.933-4.554V417.482h12.868a6.175,6.175,0,0,0,4.353-1.731A5.956,5.956,0,0,0-343.539,411.353Z" transform="translate(692.846 -404.371)" fill="#fff" />
                                        <path id="Tracé_198" data-name="Tracé 198" d="M-298.164,404.092c-9.005,0-16.335,2.938-21.776,8.723-5.4,5.686-8.2,13.073-8.311,22.023.112,8.877,2.908,16.261,8.3,21.937,5.452,5.793,12.782,8.736,21.787,8.736,8.95,0,16.277-2.939,21.79-8.738,5.283-5.62,8.077-12.987,8.3-22.047-.222-8.907-3.016-16.277-8.309-21.912C-281.887,407.027-289.214,404.092-298.164,404.092Zm12.74,43.925c-3.074,3.469-7.241,5.156-12.74,5.156s-9.664-1.686-12.748-5.168c-3.054-3.411-4.542-7.731-4.542-13.2s1.488-9.792,4.553-13.216c3.073-3.474,7.241-5.161,12.737-5.161s9.666,1.687,12.748,5.169c3.059,3.416,4.543,7.737,4.543,13.208S-282.356,444.592-285.424,448.017Z" transform="translate(675.303 -404.092)" fill="#fff" />
                                        <path id="Tracé_199" data-name="Tracé 199" d="M-198.082,411.6c-4.161-4.28-10.308-6.45-18.266-6.45h-17.679a6.172,6.172,0,0,0-4.551,1.924,6.392,6.392,0,0,0-1.811,4.513V459.04a6.232,6.232,0,0,0,1.85,4.512,6.252,6.252,0,0,0,4.512,1.849,6.383,6.383,0,0,0,4.506-1.807,6.168,6.168,0,0,0,1.933-4.554V445.319h11.24c7.957,0,14.1-2.171,18.238-6.425a18.575,18.575,0,0,0,5.338-13.662A18.553,18.553,0,0,0-198.082,411.6Zm-7.489,13.634a6.543,6.543,0,0,1-1.906,5.058c-1.813,1.736-5.032,2.615-9.567,2.615h-10.544V417.559h10.544a20.327,20.327,0,0,1,6.225.781C-207.19,419.552-205.571,421.678-205.571,425.232Z" transform="translate(652.034 -404.371)" fill="#fff" />
                                    </g>
                                </g>
                                </svg>
                            </div>
                    </div>
                    <div className="col elm-center mr-100">
                            <div className="logotype xxl" data-scroll data-scroll-speed={0.4} data-scroll-direction="horizontal">
                            <svg xmlns="http://www.w3.org/2000/svg" width="497.484" height="346.594" viewBox="0 0 497.484 346.594">
                                <g id="Groupe_53" data-name="Groupe 53" transform="translate(951.593 -1000.117)">
                                    <g id="Groupe_46" data-name="Groupe 46" transform="translate(-948.732 1000.117)">
                                        <g id="Groupe_45" data-name="Groupe 45">
                                            <g id="Groupe_44" data-name="Groupe 44">
                                                <path id="Tracé_200" data-name="Tracé 200" d="M-749.215,1052.9c-6.4-1.536-9.416-2.549-9.416-5.2,0-3.492,4.543-4.736,8.794-4.736a28.514,28.514,0,0,1,9.341,1.557,5.1,5.1,0,0,0,1.883.409,5.108,5.108,0,0,0,5.029-5.156,5,5,0,0,0-3.43-4.884,38.727,38.727,0,0,0-13.457-2.238c-5.656,0-10.446,1.542-13.852,4.46a14.312,14.312,0,0,0-5.064,11.032c0,10.719,10.34,13.367,17.889,15.3,5.472,1.4,9.06,2.471,9.06,4.484,0,1.313,0,4.8-8.6,4.8a28.077,28.077,0,0,1-11.479-2.385l-.015-.007-.015-.007a5.452,5.452,0,0,0-2.186-.455,5.07,5.07,0,0,0-5.093,5.156,5.417,5.417,0,0,0,3.308,4.9,40.387,40.387,0,0,0,16.052,3.236c11.026,0,18.725-6.11,18.725-14.858,0-11.217-10.1-13.638-17.469-15.407" transform="translate(873.534 -1013.576)" fill="#938c90" />
                                                <path id="Tracé_201" data-name="Tracé 201" d="M-692.294,1033.189a5.384,5.384,0,0,0-5.346,5.41v39.123a5.352,5.352,0,0,0,5.346,5.346,5.384,5.384,0,0,0,5.41-5.346V1038.6a5.416,5.416,0,0,0-5.41-5.41" transform="translate(843.667 -1013.799)" fill="#938c90" />
                                                <path id="Tracé_202" data-name="Tracé 202" d="M-643.051,1071.995h-18.242v-33.4a5.416,5.416,0,0,0-5.41-5.41,5.384,5.384,0,0,0-5.346,5.41v37.664a6.5,6.5,0,0,0,6.487,6.488h22.511a5.384,5.384,0,0,0,5.41-5.346,5.416,5.416,0,0,0-5.41-5.41" transform="translate(833.08 -1013.799)" fill="#938c90" />
                                                <path id="Tracé_203" data-name="Tracé 203" d="M-600.725,1033.189a5.384,5.384,0,0,0-5.346,5.41v39.123a5.352,5.352,0,0,0,5.346,5.346,5.385,5.385,0,0,0,5.41-5.346V1038.6a5.416,5.416,0,0,0-5.41-5.41" transform="translate(805.783 -1013.799)" fill="#938c90" />
                                                <path id="Tracé_204" data-name="Tracé 204" d="M-549.709,1044.1a5.132,5.132,0,0,0,5.219-5.155,5.226,5.226,0,0,0-5.219-5.22h-25.427a5.384,5.384,0,0,0-5.346,5.41v38.488a5.352,5.352,0,0,0,5.346,5.346h25.427a5.132,5.132,0,0,0,5.219-5.156,5.225,5.225,0,0,0-5.219-5.219h-20.018v-9.09h17.735a5.133,5.133,0,0,0,5.22-5.156,5.226,5.226,0,0,0-5.22-5.22h-17.735V1044.1Z" transform="translate(795.197 -1014.023)" fill="#29abd8" />
                                                <path id="Tracé_205" data-name="Tracé 205" d="M-482.686,1037.309a6.421,6.421,0,0,0-5.972-4.12h-.634a6.418,6.418,0,0,0-5.97,4.11l-16.173,38.182a5.745,5.745,0,0,0-.514,2.24,5.229,5.229,0,0,0,5.346,5.346,5.415,5.415,0,0,0,5.005-3.255l.012-.027.011-.028,3.483-8.357h18.234l3.483,8.358.011.027.012.027a5.415,5.415,0,0,0,5,3.255,5.229,5.229,0,0,0,5.346-5.346,5.771,5.771,0,0,0-.519-2.25Zm-1.465,23.843H-493.8l4.826-11.617Z" transform="translate(766.844 -1013.799)" fill="#29abd8" />
                                                <path id="Tracé_206" data-name="Tracé 206" d="M-396.777,1033.189a5.384,5.384,0,0,0-5.346,5.41v25.236c0,6.425-4.99,9.3-9.935,9.3s-9.936-2.876-9.936-9.3V1038.6a5.415,5.415,0,0,0-5.409-5.41,5.384,5.384,0,0,0-5.346,5.41v25.046c0,12.959,10.408,19.74,20.691,19.74s20.691-6.781,20.691-19.74V1038.6a5.416,5.416,0,0,0-5.41-5.41" transform="translate(734.077 -1013.799)" fill="#29abd8" />
                                                <path id="Tracé_207" data-name="Tracé 207" d="M-919.571,1025.923a4.853,4.853,0,0,0,3.239,1.233,4.843,4.843,0,0,0,3.617-1.615l15.486-17.329a4.865,4.865,0,0,0-.389-6.858,4.852,4.852,0,0,0-3.234-1.238,4.873,4.873,0,0,0-3.622,1.62l-15.487,17.335a4.869,4.869,0,0,0,.389,6.851" transform="translate(936.152 -1000.117)" fill="#29abd8" />
                                                <path id="Tracé_208" data-name="Tracé 208" d="M-896.477,1042.994a4.852,4.852,0,0,0,3.239,1.238,4.847,4.847,0,0,0,3.622-1.618l12.594-14.1A4.811,4.811,0,0,0-875.8,1025a4.782,4.782,0,0,0-1.609-3.343,4.846,4.846,0,0,0-3.234-1.238,4.849,4.849,0,0,0-3.623,1.625l-12.593,14.1a4.819,4.819,0,0,0-1.231,3.507,4.854,4.854,0,0,0,1.614,3.349" transform="translate(926.601 -1008.516)" fill="#29abd8" />
                                                <path id="Tracé_209" data-name="Tracé 209" d="M-920.544,1065.619a4.851,4.851,0,0,0-3.239-1.234,4.848,4.848,0,0,0-3.623,1.62l-18.075,20.246a4.8,4.8,0,0,0-1.225,3.5,4.811,4.811,0,0,0,1.619,3.351,4.832,4.832,0,0,0,3.234,1.234,4.871,4.871,0,0,0,3.617-1.618l18.075-20.244a4.864,4.864,0,0,0-.383-6.856" transform="translate(946.714 -1026.706)" fill="#29abd8" />
                                                <path id="Tracé_210" data-name="Tracé 210" d="M-861.253,1064.927a4.824,4.824,0,0,0-3.228-1.231,4.889,4.889,0,0,0-3.628,1.619L-905.326,1107a4.812,4.812,0,0,0-1.225,3.489,4.829,4.829,0,0,0,1.614,3.362,4.857,4.857,0,0,0,3.229,1.233,4.844,4.844,0,0,0,3.622-1.627l37.216-41.67a4.861,4.861,0,0,0-.383-6.858" transform="translate(930.101 -1026.421)" fill="#29abd8" />
                                                <path id="Tracé_211" data-name="Tracé 211" d="M-845.269,1105.246a4.873,4.873,0,0,0-3.239-1.239,4.844,4.844,0,0,0-3.623,1.625l-14.7,16.464a4.875,4.875,0,0,0,.383,6.861,4.866,4.866,0,0,0,3.234,1.228,4.854,4.854,0,0,0,3.622-1.62l14.7-16.464a4.829,4.829,0,0,0,1.231-3.513,4.827,4.827,0,0,0-1.609-3.343" transform="translate(914.175 -1043.098)" fill="#29abd8" />
                                                <path id="Tracé_212" data-name="Tracé 212" d="M-940.888,1060.385a4.858,4.858,0,0,0,3.239,1.234,4.88,4.88,0,0,0,3.627-1.615l2.062-2.315a4.843,4.843,0,0,0,1.225-3.52,4.8,4.8,0,0,0-1.609-3.342,4.86,4.86,0,0,0-3.229-1.238,4.9,4.9,0,0,0-3.633,1.624l-2.067,2.32a4.863,4.863,0,0,0,.383,6.851" transform="translate(944.971 -1020.585)" fill="#29abd8" />
                                                <path id="Tracé_213" data-name="Tracé 213" d="M-868.834,1056.926a4.835,4.835,0,0,0,3.239,1.242,4.859,4.859,0,0,0,3.617-1.622l6.989-7.818a4.867,4.867,0,0,0-.394-6.862,4.857,4.857,0,0,0-3.228-1.233,4.866,4.866,0,0,0-3.628,1.627l-6.984,7.815a4.8,4.8,0,0,0-1.219,3.508,4.8,4.8,0,0,0,1.609,3.343" transform="translate(915.163 -1016.879)" fill="#29abd8" />
                                                <path id="Tracé_214" data-name="Tracé 214" d="M-883.731,1075.114a4.849,4.849,0,0,0-3.229-1.228,4.868,4.868,0,0,0-3.628,1.622l-5.071,5.684a4.764,4.764,0,0,0-1.225,3.508,4.791,4.791,0,0,0,1.614,3.342,4.821,4.821,0,0,0,3.234,1.24,4.865,4.865,0,0,0,3.623-1.619l5.071-5.684a4.87,4.87,0,0,0-.389-6.864" transform="translate(926.102 -1030.637)" fill="#29abd8" />
                                                <path id="Tracé_215" data-name="Tracé 215" d="M-913.321,1104.613a4.852,4.852,0,0,0-3.228-1.228,4.885,4.885,0,0,0-3.633,1.62l-9.355,10.484a4.819,4.819,0,0,0-1.231,3.508,4.836,4.836,0,0,0,1.614,3.346,4.842,4.842,0,0,0,3.234,1.231,4.836,4.836,0,0,0,3.617-1.619l9.365-10.484a4.869,4.869,0,0,0-.383-6.856" transform="translate(940.119 -1042.841)" fill="#29abd8" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Groupe_49" data-name="Groupe 49" transform="translate(-949.387 1129.506)">
                                        <g id="Groupe_48" data-name="Groupe 48">
                                            <g id="Groupe_47" data-name="Groupe 47">
                                                <path id="Tracé_216" data-name="Tracé 216" d="M-749.884,1273.39c-6.4-1.535-9.416-2.549-9.416-5.2,0-3.492,4.543-4.736,8.794-4.736a28.51,28.51,0,0,1,9.341,1.557,5.1,5.1,0,0,0,1.883.409,5.108,5.108,0,0,0,5.029-5.156,5,5,0,0,0-3.429-4.884,38.724,38.724,0,0,0-13.457-2.238c-5.656,0-10.446,1.542-13.852,4.46a14.312,14.312,0,0,0-5.063,11.032c0,10.719,10.339,13.367,17.888,15.3,5.472,1.4,9.06,2.471,9.06,4.484,0,1.313,0,4.8-8.6,4.8a28.077,28.077,0,0,1-11.479-2.385l-.016-.007-.015-.006a5.456,5.456,0,0,0-2.187-.455,5.07,5.07,0,0,0-5.092,5.156,5.416,5.416,0,0,0,3.307,4.9,40.39,40.39,0,0,0,16.052,3.236c11.026,0,18.726-6.11,18.726-14.858,0-11.217-10.1-13.638-17.469-15.407" transform="translate(874.465 -1234.187)" fill="#938c90" />
                                                <path id="Tracé_217" data-name="Tracé 217" d="M-692.962,1253.683a5.385,5.385,0,0,0-5.346,5.41v39.123a5.353,5.353,0,0,0,5.346,5.346,5.385,5.385,0,0,0,5.41-5.346v-39.123a5.416,5.416,0,0,0-5.41-5.41" transform="translate(844.598 -1234.411)" fill="#938c90" />
                                                <path id="Tracé_218" data-name="Tracé 218" d="M-643.718,1292.489h-18.243v-33.4a5.416,5.416,0,0,0-5.409-5.41,5.384,5.384,0,0,0-5.346,5.41v37.665a6.5,6.5,0,0,0,6.487,6.488h22.511a5.385,5.385,0,0,0,5.41-5.346,5.416,5.416,0,0,0-5.41-5.409" transform="translate(834.01 -1234.411)" fill="#938c90" />
                                                <path id="Tracé_219" data-name="Tracé 219" d="M-601.393,1253.683a5.384,5.384,0,0,0-5.346,5.41v39.123a5.352,5.352,0,0,0,5.346,5.346,5.385,5.385,0,0,0,5.41-5.346v-39.123a5.416,5.416,0,0,0-5.41-5.41" transform="translate(806.714 -1234.411)" fill="#938c90" />
                                                <path id="Tracé_220" data-name="Tracé 220" d="M-560.654,1273.689c-6.4-1.536-9.416-2.549-9.416-5.2,0-3.492,4.542-4.736,8.794-4.736a28.5,28.5,0,0,1,9.341,1.557,5.1,5.1,0,0,0,1.883.409,5.108,5.108,0,0,0,5.029-5.156,5,5,0,0,0-3.428-4.884,38.733,38.733,0,0,0-13.459-2.238c-5.656,0-10.446,1.542-13.851,4.46a14.312,14.312,0,0,0-5.064,11.032c0,10.72,10.339,13.367,17.888,15.3,5.473,1.4,9.06,2.47,9.06,4.484,0,1.313,0,4.8-8.6,4.8a28.077,28.077,0,0,1-11.479-2.385l-.016-.007-.015-.007a5.464,5.464,0,0,0-2.186-.454,5.071,5.071,0,0,0-5.093,5.156,5.414,5.414,0,0,0,3.308,4.9,40.379,40.379,0,0,0,16.052,3.237c11.026,0,18.726-6.11,18.726-14.858,0-11.217-10.1-13.638-17.469-15.407" transform="translate(796.177 -1234.311)" fill="#a6b74a" />
                                                <path id="Tracé_221" data-name="Tracé 221" d="M-486.842,1253.442c-14.515,0-24.46,10.13-24.748,25.209v.095c.288,15.078,10.233,25.209,24.748,25.209s24.46-10.13,24.749-25.208v-.1c-.289-15.078-10.235-25.208-24.749-25.208m0,40.137c-8.5,0-13.993-5.841-13.993-14.881s5.492-14.881,13.993-14.881,13.994,5.841,13.994,14.881-5.493,14.881-13.994,14.881" transform="translate(767.349 -1234.311)" fill="#a6b74a" />
                                                <path id="Tracé_222" data-name="Tracé 222" d="M-390.5,1292.788h-18.242v-33.4a5.416,5.416,0,0,0-5.41-5.41,5.384,5.384,0,0,0-5.346,5.41v37.664a6.5,6.5,0,0,0,6.488,6.488h22.51a5.384,5.384,0,0,0,5.41-5.346,5.416,5.416,0,0,0-5.41-5.41" transform="translate(729.248 -1234.535)" fill="#a6b74a" />
                                                <path id="Tracé_223" data-name="Tracé 223" d="M-920.687,1246.617a4.853,4.853,0,0,0,3.239,1.234,4.842,4.842,0,0,0,3.617-1.615l15.486-17.329a4.865,4.865,0,0,0-.388-6.858,4.849,4.849,0,0,0-3.234-1.238,4.875,4.875,0,0,0-3.623,1.619l-15.486,17.335a4.869,4.869,0,0,0,.388,6.851" transform="translate(937.268 -1220.811)" fill="#a6b74a" />
                                                <path id="Tracé_224" data-name="Tracé 224" d="M-897.593,1263.688a4.851,4.851,0,0,0,3.239,1.238,4.847,4.847,0,0,0,3.623-1.618l12.594-14.1a4.816,4.816,0,0,0,1.22-3.513,4.787,4.787,0,0,0-1.609-3.343,4.847,4.847,0,0,0-3.234-1.238,4.848,4.848,0,0,0-3.622,1.625l-12.593,14.1a4.819,4.819,0,0,0-1.231,3.507,4.853,4.853,0,0,0,1.615,3.349" transform="translate(927.717 -1229.21)" fill="#a6b74a" />
                                                <path id="Tracé_225" data-name="Tracé 225" d="M-921.66,1286.313a4.85,4.85,0,0,0-3.239-1.234,4.846,4.846,0,0,0-3.623,1.62l-18.075,20.246a4.8,4.8,0,0,0-1.225,3.5,4.813,4.813,0,0,0,1.619,3.351,4.831,4.831,0,0,0,3.233,1.234,4.869,4.869,0,0,0,3.617-1.618l18.076-20.244a4.864,4.864,0,0,0-.383-6.856" transform="translate(947.83 -1247.4)" fill="#a6b74a" />
                                                <path id="Tracé_226" data-name="Tracé 226" d="M-862.369,1285.621a4.827,4.827,0,0,0-3.229-1.231,4.887,4.887,0,0,0-3.627,1.619l-37.216,41.684a4.811,4.811,0,0,0-1.225,3.489,4.829,4.829,0,0,0,1.614,3.362,4.86,4.86,0,0,0,3.229,1.233,4.844,4.844,0,0,0,3.622-1.627l37.216-41.67a4.861,4.861,0,0,0-.383-6.858" transform="translate(931.217 -1247.115)" fill="#a6b74a" />
                                                <path id="Tracé_227" data-name="Tracé 227" d="M-846.385,1325.941a4.873,4.873,0,0,0-3.239-1.239,4.844,4.844,0,0,0-3.623,1.625l-14.7,16.464a4.875,4.875,0,0,0,.383,6.861,4.868,4.868,0,0,0,3.234,1.228,4.852,4.852,0,0,0,3.621-1.62l14.7-16.464a4.834,4.834,0,0,0,1.231-3.513,4.827,4.827,0,0,0-1.609-3.343" transform="translate(915.291 -1263.793)" fill="#a6b74a" />
                                                <path id="Tracé_228" data-name="Tracé 228" d="M-942,1281.079a4.858,4.858,0,0,0,3.239,1.233,4.883,4.883,0,0,0,3.628-1.615l2.061-2.315a4.844,4.844,0,0,0,1.225-3.52,4.8,4.8,0,0,0-1.609-3.342,4.856,4.856,0,0,0-3.228-1.238,4.9,4.9,0,0,0-3.633,1.624l-2.067,2.321a4.863,4.863,0,0,0,.383,6.851" transform="translate(946.087 -1241.279)" fill="#a6b74a" />
                                                <path id="Tracé_229" data-name="Tracé 229" d="M-869.95,1277.621a4.835,4.835,0,0,0,3.239,1.242,4.859,4.859,0,0,0,3.617-1.622l6.988-7.818a4.866,4.866,0,0,0-.394-6.862,4.854,4.854,0,0,0-3.228-1.233,4.864,4.864,0,0,0-3.628,1.628l-6.984,7.815a4.8,4.8,0,0,0-1.219,3.508,4.8,4.8,0,0,0,1.609,3.343" transform="translate(916.279 -1237.574)" fill="#a6b74a" />
                                                <path id="Tracé_230" data-name="Tracé 230" d="M-884.847,1295.809a4.85,4.85,0,0,0-3.229-1.228,4.866,4.866,0,0,0-3.627,1.622l-5.072,5.684A4.764,4.764,0,0,0-898,1305.4a4.792,4.792,0,0,0,1.614,3.342,4.822,4.822,0,0,0,3.234,1.239,4.866,4.866,0,0,0,3.623-1.619l5.071-5.684a4.87,4.87,0,0,0-.389-6.864" transform="translate(927.218 -1251.331)" fill="#a6b74a" />
                                                <path id="Tracé_231" data-name="Tracé 231" d="M-914.437,1325.307a4.854,4.854,0,0,0-3.229-1.228,4.886,4.886,0,0,0-3.633,1.62l-9.355,10.484a4.822,4.822,0,0,0-1.231,3.508,4.84,4.84,0,0,0,1.615,3.346,4.845,4.845,0,0,0,3.234,1.23,4.838,4.838,0,0,0,3.617-1.619l9.365-10.484a4.869,4.869,0,0,0-.383-6.856" transform="translate(941.236 -1263.535)" fill="#a6b74a" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Groupe_52" data-name="Groupe 52" transform="translate(-951.593 1258.05)">
                                        <g id="Groupe_51" data-name="Groupe 51">
                                            <g id="Groupe_50" data-name="Groupe 50">
                                                <path id="Tracé_232" data-name="Tracé 232" d="M-752.923,1493.124c-6.4-1.535-9.416-2.549-9.416-5.2,0-3.492,4.542-4.736,8.794-4.736a28.513,28.513,0,0,1,9.341,1.557,5.1,5.1,0,0,0,1.883.409,5.108,5.108,0,0,0,5.029-5.156,5,5,0,0,0-3.429-4.884,38.728,38.728,0,0,0-13.458-2.238c-5.656,0-10.446,1.542-13.852,4.46a14.312,14.312,0,0,0-5.063,11.032c0,10.719,10.339,13.367,17.888,15.3,5.472,1.4,9.06,2.471,9.06,4.484,0,1.313,0,4.8-8.6,4.8a28.075,28.075,0,0,1-11.479-2.385l-.016-.007-.015-.007a5.457,5.457,0,0,0-2.187-.455,5.07,5.07,0,0,0-5.092,5.156,5.416,5.416,0,0,0,3.307,4.9,40.39,40.39,0,0,0,16.052,3.236c11.026,0,18.726-6.11,18.726-14.858,0-11.217-10.1-13.638-17.469-15.407" transform="translate(877.929 -1453.64)" fill="#938c90" />
                                                <path id="Tracé_233" data-name="Tracé 233" d="M-696,1473.416a5.385,5.385,0,0,0-5.346,5.41v39.123a5.353,5.353,0,0,0,5.346,5.346,5.385,5.385,0,0,0,5.41-5.346v-39.123a5.416,5.416,0,0,0-5.41-5.41" transform="translate(848.061 -1453.863)" fill="#938c90" />
                                                <path id="Tracé_234" data-name="Tracé 234" d="M-646.758,1512.222H-665v-33.4a5.416,5.416,0,0,0-5.41-5.41,5.384,5.384,0,0,0-5.346,5.41v37.664a6.5,6.5,0,0,0,6.487,6.488h22.511a5.384,5.384,0,0,0,5.41-5.346,5.415,5.415,0,0,0-5.41-5.41" transform="translate(837.474 -1453.863)" fill="#938c90" />
                                                <path id="Tracé_235" data-name="Tracé 235" d="M-604.432,1473.416a5.384,5.384,0,0,0-5.346,5.41v39.123a5.352,5.352,0,0,0,5.346,5.346,5.385,5.385,0,0,0,5.41-5.346v-39.123a5.416,5.416,0,0,0-5.41-5.41" transform="translate(810.177 -1453.863)" fill="#938c90" />
                                                <path id="Tracé_236" data-name="Tracé 236" d="M-553.536,1484.055a5.132,5.132,0,0,0,5.219-5.156,5.226,5.226,0,0,0-5.219-5.22h-25.427a5.384,5.384,0,0,0-5.346,5.41v38.489a5.352,5.352,0,0,0,5.346,5.346h25.427a5.133,5.133,0,0,0,5.219-5.156,5.225,5.225,0,0,0-5.219-5.219h-20.017v-9.09h17.734a5.134,5.134,0,0,0,5.22-5.156,5.226,5.226,0,0,0-5.22-5.22h-17.734v-9.028Z" transform="translate(799.64 -1453.972)" fill="#6a6667" />
                                                <path id="Tracé_237" data-name="Tracé 237" d="M-486.827,1511.946h-18.242v-33.4a5.416,5.416,0,0,0-5.41-5.41,5.384,5.384,0,0,0-5.346,5.41v37.664a6.5,6.5,0,0,0,6.487,6.488h22.511a5.384,5.384,0,0,0,5.41-5.346,5.416,5.416,0,0,0-5.41-5.41" transform="translate(771.307 -1453.749)" fill="#6a6667" />
                                                <path id="Tracé_238" data-name="Tracé 238" d="M-419.276,1484.055a5.132,5.132,0,0,0,5.219-5.156,5.226,5.226,0,0,0-5.219-5.22H-444.7a5.384,5.384,0,0,0-5.346,5.41v38.489a5.352,5.352,0,0,0,5.346,5.346h25.426a5.133,5.133,0,0,0,5.219-5.156,5.225,5.225,0,0,0-5.219-5.219h-20.017v-9.09h17.735a5.133,5.133,0,0,0,5.22-5.156,5.226,5.226,0,0,0-5.22-5.22h-17.735v-9.028Z" transform="translate(744.094 -1453.972)" fill="#6a6667" />
                                                <path id="Tracé_239" data-name="Tracé 239" d="M-335.521,1478.55a5.2,5.2,0,0,0-5.346-5.41,5.523,5.523,0,0,0-5.024,3.491l-.008.02-.008.019-12.587,31.388-12.587-31.388-.008-.019-.008-.02a5.523,5.523,0,0,0-5.024-3.491,5.2,5.2,0,0,0-5.346,5.41,5.671,5.671,0,0,0,.369,2.006l15.67,38,.015.035.015.035a6.767,6.767,0,0,0,6.207,4.39h1.395a6.766,6.766,0,0,0,6.206-4.39l.015-.035.015-.035,15.662-37.982a5.69,5.69,0,0,0,.379-2.029" transform="translate(715.721 -1453.749)" fill="#6a6667" />
                                                <path id="Tracé_240" data-name="Tracé 240" d="M-292.944,1477.26a6.42,6.42,0,0,0-5.973-4.12h-.634a6.415,6.415,0,0,0-5.969,4.111l-16.174,38.181a5.754,5.754,0,0,0-.515,2.24,5.229,5.229,0,0,0,5.346,5.346,5.413,5.413,0,0,0,5-3.255l.012-.027.011-.027,3.483-8.358h18.235l3.483,8.358.011.027.012.027a5.413,5.413,0,0,0,5,3.255,5.229,5.229,0,0,0,5.346-5.346,5.77,5.77,0,0,0-.519-2.25Zm-1.465,23.843h-9.65l4.826-11.617Z" transform="translate(691.204 -1453.749)" fill="#6a6667" />
                                                <path id="Tracé_241" data-name="Tracé 241" d="M-207.806,1493.207H-220.3a5.038,5.038,0,0,0-5.092,5.093,5.038,5.038,0,0,0,5.092,5.092h7.97v7.444a20.58,20.58,0,0,1-8.6,1.838c-8.963,0-14.754-5.791-14.754-14.754,0-9.1,5.533-14.754,14.438-14.754a20.57,20.57,0,0,1,11.156,3.2l.077.045.08.04a5.424,5.424,0,0,0,2.447.58,5.07,5.07,0,0,0,5.156-5.093,5.145,5.145,0,0,0-2.473-4.417,29.756,29.756,0,0,0-17.077-4.924c-14.231,0-24.559,10.649-24.559,25.32,0,14.833,10.177,25.193,24.749,25.193a34.75,34.75,0,0,0,15.618-3.8,6.767,6.767,0,0,0,3.8-6.17v-14.394a5.477,5.477,0,0,0-5.536-5.536" transform="translate(659.858 -1453.525)" fill="#6a6667" />
                                                <path id="Tracé_242" data-name="Tracé 242" d="M-133.668,1512.549h-20.017v-9.09h17.735a5.133,5.133,0,0,0,5.22-5.156,5.226,5.226,0,0,0-5.22-5.22h-17.735v-9.028h20.017a5.132,5.132,0,0,0,5.219-5.156,5.226,5.226,0,0,0-5.219-5.22h-25.426a5.384,5.384,0,0,0-5.346,5.41v38.489a5.353,5.353,0,0,0,5.346,5.346h25.426a5.133,5.133,0,0,0,5.219-5.156,5.225,5.225,0,0,0-5.219-5.219" transform="translate(625.933 -1453.972)" fill="#6a6667" />
                                                <path id="Tracé_243" data-name="Tracé 243" d="M-924.45,1465.87a4.853,4.853,0,0,0,3.239,1.233,4.842,4.842,0,0,0,3.617-1.615l15.486-17.328a4.865,4.865,0,0,0-.388-6.858,4.849,4.849,0,0,0-3.234-1.238,4.874,4.874,0,0,0-3.623,1.619l-15.486,17.334a4.869,4.869,0,0,0,.388,6.851" transform="translate(941.031 -1440.065)" fill="#6a6667" />
                                                <path id="Tracé_244" data-name="Tracé 244" d="M-901.356,1482.941a4.851,4.851,0,0,0,3.239,1.238,4.848,4.848,0,0,0,3.623-1.618l12.594-14.1a4.816,4.816,0,0,0,1.22-3.513,4.786,4.786,0,0,0-1.609-3.343,4.847,4.847,0,0,0-3.235-1.238,4.848,4.848,0,0,0-3.622,1.625l-12.593,14.1a4.819,4.819,0,0,0-1.231,3.507,4.854,4.854,0,0,0,1.615,3.349" transform="translate(931.48 -1448.464)" fill="#6a6667" />
                                                <path id="Tracé_245" data-name="Tracé 245" d="M-925.423,1505.566a4.85,4.85,0,0,0-3.239-1.234,4.847,4.847,0,0,0-3.623,1.62l-18.075,20.246a4.8,4.8,0,0,0-1.225,3.5,4.812,4.812,0,0,0,1.619,3.351,4.832,4.832,0,0,0,3.233,1.234,4.87,4.87,0,0,0,3.617-1.617l18.076-20.244a4.864,4.864,0,0,0-.383-6.856" transform="translate(951.593 -1466.653)" fill="#6a6667" />
                                                <path id="Tracé_246" data-name="Tracé 246" d="M-866.132,1504.874a4.827,4.827,0,0,0-3.229-1.231,4.887,4.887,0,0,0-3.627,1.619l-37.217,41.684a4.81,4.81,0,0,0-1.225,3.489,4.829,4.829,0,0,0,1.613,3.362,4.86,4.86,0,0,0,3.229,1.233,4.845,4.845,0,0,0,3.622-1.627l37.216-41.67a4.861,4.861,0,0,0-.383-6.858" transform="translate(934.98 -1466.368)" fill="#6a6667" />
                                                <path id="Tracé_247" data-name="Tracé 247" d="M-850.148,1545.193a4.873,4.873,0,0,0-3.239-1.239,4.844,4.844,0,0,0-3.623,1.625l-14.7,16.464a4.875,4.875,0,0,0,.383,6.861,4.869,4.869,0,0,0,3.234,1.228,4.852,4.852,0,0,0,3.621-1.62l14.7-16.464a4.834,4.834,0,0,0,1.231-3.513,4.827,4.827,0,0,0-1.609-3.343" transform="translate(919.054 -1483.046)" fill="#6a6667" />
                                                <path id="Tracé_248" data-name="Tracé 248" d="M-945.767,1500.332a4.858,4.858,0,0,0,3.239,1.234,4.883,4.883,0,0,0,3.628-1.615l2.061-2.315a4.844,4.844,0,0,0,1.225-3.519,4.8,4.8,0,0,0-1.609-3.343,4.857,4.857,0,0,0-3.228-1.238,4.9,4.9,0,0,0-3.633,1.624l-2.067,2.32a4.863,4.863,0,0,0,.383,6.851" transform="translate(949.85 -1460.532)" fill="#6a6667" />
                                                <path id="Tracé_249" data-name="Tracé 249" d="M-873.713,1496.874a4.835,4.835,0,0,0,3.239,1.242,4.859,4.859,0,0,0,3.617-1.622l6.989-7.818a4.866,4.866,0,0,0-.394-6.862,4.854,4.854,0,0,0-3.227-1.233,4.864,4.864,0,0,0-3.629,1.627l-6.984,7.815a4.8,4.8,0,0,0-1.219,3.508,4.8,4.8,0,0,0,1.609,3.343" transform="translate(920.042 -1456.827)" fill="#6a6667" />
                                                <path id="Tracé_250" data-name="Tracé 250" d="M-888.61,1515.061a4.851,4.851,0,0,0-3.229-1.228,4.867,4.867,0,0,0-3.627,1.622l-5.072,5.684a4.764,4.764,0,0,0-1.225,3.508,4.791,4.791,0,0,0,1.614,3.342,4.821,4.821,0,0,0,3.234,1.24,4.866,4.866,0,0,0,3.623-1.619l5.071-5.684a4.87,4.87,0,0,0-.389-6.864" transform="translate(930.981 -1470.584)" fill="#6a6667" />
                                                <path id="Tracé_251" data-name="Tracé 251" d="M-918.2,1544.56a4.853,4.853,0,0,0-3.229-1.228,4.886,4.886,0,0,0-3.633,1.62l-9.355,10.484a4.822,4.822,0,0,0-1.231,3.508,4.84,4.84,0,0,0,1.615,3.346,4.845,4.845,0,0,0,3.234,1.231,4.838,4.838,0,0,0,3.617-1.619l9.365-10.484a4.869,4.869,0,0,0-.383-6.856" transform="translate(944.999 -1482.789)" fill="#6a6667" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            </div>
                    </div>
                    <div className="col mr-100">
                        <div className="img-c">
                            <img className="visual w-80 full-height" id="1" onClick={openVisual} src="../image/oxybiotop/oxybiotoptab.webp"></img>
                        </div>
                    </div>
                    <div className="col mr-200">
                        <div className="img-c">
                            <img className="visual w-60 full-height" id="2" onClick={openVisual} src="../image/oxybiotop/oxybiotopplaquette.webp"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}