import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import Burger from "../component/Burger";
import { NavLink } from "react-router-dom";
import Draggable from "react-draggable";

export default function LesMiels() {
    const ref = useRef(null);
    const [x, setX] = useState(null);
    const [scrollObj, setScrollObj] = useState(null);

    useEffect(() => {
        //console.log(x);
        if (ref && !scrollObj) {
            const scroll = new LocomotiveScroll({
                el: ref.current,
                smooth: true,
                direction: "horizontal",
                lerp: 0.05,
                reloadOnContextChange: true,
                tablet: {
                    breakpoint: 1224,
                    lerp: 0.1,
                    multiplier: 3
                },
            });
            scroll.on("scroll", (args) => {
                // Get all current elements : args.currentElements
                setX(args.scroll.x);
                //console.log(args);
            });

            setScrollObj(scroll);
        }
    }, []);

    const [classBig, setClassBig] = useState("");
    const visualizer = document.querySelector(".visualizer");
    const imgVisual = document.querySelector(".img-visual");
    const tabSrc = ["../image/miels/mielsstart.webp", "../image/miels/mielssite.webp", "../image/miels/mielssite2.webp", "../image/miels/mielspots.webp"];
    const [imgCounter, setImgCounter] = useState("");

    const openVisual = e => {
        if (window.innerWidth <= 800) {
            visualizer.style.display = "block";
            setImgCounter(parseInt(e.currentTarget.id) + 1);
            visualizer.setAttribute("id", e.currentTarget.id);
            imgVisual.src = tabSrc[e.currentTarget.id];
        }

    };

    const closeVisual = () => {
        visualizer.style.display = "none";
        setClassBig("");
    };

    const previous = () => {
        if (imgCounter > 1) {
            setImgCounter(imgCounter - 1);
            visualizer.setAttribute("id", imgCounter - 2);
            imgVisual.src = tabSrc[imgCounter - 2];
            setClassBig("");
        }

    };

    const next = () => {
        if (imgCounter < tabSrc.length) {
            setImgCounter(imgCounter + 1);
            visualizer.setAttribute("id", imgCounter);
            imgVisual.src = tabSrc[imgCounter];
            setClassBig("");
        }
    };

    return (
        <div className="projet">
            <div className="visualizer">
                <div className="count">
                    <span>{imgCounter}</span>
                    <span>/</span>
                    <span>{tabSrc.length}</span>
                </div>
                <button className="close" onClick={closeVisual}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.414" height="26.414" viewBox="0 0 26.414 26.414">
                        <g id="Groupe_2" data-name="Groupe 2" transform="translate(-470.293 -91.928)">
                            <line id="Ligne_7" data-name="Ligne 7" x2="25" y2="25" transform="translate(471 92.635)" fill="none" stroke="var(--white)" strokeWidth="2" />
                            <line id="Ligne_8" data-name="Ligne 8" x2="25" y2="25" transform="translate(496 92.635) rotate(90)" fill="none" stroke="var(--white)" strokeWidth="2" />
                        </g>
                    </svg>
                </button>
                <button className="right" onClick={next}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                        <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                    </svg>
                </button>
                <button className="left" onClick={previous}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                        <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                    </svg>
                </button>
                <div className="img-ctn">
                    <img className="img-visual" id={classBig} src="../image/poissonpapier.webp"></img>
                </div>
            </div>
            <CustomCursor />
            <Burger />
            <div className="fixed">
                <h1>Les Miels de Bretagne</h1>
                <h2>
                    <span className="color yellow">Soucieux du respect de leur cheptel,</span> de la qualité de leur production et de la transparence de leur méthodes, les apiculteurs bretons ont créé la marque "Les Miels de Bretagne".
                </h2>
                <p>Les imagiciens ont accompagné la genèse de cette organisation professionnelle en créant son identité visuelle et sa charte d'utilisation ainsi que son site internet.</p>
                <div className="nav">
                    <button className="back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                        Retour
                        <div className="back-choice">
                            <NavLink to="../realisations/eaux-et-vilaine">Projet précédent</NavLink>
                            <NavLink to="../realisations">Galerie principale</NavLink>
                        </div>
                    </button>
                    <NavLink className="forward" to="../realisations/liffre-cormier-communaute">Projet suivant<svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                        <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#fff" />
                    </svg>
                    </NavLink>
                </div>
            </div>
            <div className="scroll-container" data-scroll-container ref={ref}>

                <div className="start-img">
                    <img className="visual" id="0" onClick={openVisual} data-scroll data-scroll-speed={-1} data-scroll-direction="horizontal" src="../image/miels/mielsstart.webp"></img>
                </div>
                <div className="projet-gallery-ctn">
                    <div className="col elm-center mr-100">
                        <div className="logotype" data-scroll data-scroll-speed={0.4} data-scroll-direction="horizontal">
                            <svg xmlns="http://www.w3.org/2000/svg" width="242.697" height="242.698" viewBox="0 0 242.697 242.698">
                                <g id="Groupe_208" data-name="Groupe 208" transform="translate(-853 -431)">
                                    <g id="Groupe_117" data-name="Groupe 117" transform="translate(853 431)">
                                        <g id="Groupe_115" data-name="Groupe 115" transform="translate(0 0)">
                                            <path id="Tracé_464" data-name="Tracé 464" d="M518.574,366.806a121.868,121.868,0,0,0,13.665-17.128c-81.519-17.359-123.491,14.155-202.723-.1a121.925,121.925,0,0,0,18.935,22.339C411.894,378.408,452.318,356.64,518.574,366.806Z" transform="translate(-309.561 -161.553)" />
                                            <path id="Tracé_465" data-name="Tracé 465" d="M542.565,353.233a121.347,121.347,0,1,0-221.146-4.051C409.866,368.516,451.764,330.665,542.565,353.233Z" transform="translate(-311.56 -179.86)" />
                                            <path id="Tracé_466" data-name="Tracé 466" d="M369.322,386.3a121.426,121.426,0,0,0,127.788-8.385C449.285,374.208,414.189,386.243,369.322,386.3Z" transform="translate(-305.13 -157.89)" />
                                            <path id="Tracé_467" data-name="Tracé 467" d="M533.25,351.363a121.025,121.025,0,0,0,8.327-14.753c-90.8-22.568-132.7,15.283-221.146-4.051a121.023,121.023,0,0,0,10.1,18.706C409.76,365.518,451.731,334,533.25,351.363Z" transform="translate(-310.572 -163.237)" fill="#fff" />
                                            <path id="Tracé_468" data-name="Tracé 468" d="M516.677,364.828c-66.256-10.166-106.68,11.6-170.123,5.113a121.673,121.673,0,0,0,25.3,18.048c44.866-.061,79.963-12.1,127.788-8.385A122.045,122.045,0,0,0,516.677,364.828Z" transform="translate(-307.664 -159.574)" fill="#fff" />
                                        </g>
                                        <g id="Groupe_116" data-name="Groupe 116" transform="translate(30.991 36.4)">
                                            <path id="Tracé_469" data-name="Tracé 469" d="M412.071,238.013c-.117,0-1.282-.039-1.517-.039a1.511,1.511,0,0,0-1.011.388,13.547,13.547,0,0,1-7.43,1.906c-5.951,0-8.285-3.5-8.285-8.752V214.01c0-.817.038-1.011.543-1.011.272,0,1.984.156,1.984.156s2.413-.156,2.568-.156c.39,0,.467.156.467.583s-.077,1.361-.077,2.489v14.161c0,3.111,1.011,5.134,4.162,5.134,3.306,0,4.668-1.867,4.668-4.319,0-.31.078-.5.389-.5.35,0,1.322.038,1.322.038l2.217-.077c.429,0,.467,0,.467.467,0,.582-.077,3.034-.077,3.034s.116,3.034.116,3.578C412.576,237.856,412.421,238.013,412.071,238.013Z" transform="translate(-333.392 -212.571)" fill="#fff" />
                                            <path id="Tracé_470" data-name="Tracé 470" d="M432.834,237.783c-.311,0-1.439-.079-1.517-.079a1.43,1.43,0,0,0-1.05.428,13.082,13.082,0,0,1-7.819,2.178c-6.069,0-9.686-3.151-9.686-8.675,0-4.044,2.256-5.757,2.256-7.079,0-1.05-.7-2.334-.7-4.162,0-5.174,4.24-7.78,9.143-7.78a14.622,14.622,0,0,1,6.767,1.284c.39.233.467.312.467.661a19.473,19.473,0,0,1-.506,1.945s-.038,2.374-.31,2.374a1.617,1.617,0,0,1-.623-.311,8.38,8.38,0,0,0-4.862-1.4c-2.8,0-4.706,1.088-4.706,3.656,0,1.711.622,2.451,2.294,2.451h6.109c.545,0,.584.078.584.583,0,.271-.078,1.711-.078,1.711s.116,1.556.116,1.945c0,.467-.194.467-.621.467h-7.2c-1.635,0-2.335,1.206-2.335,2.723,0,2.917,1.6,4.863,5.252,4.863,3.346,0,5.019-2.1,5.019-3.93,0-.349.039-.467.35-.467.271,0,1.555.04,1.555.04s1.79-.079,2.063-.079c.35,0,.428.039.428.467,0,.585-.078,2.646-.078,2.646s.117,2.489.117,3.112A.374.374,0,0,1,432.834,237.783Z" transform="translate(-331.284 -212.614)" fill="#fff" />
                                            <path id="Tracé_471" data-name="Tracé 471" d="M444.618,240.311c-2.957,0-5.174-.622-6.808-1.906a1.049,1.049,0,0,0-.739-.194h-1.711c-.428,0-.583-.039-.583-.506,0-.35.156-3.228.156-3.228s-.039-2.256-.039-2.956c0-.429.193-.506.544-.506.233,0,1.634.116,1.634.116s1.4-.116,1.556-.116c.35,0,.506.116.506.506,0,2.412,1.789,4.279,4.863,4.279,2.762,0,4.241-1.361,4.241-3.424,0-5.873-13.032-2.566-13.032-11.552,0-5.213,4.162-8.208,9.492-8.208a15.03,15.03,0,0,1,7.663,1.829c.546.349.7.543.7.894a8.878,8.878,0,0,1-.778,2.063s-.194,1.594-.31,2.06c-.078.312-.2.429-.429.429a.4.4,0,0,1-.349-.157,10.22,10.22,0,0,0-6.654-2.6c-2.138,0-3.7,1.088-3.7,2.877,0,5.213,13.3,2.024,13.3,11.982C454.149,236.81,450.687,240.311,444.618,240.311Z" transform="translate(-328.833 -212.614)" fill="#fff" />
                                            <path id="Tracé_472" data-name="Tracé 472" d="M379.545,276.986a7.677,7.677,0,0,1-3.307.856c-2.956,0-4.785-1.555-4.785-5.486v-9.335c0-5.64-.894-8.053-4.045-8.053-2.957,0-3.617,2.45-3.617,6.769v15.015c0,.661-.039.7-.624.7-.543,0-2.333-.154-2.333-.154s-1.557.154-1.945.154c-.468,0-.585-.078-.585-.778V261.737c0-4.746-1.089-6.769-4.162-6.769-3.191,0-3.773,3.228-3.773,8.169v12.177c0,.349.039,1.166.039,1.438,0,.583-.079.7-.467.7-.2,0-2.646-.154-2.646-.154s-1.829.154-2.139.154c-.233,0-.35-.154-.35-.545,0-.428.039-1.05.039-1.517v-12.72a12.739,12.739,0,0,1,1.323-6.068,1.823,1.823,0,0,0,.272-.934c0-.39-.311-.74-.778-1.207l-1.907-1.867a.876.876,0,0,1-.31-.583.469.469,0,0,1,.272-.506l2.217-1.478a.916.916,0,0,1,.506-.195,1,1,0,0,1,.7.272l1.711,1.4a1.263,1.263,0,0,0,.818.35,1.792,1.792,0,0,0,.893-.272,8.8,8.8,0,0,1,4.358-1.439,7.219,7.219,0,0,1,4.9,1.828,2.239,2.239,0,0,0,1.4.544,2.349,2.349,0,0,0,1.324-.506,8.609,8.609,0,0,1,5.523-1.867c4.862,0,8.87,2.684,8.87,11.631v9.3c0,1.4.272,1.945,1.167,1.945.739,0,1.089-.545,1.517-.545.2,0,.233.078.233.428s-.078,1.906-.078,1.906.156,1.167.156,1.556A.558.558,0,0,1,379.545,276.986Z" transform="translate(-339.001 -208.47)" fill="#fff" />
                                            <path id="Tracé_473" data-name="Tracé 473" d="M384.318,277.383c-.311,0-2.528-.154-2.528-.154s-2.335.349-2.723.349c-.272,0-.272-.194-.272-.545V251.709c0-.934.039-1.206.584-1.206.272,0,2.295.117,2.295.117s1.946-.156,2.256-.156c.389,0,.467.078.467.506s-.078,1.128-.078,2.374v19.644c0,2.451.233,3.578.233,4.045C384.552,277.344,384.513,277.383,384.318,277.383Z" transform="translate(-335.065 -208.4)" fill="#fff" />
                                            <path id="Tracé_474" data-name="Tracé 474" d="M408.317,275.283c-.311,0-1.438-.079-1.517-.079a1.431,1.431,0,0,0-1.05.428,13.081,13.081,0,0,1-7.818,2.178c-6.069,0-9.686-3.151-9.686-8.675,0-4.044,2.255-5.757,2.255-7.079,0-1.05-.7-2.334-.7-4.163,0-5.173,4.241-7.779,9.143-7.779a14.612,14.612,0,0,1,6.768,1.284c.389.233.467.311.467.661a19.5,19.5,0,0,1-.5,1.945s-.04,2.374-.312,2.374a1.617,1.617,0,0,1-.622-.311,8.38,8.38,0,0,0-4.862-1.4c-2.8,0-4.708,1.089-4.708,3.657,0,1.712.622,2.451,2.295,2.451h6.108c.545,0,.583.078.583.583,0,.271-.078,1.711-.078,1.711s.117,1.556.117,1.945c0,.467-.194.467-.622.467h-7.2c-1.634,0-2.334,1.206-2.334,2.723,0,2.917,1.595,4.863,5.251,4.863,3.346,0,5.019-2.1,5.019-3.93,0-.349.039-.467.35-.467.272,0,1.556.04,1.556.04s1.789-.079,2.061-.079c.351,0,.428.039.428.467,0,.585-.077,2.646-.077,2.646s.116,2.489.116,3.112A.373.373,0,0,1,408.317,275.283Z" transform="translate(-334.013 -208.439)" fill="#fff" />
                                            <path id="Tracé_475" data-name="Tracé 475" d="M428.5,275.513c-.117,0-1.285-.039-1.518-.039a1.508,1.508,0,0,0-1.01.388,13.548,13.548,0,0,1-7.43,1.906c-5.952,0-8.285-3.5-8.285-8.752V251.51c0-.817.038-1.011.544-1.011.272,0,1.984.154,1.984.154s2.413-.154,2.568-.154c.389,0,.466.154.466.583s-.077,1.361-.077,2.489v14.16c0,3.111,1.011,5.134,4.162,5.134,3.307,0,4.668-1.867,4.668-4.319,0-.31.079-.5.39-.5.349,0,1.321.038,1.321.038L428.5,268c.428,0,.467,0,.467.467,0,.582-.078,3.034-.078,3.034s.117,3.034.117,3.578C429.009,275.356,428.854,275.513,428.5,275.513Z" transform="translate(-331.562 -208.396)" fill="#fff" />
                                            <path id="Tracé_476" data-name="Tracé 476" d="M439.806,277.811c-2.956,0-5.173-.622-6.808-1.906a1.047,1.047,0,0,0-.739-.195h-1.711c-.428,0-.582-.039-.582-.506,0-.35.154-3.228.154-3.228s-.039-2.256-.039-2.956c0-.429.195-.506.545-.506.233,0,1.634.116,1.634.116s1.4-.116,1.556-.116c.351,0,.507.116.507.506,0,2.412,1.789,4.279,4.863,4.279,2.76,0,4.24-1.361,4.24-3.424,0-5.873-13.033-2.566-13.033-11.552,0-5.213,4.163-8.208,9.492-8.208a15.046,15.046,0,0,1,7.664,1.828c.544.35.7.544.7.895a8.864,8.864,0,0,1-.778,2.062s-.195,1.595-.311,2.061c-.078.312-.195.428-.428.428a.4.4,0,0,1-.35-.156,10.219,10.219,0,0,0-6.652-2.6c-2.139,0-3.7,1.088-3.7,2.877,0,5.213,13.3,2.024,13.3,11.982C449.337,274.31,445.875,277.811,439.806,277.811Z" transform="translate(-329.369 -208.439)" fill="#fff" />
                                            <path id="Tracé_477" data-name="Tracé 477" d="M470.936,277.807c-2.956,0-5.562-1.011-7-3.423-.31-.544-.544-.583-1.127-.583h-1.324c-.388,0-.545-.195-.545-.7V252.677c0-.506-.349-.7-.349-1.012,0-.193.154-.388.778-.543a54.422,54.422,0,0,1,9.491-.972c6.263,0,11.087,3.267,11.087,13.926C481.944,273.684,477.043,277.807,470.936,277.807Zm-.817-23.3a14.584,14.584,0,0,0-3.267.389c-.428.117-.545.272-.545.935v12.33c0,3.307,1.128,5.135,4.241,5.135,2.838,0,5.446-2.062,5.446-9.258C475.993,255.711,472.959,254.505,470.119,254.505Z" transform="translate(-325.959 -208.435)" fill="#fff" />
                                            <path id="Tracé_478" data-name="Tracé 478" d="M503.2,275.283c-.311,0-1.439-.079-1.517-.079a1.431,1.431,0,0,0-1.05.428,13.081,13.081,0,0,1-7.818,2.178c-6.069,0-9.687-3.151-9.687-8.675,0-4.044,2.256-5.757,2.256-7.079,0-1.05-.7-2.334-.7-4.163,0-5.173,4.241-7.779,9.143-7.779a14.615,14.615,0,0,1,6.768,1.284c.389.233.466.311.466.661a19.467,19.467,0,0,1-.506,1.945s-.038,2.374-.31,2.374a1.616,1.616,0,0,1-.622-.311,8.385,8.385,0,0,0-4.862-1.4c-2.8,0-4.708,1.089-4.708,3.657,0,1.712.622,2.451,2.295,2.451h6.108c.545,0,.583.078.583.583,0,.271-.078,1.711-.078,1.711s.116,1.556.116,1.945c0,.467-.195.467-.621.467h-7.2c-1.635,0-2.334,1.206-2.334,2.723,0,2.917,1.595,4.863,5.251,4.863,3.346,0,5.019-2.1,5.019-3.93,0-.349.039-.467.35-.467.271,0,1.556.04,1.556.04s1.789-.079,2.062-.079c.351,0,.428.039.428.467,0,.585-.077,2.646-.077,2.646s.116,2.489.116,3.112A.373.373,0,0,1,503.2,275.283Z" transform="translate(-323.45 -208.439)" fill="#fff" />
                                            <path id="Tracé_479" data-name="Tracé 479" d="M349.482,315.295c-3.073,0-5.447-.816-7-2.761-.429-.546-.467-.623-1.09-.623h-1.439c-.311,0-.506-.117-.506-.622V288.96c0-.7.039-.895.233-.895.117,0,.428.116.545.116.583,0,5.329-.427,8.908-.427,5.291,0,8.208,2.295,8.208,6.651a5.959,5.959,0,0,1-2.645,5.135c-.351.194-.506.35-.506.622,0,.233.194.389.543.506,2.528.778,4.124,3.306,4.124,6.38C358.858,312.417,355.162,315.295,349.482,315.295Zm-1.05-23.729a25.759,25.759,0,0,0-3.19.156c-.39.078-.468.233-.468.857v5.407c0,.428.039.621.583.621h2.917c2.063,0,3.657-.894,3.657-3.7A3.183,3.183,0,0,0,348.432,291.566ZM348.2,302.5h-2.762c-.272,0-.312-.35-.506-.35-.118,0-.157.194-.157.972V306.5c0,2.8,1.207,4.784,4.163,4.784,2.567,0,4.045-1.712,4.045-4.512C352.983,303.43,350.8,302.5,348.2,302.5Z" transform="translate(-339.446 -204.249)" fill="#fff" />
                                            <path id="Tracé_480" data-name="Tracé 480" d="M381.215,312.417s.078,1.323.078,1.557a.552.552,0,0,1-.389.582,6.508,6.508,0,0,1-2.956.739c-2.528,0-4.124-1.089-5.445-3.7l-3.23-6.419a1.248,1.248,0,0,0-1.245-.856,11.92,11.92,0,0,0-1.789-.117c-.506,0-.585.272-.585.779v9.335c0,.39-.039.583-.427.583-.351,0-2.3-.154-2.374-.154-.117,0-2.1.154-2.451.154-.233,0-.272-.078-.272-.193,0-.273.233-.7.233-.972V289.115c0-.506.194-.661.467-.7a65.709,65.709,0,0,1,9.492-.66c4.941,0,8.441,2.373,8.441,7.975a7.736,7.736,0,0,1-4.084,7.08c-.35.155-.466.31-.466.467a1.41,1.41,0,0,0,.193.506c.779,1.517,1.867,3.423,2.645,4.822.778,1.362,1.635,1.829,2.374,1.829.934,0,1.284-.428,1.673-.428.194,0,.272.117.272.35C381.371,310.627,381.215,312.417,381.215,312.417Zm-12.176-20.577a19.944,19.944,0,0,0-2.995.232c-.35.04-.545.117-.545.622,0,.583.155,1.439.155,2.528v3.541c0,.934.118,1.322,1.012,1.556a8.259,8.259,0,0,0,2.177.233,3.956,3.956,0,0,0,4.28-4.317C373.123,293.511,371.762,291.839,369.039,291.839Z" transform="translate(-337.143 -204.249)" fill="#fff" />
                                            <path id="Tracé_481" data-name="Tracé 481" d="M401.623,312.783c-.311,0-1.439-.079-1.517-.079a1.43,1.43,0,0,0-1.05.428,13.082,13.082,0,0,1-7.819,2.178c-6.069,0-9.686-3.151-9.686-8.675,0-4.044,2.256-5.757,2.256-7.079,0-1.05-.7-2.334-.7-4.163,0-5.173,4.24-7.779,9.143-7.779a14.607,14.607,0,0,1,6.767,1.284c.39.233.467.311.467.661a19.465,19.465,0,0,1-.506,1.945s-.038,2.374-.31,2.374a1.624,1.624,0,0,1-.623-.311,8.381,8.381,0,0,0-4.862-1.4c-2.8,0-4.706,1.089-4.706,3.657,0,1.711.622,2.45,2.295,2.45h6.108c.545,0,.583.078.583.584,0,.271-.078,1.711-.078,1.711s.116,1.556.116,1.945c0,.467-.195.467-.621.467h-7.2c-1.635,0-2.335,1.206-2.335,2.723,0,2.917,1.6,4.863,5.252,4.863,3.346,0,5.019-2.1,5.019-3.93,0-.349.039-.467.35-.467.271,0,1.555.04,1.555.04s1.79-.079,2.063-.079c.35,0,.428.039.428.467,0,.585-.078,2.646-.078,2.646s.117,2.489.117,3.112A.374.374,0,0,1,401.623,312.783Z" transform="translate(-334.759 -204.264)" fill="#fff" />
                                            <path id="Tracé_482" data-name="Tracé 482" d="M420.476,292.674c-.428,0-1.206-.077-2.412-.077H414.68c-1.011,0-1.129.349-1.129,1.011v14.937c0,1.595.506,1.945,1.129,1.945s1.206-.545,1.555-.545c.2,0,.312.039.312.388v1.751s.233,1.556.233,1.789c0,.311-.078.39-.389.583a9.448,9.448,0,0,1-3.734.856c-3.074,0-4.591-1.595-4.591-4.9V294.93c0-.545.428-1.555.428-1.945,0-.233-.233-.428-.739-.428-.389,0-2.878.04-2.957.04-.582,0-3.19.154-3.5.154-.389,0-.467-.154-.467-.428,0-.311.078-2.1.078-2.1s-.116-1.322-.116-1.634c0-.467.116-.545.621-.545H418.3a7.106,7.106,0,0,0,2.413-.467.221.221,0,0,1,.233.233c0,.428-.079,2.023-.079,2.023s.117,2.061.117,2.373C420.981,292.6,420.865,292.674,420.476,292.674Z" transform="translate(-332.616 -204.268)" fill="#fff" />
                                            <path id="Tracé_483" data-name="Tracé 483" d="M442.837,314.568a7.777,7.777,0,0,1-3.113.7c-2.023,0-3.539-.895-4.28-3.384l-1.166-3.851c-.117-.429-.117-1.674-1.051-1.674-.349,0-1.36.546-1.9.546l-4.046.039c-.895,0-1.167.078-1.439,1.011l-1.439,4.94c-.428,1.517-.233,2.217-.9,2.217-.388,0-2.178-.272-2.178-.272H418.64c-.35,0-.389-.116-.389-.388a13.857,13.857,0,0,1,.661-2.024l6.5-21.162c.856-2.762.389-3.267,2.1-3.267.35,0,2.334.117,2.334.117s2.335-.117,2.529-.117c1.167,0,1.321.039,2.178,2.8l5.407,17.117c.545,1.711,1.05,2.295,1.829,2.295a6.231,6.231,0,0,0,1.089-.272c.194,0,.271.117.271.428,0,.389-.116,1.635-.116,1.635s.193,1.594.193,1.945C443.225,314.334,443.186,314.373,442.837,314.568Zm-10-12.72-1.75-5.8c-.818-2.723-1.09-2.88-1.557-2.88-.388,0,.039.624-.621,2.88l-1.674,5.873a1.259,1.259,0,0,0-.078.311c0,.272.195.389.622.389h4.591c.428,0,.544-.077.544-.272A1.437,1.437,0,0,0,432.839,301.847Z" transform="translate(-330.673 -204.222)" fill="#fff" />
                                            <path id="Tracé_484" data-name="Tracé 484" d="M462.528,303.524v6.147s.077,2.062.077,2.956c0,.506-.156.506-.428.506-.349,0-2.139-.116-2.645-.116a1.61,1.61,0,0,0-1.05.31,10.382,10.382,0,0,1-6.536,1.984c-6.34,0-10.035-3.734-10.035-13.693,0-9.647,4.59-14,10.425-14,2.373,0,4.862.622,6.264,1.867a1.143,1.143,0,0,0,.855.35c.506,0,1.711-.039,1.984-.039.157,0,.272.194.272.35,0,.35-.039,3.034-.039,3.034s.079,2.956.079,3.267c0,.39-.04.467-.389.467-.272,0-1.75-.077-1.75-.077l-1.75.194c-.234,0-.39-.039-.39-.311a4.179,4.179,0,0,0-4.241-4.513c-4.512,0-5.368,4.785-5.368,9.181,0,8.247,2.645,9.258,5.29,9.258,2.957,0,4.357-1.361,4.357-3.268v-.855c0-.662-.233-1.011-.856-1.011h-1.945a6.676,6.676,0,0,0-.895.154s-.272-.116-.272-.233c0-.467.115-1.945.115-1.945s-.038-1.245-.038-1.634c0-.194.077-.35.582-.35h8.169c.468,0,.623.156.623.35A9.382,9.382,0,0,0,462.528,303.524Z" transform="translate(-328.039 -204.264)" fill="#fff" />
                                            <path id="Tracé_485" data-name="Tracé 485" d="M489.157,314.482a7.673,7.673,0,0,1-3.306.856c-3,0-4.785-1.634-4.785-5.6v-8.792c0-5.64-.972-8.481-5.019-8.481-3.929,0-4.9,3.579-4.9,8.442v11.9c0,.349.039,1.166.039,1.438,0,.583-.078.7-.506.7-.193,0-2.217-.154-2.217-.154s-2.1.154-2.45.154c-.272,0-.389-.193-.389-.583,0-.428.039-1.011.039-1.478v-12.72a12.682,12.682,0,0,1,1.089-5.641,4.054,4.054,0,0,0,.428-1.323c0-.428-.233-.778-.739-1.245l-1.673-1.517a3.9,3.9,0,0,1-.544-.622,1.156,1.156,0,0,1-.157-.428c0-.156.079-.272.272-.389l1.6-1.011c.078-.04.817-.507.895-.546a.849.849,0,0,1,.428-.077,1.134,1.134,0,0,1,.661.233l1.829,1.4a1.715,1.715,0,0,0,.972.389,4.418,4.418,0,0,0,1.438-.428,10.129,10.129,0,0,1,4.669-1.322c5.757,0,9.725,2.995,9.725,12.331v8.753c0,1.245.427,1.789,1.089,1.789.778,0,1.206-.545,1.556-.545.233,0,.311.078.311.428s-.117,1.828-.117,1.828.156,1.284.156,1.673C489.546,314.21,489.468,314.289,489.157,314.482Z" transform="translate(-325.573 -204.292)" fill="#fff" />
                                            <path id="Tracé_486" data-name="Tracé 486" d="M508.951,312.783c-.311,0-1.439-.079-1.517-.079a1.432,1.432,0,0,0-1.05.428,13.08,13.08,0,0,1-7.818,2.178c-6.069,0-9.687-3.151-9.687-8.675,0-4.044,2.256-5.757,2.256-7.079,0-1.05-.7-2.334-.7-4.163,0-5.173,4.241-7.779,9.143-7.779a14.614,14.614,0,0,1,6.768,1.284c.389.233.466.311.466.661a19.467,19.467,0,0,1-.506,1.945s-.038,2.374-.31,2.374a1.616,1.616,0,0,1-.622-.311,8.386,8.386,0,0,0-4.862-1.4c-2.8,0-4.707,1.089-4.707,3.657,0,1.711.622,2.45,2.295,2.45h6.108c.544,0,.583.078.583.584,0,.271-.078,1.711-.078,1.711s.116,1.556.116,1.945c0,.467-.195.467-.621.467h-7.2c-1.635,0-2.334,1.206-2.334,2.723,0,2.917,1.595,4.863,5.251,4.863,3.346,0,5.019-2.1,5.019-3.93,0-.349.039-.467.35-.467.271,0,1.556.04,1.556.04s1.789-.079,2.061-.079c.351,0,.428.039.428.467,0,.585-.077,2.646-.077,2.646s.116,2.489.116,3.112A.373.373,0,0,1,508.951,312.783Z" transform="translate(-322.81 -204.264)" fill="#fff" />
                                        </g>
                                    </g>
                                </g>
                            </svg>

                        </div>
                    </div>
                    <div className="col elm-bottom mr-100">
                        <div className="img-c" data-scroll data-scroll-speed={0.2} data-scroll-direction="vertical">
                            <img className="visual w-60" id="1" onClick={openVisual} src="../image/miels/mielssite.webp"></img>
                        </div>
                    </div>
                    <div className="col elm-top mr-200">
                        <div className="img-c" data-scroll data-scroll-speed={-0.2} data-scroll-direction="vertical">
                            <img className="visual w-60" id="2" onClick={openVisual} src="../image/miels/mielssite2.webp"></img>
                        </div>
                    </div>
                    <div className="col elm-bottom mr-200">
                        <div className="logotype xl">
                            <svg xmlns="http://www.w3.org/2000/svg" width="365.658" height="511.674" viewBox="0 0 365.658 511.674">
                                <g id="Groupe_216" data-name="Groupe 216" transform="translate(-3796.441 -430.5)">
                                    <g id="Groupe_215" data-name="Groupe 215" transform="translate(3797 431)">
                                        <path id="Tracé_1092" data-name="Tracé 1092" d="M323.882,257.673v-.683a.664.664,0,0,0-.664-.664H281.331a.664.664,0,0,0-.664.664v.683c0,.367-5.069,9.465-5.069,12.309v50.037a17.156,17.156,0,0,0,17.154,17.156h18.921a17.156,17.156,0,0,0,17.154-17.156V269.982C328.826,267.17,323.882,258.04,323.882,257.673Z" transform="translate(-237.378 -249.412)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <path id="Tracé_1093" data-name="Tracé 1093" d="M279.683,286.141a12.6,12.6,0,0,0,4.417,4.011" transform="translate(-232.041 -210.465)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Tracé_1094" data-name="Tracé 1094" d="M322.783,260.185H278.534a1.747,1.747,0,0,1-1.748-1.746v-2.927c0-.586,2.053-2.184,2.4-2.184h42.65c.3,0,2.7,1.642,2.7,2.276v2.834A1.747,1.747,0,0,1,322.783,260.185Z" transform="translate(-235.825 -253.328)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <line id="Ligne_31" data-name="Ligne 31" x2="51.64" transform="translate(39.013 26.152)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <line id="Ligne_32" data-name="Ligne 32" x2="51.64" transform="translate(39.013 63.988)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <rect id="Rectangle_51" data-name="Rectangle 51" width="43.204" height="2.942" transform="translate(43.231 7.49)" fill="#d98d1b" />
                                        <rect id="Rectangle_52" data-name="Rectangle 52" width="34.854" height="3.817" transform="translate(45.46 451.5)" fill="#d98d1b" />
                                        <ellipse id="Ellipse_8" data-name="Ellipse 8" cx="33.234" cy="1.662" rx="33.234" ry="1.662" transform="translate(31.599 87.507)" fill="#d98d1b" />
                                        <ellipse id="Ellipse_9" data-name="Ellipse 9" cx="33.234" cy="1.662" rx="33.234" ry="1.662" transform="translate(24.248 222.5)" fill="#d98d1b" />
                                        <path id="Tracé_1095" data-name="Tracé 1095" d="M301.367,312.461H282.654a2.979,2.979,0,0,0-2.583,1.492l-9.357,16.206a2.982,2.982,0,0,0,0,2.982l9.357,16.206a2.982,2.982,0,0,0,2.583,1.49h18.713a2.979,2.979,0,0,0,2.583-1.49l9.359-16.206a2.982,2.982,0,0,0,0-2.982l-9.359-16.206A2.976,2.976,0,0,0,301.367,312.461Z" transform="translate(-244.278 -176.083)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <path id="Tracé_1096" data-name="Tracé 1096" d="M301.468,329.2H282.755a2.982,2.982,0,0,0-2.583,1.49l-9.357,16.206a2.986,2.986,0,0,0,0,2.984l9.357,16.206a2.982,2.982,0,0,0,2.583,1.49h18.713a2.982,2.982,0,0,0,2.583-1.49l9.357-16.206a2.986,2.986,0,0,0,0-2.984l-9.357-16.206A2.982,2.982,0,0,0,301.468,329.2Z" transform="translate(-244.146 -154.223)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <path id="Tracé_1097" data-name="Tracé 1097" d="M273.852,337.933l6.049,10.477a1.931,1.931,0,0,0,1.672.964" transform="translate(-239.658 -142.809)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Tracé_1098" data-name="Tracé 1098" d="M315.9,320.778H297.184a2.982,2.982,0,0,0-2.583,1.49l-9.357,16.206a2.986,2.986,0,0,0,0,2.984l9.357,16.206a2.982,2.982,0,0,0,2.583,1.49H315.9a2.982,2.982,0,0,0,2.583-1.49l9.357-16.206a2.986,2.986,0,0,0,0-2.984l-9.357-16.206A2.982,2.982,0,0,0,315.9,320.778Z" transform="translate(-225.298 -165.218)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <ellipse id="Ellipse_10" data-name="Ellipse 10" cx="33.234" cy="1.662" rx="33.234" ry="1.662" transform="translate(19.438 507.851)" fill="#d98d1b" />
                                        <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="28.185" cy="1.662" rx="28.185" ry="1.662" transform="translate(26.033 378.194)" fill="#d98d1b" />
                                        <g id="Groupe_209" data-name="Groupe 209" transform="translate(34.958 273.554)">
                                            <path id="Tracé_1099" data-name="Tracé 1099" d="M316.624,407.214l-30.734-23.3a1.614,1.614,0,0,0-1.9-.058,21.779,21.779,0,0,0-3.639,3.222,1.642,1.642,0,0,0,.2,2.415l32.141,24.361a1.644,1.644,0,0,0,2.38-.461,21.77,21.77,0,0,0,2.117-4.375A1.609,1.609,0,0,0,316.624,407.214Z" transform="translate(-266.691 -356.734)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                            <rect id="Rectangle_53" data-name="Rectangle 53" width="7.669" height="43.715" rx="0.703" transform="translate(5.334 41.215) rotate(-52.84)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                            <path id="Tracé_1100" data-name="Tracé 1100" d="M282.343,412.182a21.76,21.76,0,0,0,14.343,4.405,1.63,1.63,0,0,0,.943-2.922l-20.842-15.8a1.631,1.631,0,0,0-2.56,1.7A21.751,21.751,0,0,0,282.343,412.182Z" transform="translate(-274.183 -338.512)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                            <path id="Tracé_1101" data-name="Tracé 1101" d="M274.891,398.884l29.6,22.431a1.614,1.614,0,0,0,1.764.141,21.741,21.741,0,0,0,4.029-2.929,1.639,1.639,0,0,0-.1-2.516l-31.725-24.045a1.64,1.64,0,0,0-2.449.586,21.745,21.745,0,0,0-1.732,4.67A1.623,1.623,0,0,0,274.891,398.884Z" transform="translate(-274.116 -346.209)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                            <path id="Tracé_1102" data-name="Tracé 1102" d="M319.261,371.94,303.39,392.881a7.786,7.786,0,0,1-8.084,2.774,21.9,21.9,0,0,0-9.244-.208,1.621,1.621,0,0,0-.618,2.878l23.969,18.167a1.62,1.62,0,0,0,2.6-1.372,21.85,21.85,0,0,0-2.7-8.845,7.786,7.786,0,0,1,.487-8.531l15.9-20.978" transform="translate(-260.315 -371.94)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        </g>
                                        <path id="Rectangle_54" data-name="Rectangle 54" d="M0,0H10.512a2.91,2.91,0,0,1,2.91,2.91v7.6a2.91,2.91,0,0,1-2.91,2.91H2.91A2.91,2.91,0,0,1,0,10.512V0A0,0,0,0,1,0,0Z" transform="matrix(0.711, 0.703, -0.703, 0.711, 54.619, 357.374)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <rect id="Rectangle_55" data-name="Rectangle 55" width="36.963" height="5.369" rx="0.738" transform="translate(44.384 446.131)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <path id="Rectangle_56" data-name="Rectangle 56" d="M0,0H34.9a0,0,0,0,1,0,0V53.546a3.476,3.476,0,0,1-3.476,3.476H3.476A3.476,3.476,0,0,1,0,53.546V0A0,0,0,0,1,0,0Z" transform="translate(45.416 451.5)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <path id="Tracé_1103" data-name="Tracé 1103" d="M279.852,461.138l32.12-25.095a1.292,1.292,0,0,1,1.727.122l3.6,3.757a1.288,1.288,0,0,1,.293,1.308l-6.428,15.049a5.558,5.558,0,0,0,.641,4.859" transform="translate(-231.82 -15.006)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <g id="Groupe_210" data-name="Groupe 210" transform="translate(0 440.822)">
                                            <path id="Tracé_1104" data-name="Tracé 1104" d="M275.284,445.934a32.977,32.977,0,0,0-15.81,10.565,1.951,1.951,0,0,0-.122,2.325l33.234,49.717c.47.7,5.489,2.1,6.245-2.066l-.392-57.876a1.952,1.952,0,0,0-1.245-1.8A32.982,32.982,0,0,0,275.284,445.934Z" transform="translate(-259.025 -444.467)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        </g>
                                        <path id="Rectangle_57" data-name="Rectangle 57" d="M2.36,0H5.442a0,0,0,0,1,0,0V29.829a0,0,0,0,1,0,0H2.36A2.36,2.36,0,0,1,0,27.47V2.36A2.36,2.36,0,0,1,2.36,0Z" transform="translate(39.84 459.96)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <ellipse id="Ellipse_12" data-name="Ellipse 12" cx="0.605" cy="4.132" rx="0.605" ry="4.132" transform="translate(44.902 430.639) rotate(-37.061)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Tracé_1105" data-name="Tracé 1105" d="M287.828,447.121l-4.071,3.074a.609.609,0,0,1-.888-.168l-3.044-5.016a.608.608,0,0,1,.155-.8l3.125-2.362a.607.607,0,0,1,.812.071l3.992,4.3A.608.608,0,0,1,287.828,447.121Z" transform="translate(-231.97 -7.225)" fill="none" stroke="#d98d1b" stroke-miterlimit="10" stroke-width="1" />
                                        <path id="Tracé_1106" data-name="Tracé 1106" d="M311.441,458.908a5.946,5.946,0,0,0-6.342-5.932,6.082,6.082,0,0,0-5.549,6.137v31.742a2.416,2.416,0,0,0,2.417,2.417h1.211a2.418,2.418,0,0,1,2.417,2.419h0a2.418,2.418,0,0,1-2.417,2.419H293.15" transform="translate(-214.449 7.455)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Tracé_1107" data-name="Tracé 1107" d="M284.69,472.966a3.5,3.5,0,0,1-3.506-3.506" transform="translate(-230.08 29.004)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Tracé_1108" data-name="Tracé 1108" d="M288.6,445.114l1.815,49.774a.767.767,0,0,1-1.409.447L260.993,452.4" transform="translate(-256.455 -2.799)" fill="none" stroke="#d98d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <g id="Groupe_211" data-name="Groupe 211" transform="translate(121.896 30.9)">
                                            <path id="Tracé_1109" data-name="Tracé 1109" d="M328.554,288.876c0,.254-.15.381-.447.381-.025,0-.242-.012-.66-.035h-.69a1.705,1.705,0,0,0-.9.346,11.536,11.536,0,0,1-6.605,1.7q-7.372,0-7.371-7.784V267.912c0-.6.161-.9.484-.9.138,0,.727.048,1.764.141l2.286-.141c.274,0,.413.175.413.521,0,.069-.009.357-.035.865s-.032.957-.032,1.349v12.592q0,4.566,3.7,4.564,4.148,0,4.149-3.838,0-.45.346-.45c.184,0,.577.012,1.178.035l1.972-.071a.369.369,0,0,1,.415.417q0,.484-.072,2.7Z" transform="translate(-311.879 -266.355)" fill="#fff" />
                                            <path id="Tracé_1110" data-name="Tracé 1110" d="M338.224,288.846a.335.335,0,0,1-.38.381c-.115,0-.565-.021-1.349-.067a1.635,1.635,0,0,0-.932.381,11.364,11.364,0,0,1-6.956,1.937,9.224,9.224,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.281-5.69,8.78,8.78,0,0,1,1-4.064,11.206,11.206,0,0,0,1-2.233,8.378,8.378,0,0,0-.311-1.594,8.721,8.721,0,0,1-.311-2.108,6.24,6.24,0,0,1,2.424-5.189,9.081,9.081,0,0,1,5.708-1.73,12.611,12.611,0,0,1,6.017,1.139.694.694,0,0,1,.415.59,11.333,11.333,0,0,1-.45,1.73,11.212,11.212,0,0,1-.277,2.108,1.883,1.883,0,0,1-.553-.275,6.659,6.659,0,0,0-4.324-1.245q-4.182,0-4.184,3.25,0,2.183,2.039,2.182h5.431a.459.459,0,0,1,.519.519q0,.242-.067,1.52c.067.925.1,1.5.1,1.73,0,.279-.187.417-.556.417h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.852,4.852,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.352q0-.415.311-.415.242,0,1.384.035l1.833-.069q.381,0,.38.415c0,.323-.021,1.107-.069,2.352Q338.224,288.329,338.224,288.846Z" transform="translate(-301.277 -266.569)" fill="#fff" />
                                            <path id="Tracé_1111" data-name="Tracé 1111" d="M346.657,284.074a6.8,6.8,0,0,1-2.214,5.293,8.869,8.869,0,0,1-6.262,2.11,9.641,9.641,0,0,1-6.054-1.7,1.019,1.019,0,0,0-.657-.175h-1.522c-.346,0-.519-.148-.519-.447q0-.277.138-2.871l-.035-2.631c0-.3.161-.447.484-.447q.173,0,1.453.1,1.245-.1,1.384-.1a.4.4,0,0,1,.45.447,3.563,3.563,0,0,0,1.16,2.735,4.487,4.487,0,0,0,3.164,1.072,4.194,4.194,0,0,0,2.786-.833,2.741,2.741,0,0,0,.985-2.214q0-2.072-3.01-3.18-2.768-1.038-5.57-2.041a5.369,5.369,0,0,1-3.01-5.053,6.64,6.64,0,0,1,2.424-5.394,9.256,9.256,0,0,1,6.017-1.9q4.67,0,7.057,1.8a.777.777,0,0,1,.381.623A6,6,0,0,1,345,271.1q-.1.692-.277,1.833c-.069.254-.2.381-.378.381a.434.434,0,0,1-.314-.141,7.837,7.837,0,0,0-2.629-1.64,8.642,8.642,0,0,0-3.286-.676,3.839,3.839,0,0,0-2.369.69,2.225,2.225,0,0,0-.916,1.87q0,1.833,3.077,2.871a47.4,47.4,0,0,1,5.673,2.11A6.189,6.189,0,0,1,346.657,284.074Z" transform="translate(-288.954 -266.569)" fill="#fff" />
                                            <path id="Tracé_1112" data-name="Tracé 1112" d="M374.344,290.319a.628.628,0,0,1-.346.554,5.762,5.762,0,0,1-2.941.761q-4.255,0-4.255-4.878v-8.3a13.917,13.917,0,0,0-.78-5.517,2.841,2.841,0,0,0-2.818-1.644,2.622,2.622,0,0,0-2.595,1.555,11.534,11.534,0,0,0-.623,4.465v13.353a1.149,1.149,0,0,1-.069.535c-.046.06-.208.088-.484.088q-.45,0-2.076-.138-1.418.138-1.73.138-.519,0-.519-.692V277.312a9.524,9.524,0,0,0-.83-4.62,3.065,3.065,0,0,0-2.871-1.4,2.711,2.711,0,0,0-2.733,1.972,16.127,16.127,0,0,0-.623,5.293v10.826c0,.118.012.337.035.66v.623q0,.623-.415.623-.173,0-2.352-.138-1.626.138-1.9.138-.311,0-.311-.484c0-.115.011-.334.035-.66v-12a11,11,0,0,1,1.176-5.394,1.771,1.771,0,0,0,.242-.83q0-.381-.692-1.075l-1.7-1.658a.774.774,0,0,1-.277-.519.53.53,0,0,1,.242-.45l1.972-1.317a.942.942,0,0,1,.45-.171,1.013,1.013,0,0,1,.623.242l1.522,1.245a1.165,1.165,0,0,0,.727.309,1.718,1.718,0,0,0,.8-.24A9.014,9.014,0,0,1,352.1,267a7.28,7.28,0,0,1,4.359,1.624,2.116,2.116,0,0,0,1.245.484,2.152,2.152,0,0,0,1.176-.447A8.835,8.835,0,0,1,363.792,267q7.888,0,7.887,10.344v8.268q0,1.73,1.038,1.73a1.559,1.559,0,0,0,.761-.242,1.46,1.46,0,0,1,.588-.242c.092,0,.15.021.173.069a.859.859,0,0,1,.035.311q0,.277-.069,1.7Q374.344,290.008,374.344,290.319Z" transform="translate(-272.671 -266.726)" fill="#fff" />
                                            <path id="Tracé_1113" data-name="Tracé 1113" d="M361.956,290.935q-.242,0-2.249-.138-2.076.311-2.422.311c-.161,0-.242-.164-.242-.484V268.1c0-.715.173-1.075.519-1.075.161,0,.842.037,2.041.106q1.73-.138,2.006-.138.415,0,.415.45c0,.069-.012.33-.035.777s-.035.895-.035,1.333v17.47q0,1.315.208,3.6Q362.164,290.935,361.956,290.935Z" transform="translate(-252.88 -266.373)" fill="#fff" />
                                            <path id="Tracé_1114" data-name="Tracé 1114" d="M379.325,288.846a.335.335,0,0,1-.38.381c-.115,0-.565-.021-1.349-.067a1.641,1.641,0,0,0-.934.381,11.357,11.357,0,0,1-6.953,1.937,9.215,9.215,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.283-5.69,8.781,8.781,0,0,1,1-4.064,11.2,11.2,0,0,0,1-2.233,8.378,8.378,0,0,0-.311-1.594,8.607,8.607,0,0,1-.311-2.108,6.244,6.244,0,0,1,2.422-5.189,9.09,9.09,0,0,1,5.708-1.73,12.615,12.615,0,0,1,6.019,1.139.7.7,0,0,1,.415.59,11.342,11.342,0,0,1-.45,1.73,11.217,11.217,0,0,1-.277,2.108,1.883,1.883,0,0,1-.553-.275,6.663,6.663,0,0,0-4.324-1.245q-4.186,0-4.186,3.25,0,2.183,2.041,2.182h5.431a.459.459,0,0,1,.519.519q0,.242-.069,1.52.1,1.387.1,1.73c0,.279-.184.417-.553.417h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.852,4.852,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.352q0-.415.311-.415.242,0,1.384.035l1.834-.069q.38,0,.38.415,0,.484-.069,2.352Q379.325,288.329,379.325,288.846Z" transform="translate(-247.59 -266.569)" fill="#fff" />
                                            <path id="Tracé_1115" data-name="Tracé 1115" d="M387.2,288.876q0,.381-.45.381-.035,0-.657-.035H385.4a1.706,1.706,0,0,0-.9.346,11.549,11.549,0,0,1-6.607,1.7q-7.369,0-7.369-7.784V267.912c0-.6.161-.9.484-.9.138,0,.726.048,1.764.141l2.283-.141c.277,0,.415.175.415.521q0,.1-.035.865t-.035,1.349v12.592q0,4.566,3.7,4.564,4.151,0,4.151-3.838,0-.45.346-.45.277,0,1.176.035l1.972-.071a.369.369,0,0,1,.415.417q0,.484-.069,2.7Z" transform="translate(-235.265 -266.355)" fill="#fff" />
                                            <path id="Tracé_1116" data-name="Tracé 1116" d="M396.2,284.074a6.8,6.8,0,0,1-2.214,5.293,8.872,8.872,0,0,1-6.262,2.11,9.637,9.637,0,0,1-6.054-1.7,1.023,1.023,0,0,0-.657-.175h-1.522c-.346,0-.519-.148-.519-.447q0-.277.138-2.871l-.035-2.631c0-.3.159-.447.484-.447q.173,0,1.453.1c.83-.069,1.289-.1,1.381-.1a.4.4,0,0,1,.452.447,3.56,3.56,0,0,0,1.158,2.735,4.493,4.493,0,0,0,3.167,1.072,4.191,4.191,0,0,0,2.784-.833,2.743,2.743,0,0,0,.987-2.214q0-2.072-3.01-3.18-2.768-1.038-5.57-2.041a5.369,5.369,0,0,1-3.01-5.053,6.645,6.645,0,0,1,2.422-5.394,9.267,9.267,0,0,1,6.019-1.9q4.67,0,7.057,1.8a.777.777,0,0,1,.38.623,6,6,0,0,1-.692,1.834q-.1.692-.277,1.833-.1.38-.381.381a.433.433,0,0,1-.311-.141,7.861,7.861,0,0,0-2.629-1.64,8.651,8.651,0,0,0-3.286-.676,3.846,3.846,0,0,0-2.371.69,2.225,2.225,0,0,0-.916,1.87q0,1.833,3.079,2.871a47.41,47.41,0,0,1,5.674,2.11A6.189,6.189,0,0,1,396.2,284.074Z" transform="translate(-224.233 -266.569)" fill="#fff" />
                                            <path id="Tracé_1117" data-name="Tracé 1117" d="M331.05,294.246q0,6.089-2.871,9.306a8.747,8.747,0,0,1-6.919,2.906q-4.394,0-6.225-3.044a1.086,1.086,0,0,0-1-.519h-1.179q-.484,0-.484-.623V284.11a1.064,1.064,0,0,0-.155-.519,1,1,0,0,1-.155-.381c0-.208.228-.369.69-.484a25.775,25.775,0,0,1,3.824-.588q2.716-.277,4.619-.277Q331.052,281.861,331.05,294.246Zm-5.291-.037q0-5.013-1.663-7.02a4.37,4.37,0,0,0-3.563-1.453,10.411,10.411,0,0,0-2.9.346c-.325.092-.484.369-.484.828v10.969q0,4.566,3.768,4.566Q325.757,302.445,325.759,294.209Z" transform="translate(-311.643 -246.955)" fill="#fff" />
                                            <path id="Tracé_1118" data-name="Tracé 1118" d="M339.951,303.846a.337.337,0,0,1-.383.38c-.115,0-.565-.021-1.347-.067a1.635,1.635,0,0,0-.934.381,11.362,11.362,0,0,1-6.954,1.937A9.226,9.226,0,0,1,324,304.452a7.2,7.2,0,0,1-2.281-5.69,8.778,8.778,0,0,1,1-4.064,11.2,11.2,0,0,0,1-2.233,8.492,8.492,0,0,0-.309-1.594,8.623,8.623,0,0,1-.314-2.108,6.24,6.24,0,0,1,2.424-5.189,9.086,9.086,0,0,1,5.708-1.73,12.626,12.626,0,0,1,6.019,1.139.7.7,0,0,1,.413.59,11.35,11.35,0,0,1-.45,1.73,11.225,11.225,0,0,1-.277,2.108,1.879,1.879,0,0,1-.551-.275,6.667,6.667,0,0,0-4.324-1.245q-4.186,0-4.186,3.25,0,2.183,2.039,2.182h5.431a.459.459,0,0,1,.519.519q0,.242-.067,1.52c.067.925.1,1.5.1,1.73,0,.279-.184.417-.556.417h-6.4q-2.076,0-2.073,2.422,0,4.324,4.668,4.324a4.859,4.859,0,0,0,3.358-1.142,3.09,3.09,0,0,0,1.107-2.352c0-.277.1-.415.309-.415q.242,0,1.384.035l1.834-.069q.381,0,.381.415c0,.323-.021,1.107-.067,2.352C339.914,302.58,339.951,303.5,339.951,303.846Z" transform="translate(-299.024 -246.975)" fill="#fff" />
                                            <path id="Tracé_1119" data-name="Tracé 1119" d="M352.587,299.065a6.72,6.72,0,0,1-2.249,5.431,9.122,9.122,0,0,1-6.089,1.9q-4.255,0-6.227-2.456a1.263,1.263,0,0,0-.969-.554h-1.278c-.3,0-.452-.185-.452-.554V282.978q0-.8.208-.8a1.157,1.157,0,0,1,.244.051.989.989,0,0,0,.242.053q-.073,0,2.957-.191t4.963-.189q7.3,0,7.3,5.916a4.833,4.833,0,0,1-2.352,4.566q-.45.277-.45.554c0,.208.161.357.484.447a4.753,4.753,0,0,1,2.682,2.078A6.573,6.573,0,0,1,352.587,299.065Zm-6.158-10.794a2.764,2.764,0,0,0-.881-2.214,3.3,3.3,0,0,0-2.232-.761,21.164,21.164,0,0,0-2.837.138q-.415.069-.415.761V291c0,.369.173.551.519.551h2.595Q346.429,291.555,346.429,288.271Zm.934,10.551q0-3.805-4.255-3.808h-2.456a.333.333,0,0,1-.258-.154c-.081-.1-.145-.155-.191-.155q-.138,0-.138.865v3.008q0,4.258,3.7,4.257a3.38,3.38,0,0,0,2.648-1.056A4.258,4.258,0,0,0,347.363,298.823Z" transform="translate(-281.253 -246.896)" fill="#fff" />
                                            <path id="Tracé_1120" data-name="Tracé 1120" d="M363.078,302.005q0,.208-.138,1.834c.046.782.069,1.245.069,1.384a.513.513,0,0,1-.346.519,7.066,7.066,0,0,1-2.629.657,5.229,5.229,0,0,1-4.843-3.286l-2.871-5.708a1.3,1.3,0,0,0-1.107-.761q-1.488-.1-1.591-.1c-.346,0-.519.231-.519.69v8.3c0,.344-.127.519-.381.519a18.925,18.925,0,0,1-2.11-.138,21.556,21.556,0,0,1-2.179.138c-.161,0-.242-.058-.242-.175a1.715,1.715,0,0,1,.1-.413,1.655,1.655,0,0,0,.1-.452v-21.9q0-.554.415-.623a50.226,50.226,0,0,1,8.441-.588,7.926,7.926,0,0,1,5.4,1.7,6.764,6.764,0,0,1,2.113,5.4,7.327,7.327,0,0,1-1.022,3.822,6.107,6.107,0,0,1-2.613,2.475q-.415.208-.415.415a1.432,1.432,0,0,0,.173.45q.8,1.557,2.352,4.29.934,1.626,2.11,1.626a2.335,2.335,0,0,0,.934-.191,2.245,2.245,0,0,1,.553-.189Q363.078,301.694,363.078,302.005Zm-7.334-12.56q0-3.906-3.63-3.907a16.364,16.364,0,0,0-2.666.208.5.5,0,0,0-.484.553c0,.138.023.454.069.95s.069.929.069,1.3V291.7a1.245,1.245,0,0,0,.9,1.384,7.545,7.545,0,0,0,1.937.205,3.87,3.87,0,0,0,2.77-.982A3.767,3.767,0,0,0,355.744,289.445Z" transform="translate(-269.673 -246.896)" fill="#fff" />
                                            <path id="Tracé_1121" data-name="Tracé 1121" d="M371.6,303.846a.335.335,0,0,1-.38.38c-.115,0-.565-.021-1.349-.067a1.642,1.642,0,0,0-.934.381,11.356,11.356,0,0,1-6.953,1.937,9.216,9.216,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.283-5.69,8.782,8.782,0,0,1,1-4.064,11.2,11.2,0,0,0,1-2.233,8.377,8.377,0,0,0-.311-1.594,8.6,8.6,0,0,1-.311-2.108,6.244,6.244,0,0,1,2.422-5.189,9.09,9.09,0,0,1,5.708-1.73,12.614,12.614,0,0,1,6.02,1.139.7.7,0,0,1,.415.59,11.337,11.337,0,0,1-.45,1.73,11.217,11.217,0,0,1-.277,2.108,1.883,1.883,0,0,1-.553-.275,6.663,6.663,0,0,0-4.324-1.245q-4.186,0-4.186,3.25,0,2.183,2.041,2.182H367a.459.459,0,0,1,.519.519q0,.242-.069,1.52.1,1.387.1,1.73c0,.279-.185.417-.554.417h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.853,4.853,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.352q0-.415.311-.415.242,0,1.384.035l1.833-.069q.38,0,.381.415,0,.484-.069,2.352Q371.6,303.329,371.6,303.846Z" transform="translate(-257.681 -246.975)" fill="#fff" />
                                            <path id="Tracé_1122" data-name="Tracé 1122" d="M379.574,285.948q0,.415-.45.415c-.069,0-.339-.012-.812-.035s-.918-.035-1.333-.035h-3.01q-1,0-1,.9v13.284q0,1.73,1,1.73a1.823,1.823,0,0,0,.78-.242,1.89,1.89,0,0,1,.6-.242q.277,0,.277.346v1.557q.207,1.384.208,1.591a.605.605,0,0,1-.346.519,6.211,6.211,0,0,1-3.321.761q-4.082,0-4.082-4.361V288.369a3.99,3.99,0,0,1,.191-.936,4.507,4.507,0,0,0,.189-.793q0-.381-.657-.381-.623,0-2.629.035-.277,0-1.626.069t-1.488.069q-.415,0-.415-.38,0-.277.069-1.868-.1-1.176-.1-1.453c0-.323.185-.487.554-.487h15.014a4.534,4.534,0,0,0,1.488-.205,4.783,4.783,0,0,1,.657-.208.184.184,0,0,1,.208.208q0,.381-.069,1.8Z" transform="translate(-246.904 -246.994)" fill="#fff" />
                                            <path id="Tracé_1123" data-name="Tracé 1123" d="M391.314,305.087a.573.573,0,0,1-.346.551,5.681,5.681,0,0,1-2.768.625,3.715,3.715,0,0,1-3.805-3.01l-1.038-3.425-.208-.9a.75.75,0,0,0-.727-.59,3.663,3.663,0,0,0-.777.245,3.048,3.048,0,0,1-.918.24l-3.6.037a1.7,1.7,0,0,0-.881.154,1.384,1.384,0,0,0-.4.745l-1.28,4.393q-.173.761-.311,1.485c-.092.325-.254.484-.484.484s-.876-.081-1.937-.24H369.45a.306.306,0,0,1-.346-.346q0,.1.588-1.8l5.777-18.819q.311-1.176.657-2.387c.138-.346.542-.521,1.211-.521.184,0,.876.037,2.076.106l2.249-.106a1.378,1.378,0,0,1,1.089.348,8.232,8.232,0,0,1,.849,2.143l4.809,15.224q.657,2.041,1.626,2.041a1.843,1.843,0,0,0,.588-.122,3.542,3.542,0,0,1,.381-.12c.161,0,.242.127.242.378q0,.315-.1,1.455Q391.314,304.81,391.314,305.087Zm-9.168-10.309a1.643,1.643,0,0,0-.069-.45l-1.557-5.155a13.366,13.366,0,0,0-.8-2.179.7.7,0,0,0-.588-.383c-.092,0-.15.175-.173.521a9.851,9.851,0,0,1-.381,2.041l-1.488,5.221a2.146,2.146,0,0,0-.069.279q0,.346.553.346h4.082Q382.146,295.02,382.146,294.778Z" transform="translate(-237.126 -246.761)" fill="#fff" />
                                            <path id="Tracé_1124" data-name="Tracé 1124" d="M397.994,294.508a3.062,3.062,0,0,1-.208.639,2.534,2.534,0,0,0-.208.849v5.466q.069,1.9.069,2.629c0,.3-.129.45-.381.45-.092,0-.468-.018-1.125-.051s-1.068-.053-1.227-.053a1.462,1.462,0,0,0-.934.275,8.863,8.863,0,0,1-5.812,1.767q-4.394,0-6.573-2.768-2.356-2.979-2.352-9.41,0-6.158,2.7-9.444a8.05,8.05,0,0,1,6.573-3.01,8.161,8.161,0,0,1,5.57,1.661,1.114,1.114,0,0,0,.761.311q.242,0,.969-.034h.8q.242,0,.242.311,0,.277-.035,2.7l.069,2.906q0,.415-.346.415-.208,0-1.557-.069l-1.557.173c-.233,0-.346-.092-.346-.277a4.007,4.007,0,0,0-1.142-3.012,3.717,3.717,0,0,0-2.629-1q-4.774,0-4.774,8.164,0,4.947,1.487,6.85a3.76,3.76,0,0,0,3.217,1.384q3.875,0,3.875-2.908v-.761c0-.6-.256-.9-.761-.9h-1.73a1.921,1.921,0,0,0-.484.069,2.866,2.866,0,0,1-.311.069c-.161-.069-.242-.138-.242-.21q0-.377.1-1.73l-.035-1.451q0-.311.519-.311h7.265C397.807,294.2,397.994,294.3,397.994,294.508Z" transform="translate(-223.88 -246.975)" fill="#fff" />
                                            <path id="Tracé_1125" data-name="Tracé 1125" d="M411.4,305.334a.605.605,0,0,1-.346.519,5.649,5.649,0,0,1-2.94.761q-4.255,0-4.255-4.982v-7.818q0-3.978-.9-5.639a3.674,3.674,0,0,0-3.563-1.9,3.591,3.591,0,0,0-3.494,2.143,12.738,12.738,0,0,0-.865,5.364v10.584c0,.118.012.337.035.66v.623c0,.415-.152.623-.45.623q-.173,0-1.972-.138-1.9.138-2.179.138c-.231,0-.346-.175-.346-.519q0-.173.035-.657V293.12a11.093,11.093,0,0,1,.969-5.014,2.926,2.926,0,0,0,.381-1.176,1.6,1.6,0,0,0-.657-1.109l-1.488-1.349a2.561,2.561,0,0,1-.468-.517.806.806,0,0,1-.154-.415.4.4,0,0,1,.242-.346l1.416-.9a7.882,7.882,0,0,1,.833-.5,1.044,1.044,0,0,1,.346-.051.964.964,0,0,1,.588.208l1.626,1.245a1.436,1.436,0,0,0,.865.346,3,3,0,0,0,1.28-.381,9.523,9.523,0,0,1,4.151-1.176q8.649,0,8.649,10.966v7.784q0,1.591.969,1.591a1.806,1.806,0,0,0,.83-.242,1.922,1.922,0,0,1,.553-.242.273.273,0,0,1,.208.069.472.472,0,0,1,.069.311q0,.277-.1,1.626C411.35,304.631,411.4,305.125,411.4,305.334Z" transform="translate(-211.477 -247.112)" fill="#fff" />
                                            <path id="Tracé_1126" data-name="Tracé 1126" d="M417.605,303.846a.335.335,0,0,1-.38.38c-.118,0-.565-.021-1.349-.067a1.641,1.641,0,0,0-.934.381,11.357,11.357,0,0,1-6.953,1.937,9.216,9.216,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.283-5.69,8.782,8.782,0,0,1,1-4.064,11.2,11.2,0,0,0,1-2.233,8.375,8.375,0,0,0-.311-1.594,8.6,8.6,0,0,1-.311-2.108,6.244,6.244,0,0,1,2.422-5.189,9.091,9.091,0,0,1,5.708-1.73,12.62,12.62,0,0,1,6.019,1.139.7.7,0,0,1,.415.59,11.342,11.342,0,0,1-.45,1.73,11.612,11.612,0,0,1-.277,2.108,1.883,1.883,0,0,1-.554-.275,6.667,6.667,0,0,0-4.324-1.245q-4.186,0-4.186,3.25,0,2.183,2.041,2.182H413a.459.459,0,0,1,.519.519q0,.242-.069,1.52.1,1.387.1,1.73c0,.279-.185.417-.554.417h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.852,4.852,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.352q0-.415.311-.415c.159,0,.623.012,1.384.035l1.834-.069c.251,0,.381.138.381.415q0,.484-.069,2.352Q417.605,303.329,417.605,303.846Z" transform="translate(-197.585 -246.975)" fill="#fff" />
                                        </g>
                                        <g id="Groupe_212" data-name="Groupe 212" transform="translate(123.87 166.171)">
                                            <path id="Tracé_1127" data-name="Tracé 1127" d="M329.41,347.407c0,.254-.15.38-.447.38-.025,0-.242-.011-.66-.035h-.69a1.705,1.705,0,0,0-.9.346,11.537,11.537,0,0,1-6.605,1.7q-7.372,0-7.371-7.784V326.443q0-.9.484-.9.208,0,1.764.138l2.286-.138c.275,0,.413.173.413.519q0,.1-.035.865c-.023.507-.032.957-.032,1.349v12.592q0,4.566,3.7,4.566,4.148,0,4.149-3.838c0-.3.115-.452.346-.452q.277,0,1.178.035l1.972-.069a.367.367,0,0,1,.415.415c0,.323-.025,1.225-.071,2.7Z" transform="translate(-312.735 -325.165)" fill="#fff" />
                                            <path id="Tracé_1128" data-name="Tracé 1128" d="M339.08,347.379a.336.336,0,0,1-.381.381q-.173,0-1.349-.069a1.629,1.629,0,0,0-.932.381,11.364,11.364,0,0,1-6.956,1.937,9.219,9.219,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.281-5.69,8.778,8.778,0,0,1,1-4.064,11.205,11.205,0,0,0,1-2.232,8.362,8.362,0,0,0-.311-1.591,8.752,8.752,0,0,1-.311-2.108,6.238,6.238,0,0,1,2.422-5.189,9.093,9.093,0,0,1,5.71-1.73,12.61,12.61,0,0,1,6.017,1.139.689.689,0,0,1,.415.59,11.494,11.494,0,0,1-.45,1.73,11.277,11.277,0,0,1-.277,2.108,1.971,1.971,0,0,1-.554-.277,6.658,6.658,0,0,0-4.324-1.245q-4.186,0-4.184,3.252,0,2.179,2.039,2.179h5.431a.459.459,0,0,1,.519.519c0,.161-.023.671-.067,1.522q.1,1.384.1,1.73,0,.415-.553.415h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.853,4.853,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.352q0-.415.311-.415c.161,0,.623.014,1.384.035l1.834-.069q.38,0,.38.415,0,.484-.069,2.352Q339.08,346.86,339.08,347.379Z" transform="translate(-302.133 -325.379)" fill="#fff" />
                                            <path id="Tracé_1129" data-name="Tracé 1129" d="M347.513,342.6A6.8,6.8,0,0,1,345.3,347.9a8.859,8.859,0,0,1-6.262,2.11,9.641,9.641,0,0,1-6.054-1.7,1.019,1.019,0,0,0-.657-.173H330.8q-.519,0-.519-.45,0-.277.138-2.871l-.035-2.629c0-.3.161-.45.484-.45q.173,0,1.453.1,1.245-.1,1.384-.1a.4.4,0,0,1,.45.45,3.557,3.557,0,0,0,1.16,2.733,4.476,4.476,0,0,0,3.164,1.072,4.206,4.206,0,0,0,2.786-.83,2.748,2.748,0,0,0,.985-2.214q0-2.076-3.01-3.183-2.768-1.038-5.57-2.041a5.368,5.368,0,0,1-3.01-5.051,6.646,6.646,0,0,1,2.422-5.4,9.266,9.266,0,0,1,6.019-1.9q4.67,0,7.057,1.8a.777.777,0,0,1,.38.623,6,6,0,0,1-.692,1.833q-.1.692-.277,1.834c-.069.256-.2.381-.381.381a.43.43,0,0,1-.311-.138,7.8,7.8,0,0,0-2.629-1.642,8.641,8.641,0,0,0-3.286-.676,3.824,3.824,0,0,0-2.369.692,2.22,2.22,0,0,0-.918,1.868q0,1.834,3.079,2.871a47.388,47.388,0,0,1,5.674,2.11A6.191,6.191,0,0,1,347.513,342.6Z" transform="translate(-289.81 -325.379)" fill="#fff" />
                                            <path id="Tracé_1130" data-name="Tracé 1130" d="M329.751,348.222a8.264,8.264,0,0,1-2.249,6.139,7.985,7.985,0,0,1-5.883,2.232,2.946,2.946,0,0,1-1.9-.78,2.7,2.7,0,0,0-1.453-.777c-.3,0-.452.161-.452.484a3.681,3.681,0,0,0,.035.381v5.157a14.485,14.485,0,0,0,.141,2.731c.113.392.171.554.171.484,0,.208-.136.311-.413.311-.21,0-.911-.046-2.11-.138-1.315.092-2.066.138-2.251.138q-.38,0-.381-.623V341.718c0-.367.127-.577.381-.623a43.135,43.135,0,0,1,8.2-.657,8.906,8.906,0,0,1,5.533,1.626A7.312,7.312,0,0,1,329.751,348.222Zm-5.051.035a3.985,3.985,0,0,0-1.091-2.975,3.941,3.941,0,0,0-2.887-1.072,13.89,13.89,0,0,0-2.352.208.6.6,0,0,0-.521.657v4.774a3.362,3.362,0,0,0,.484,2.041,2.928,2.928,0,0,0,2.456.865Q324.7,352.754,324.7,348.256Z" transform="translate(-312.382 -305.708)" fill="#fff" />
                                            <path id="Tracé_1131" data-name="Tracé 1131" d="M340.744,360.537q0,.207-.138,1.833.069,1.176.069,1.384a.513.513,0,0,1-.346.519,7.066,7.066,0,0,1-2.629.657,5.226,5.226,0,0,1-4.843-3.287l-2.871-5.708a1.3,1.3,0,0,0-1.107-.761q-1.488-.1-1.591-.1-.519,0-.519.692v8.3q0,.519-.38.519a18.894,18.894,0,0,1-2.11-.138,21.523,21.523,0,0,1-2.179.138q-.242,0-.242-.173a1.7,1.7,0,0,1,.1-.415,1.646,1.646,0,0,0,.1-.45v-21.9q0-.554.415-.623a50.241,50.241,0,0,1,8.441-.588,7.926,7.926,0,0,1,5.4,1.7,6.764,6.764,0,0,1,2.113,5.4,7.335,7.335,0,0,1-1.022,3.824,6.1,6.1,0,0,1-2.613,2.472q-.415.208-.415.415a1.43,1.43,0,0,0,.173.45q.8,1.557,2.353,4.29.934,1.626,2.11,1.626a2.336,2.336,0,0,0,.934-.191,2.355,2.355,0,0,1,.553-.189Q340.744,360.226,340.744,360.537Zm-7.334-12.558q0-3.909-3.63-3.909a16.626,16.626,0,0,0-2.666.208.505.505,0,0,0-.484.553c0,.138.023.457.069.95s.069.929.069,1.3v3.148a1.245,1.245,0,0,0,.9,1.384,7.527,7.527,0,0,0,1.937.208,3.871,3.871,0,0,0,2.77-.985A3.761,3.761,0,0,0,333.409,347.98Z" transform="translate(-300.822 -305.708)" fill="#fff" />
                                            <path id="Tracé_1132" data-name="Tracé 1132" d="M350.886,352.658q0,6.507-3.252,9.686a9.4,9.4,0,0,1-6.954,2.664q-9.721,0-9.721-12.108a17.245,17.245,0,0,1,1-5.828,10.77,10.77,0,0,1,2.629-4.343,6.784,6.784,0,0,0,2.89-1.195,7.348,7.348,0,0,1,3.891-1.156Q350.886,340.379,350.886,352.658Zm-5.328.208q0-8.476-4.532-8.476a3.848,3.848,0,0,0-3.356,1.868q-1.418,2.145-1.418,6.5,0,8.164,4.636,8.164a3.725,3.725,0,0,0,3.286-1.9A11.274,11.274,0,0,0,345.559,352.865Z" transform="translate(-288.928 -305.785)" fill="#fff" />
                                            <path id="Tracé_1133" data-name="Tracé 1133" d="M360.257,352.778q0,6.089-2.871,9.306a8.753,8.753,0,0,1-6.919,2.906q-4.394,0-6.227-3.044a1.081,1.081,0,0,0-1-.519h-1.176q-.484,0-.484-.623V342.642a1.09,1.09,0,0,0-.154-.519,1.019,1.019,0,0,1-.157-.381q0-.311.692-.484a25.708,25.708,0,0,1,3.824-.588q2.712-.277,4.617-.277Q360.257,340.393,360.257,352.778Zm-5.293-.035q0-5.016-1.661-7.023a4.373,4.373,0,0,0-3.563-1.453,10.532,10.532,0,0,0-2.906.346q-.484.138-.484.83V356.41q0,4.566,3.771,4.566Q354.964,360.977,354.964,352.743Z" transform="translate(-275.466 -305.766)" fill="#fff" />
                                            <path id="Tracé_1134" data-name="Tracé 1134" d="M372.771,363.174a.476.476,0,0,1-.242.381l-2.041,1.314a.8.8,0,0,1-.45.138,1.6,1.6,0,0,1-.9-.346l-1.315-.969a1.309,1.309,0,0,0-.761-.311,3.08,3.08,0,0,0-1.349.484,8.489,8.489,0,0,1-4.117,1.142q-8.372,0-8.372-10.966v-7.784q0-1.591-1-1.591a1.8,1.8,0,0,0-.708.173,1.768,1.768,0,0,1-.537.173q-.277,0-.277-.346,0-.277.1-1.626-.1-1.176-.1-1.488a.561.561,0,0,1,.311-.519,6.217,6.217,0,0,1,3.148-.655q3.944,0,3.944,4.737v8.06a14.937,14.937,0,0,0,.727,5.639,3.326,3.326,0,0,0,3.356,1.9,3.5,3.5,0,0,0,3.425-2.145,12.719,12.719,0,0,0,.865-5.362V341.968q-.035-.519-.035-.623,0-.623.45-.623.173,0,1.972.138,1.9-.138,2.179-.138c.231,0,.346.173.346.521v.655c-.023.3-.035.519-.035.657v11.312a11.033,11.033,0,0,1-1,5.016,2.66,2.66,0,0,0-.346,1.072,1.58,1.58,0,0,0,.692,1.107l1.453,1.211Q372.771,362.794,372.771,363.174Z" transform="translate(-263.141 -305.785)" fill="#fff" />
                                            <path id="Tracé_1135" data-name="Tracé 1135" d="M379.644,362.725a.336.336,0,0,1-.381.381c-.115,0-.473-.018-1.072-.051s-.957-.053-1.072-.053a1.191,1.191,0,0,0-.761.277,8.653,8.653,0,0,1-5.6,1.73q-4.5,0-6.746-2.8-2.387-3.01-2.387-9.479,0-6.158,2.768-9.444a8.044,8.044,0,0,1,6.4-2.9,8.582,8.582,0,0,1,5.673,1.658,1.337,1.337,0,0,0,.865.346q.173,0,.83-.034h.761q.311,0,.311.415c0,.346-.023,1.167-.069,2.456l.069,2.975q0,.45-.346.45-.208,0-1.557-.1-1.28.1-1.522.1a.336.336,0,0,1-.38-.381,3.672,3.672,0,0,0-1.176-2.906,4.056,4.056,0,0,0-2.7-.9q-4.636,0-4.636,8.1,0,4.532,1.245,6.469a3.812,3.812,0,0,0,3.459,1.73,4.159,4.159,0,0,0,2.991-1.038,3.732,3.732,0,0,0,1.091-2.837q0-.38.346-.38.208,0,1.661.1,1.418-.1,1.661-.1a.292.292,0,0,1,.208.069.636.636,0,0,1,.069.381q0,.519-.1,2.422Z" transform="translate(-248.877 -305.785)" fill="#fff" />
                                            <path id="Tracé_1136" data-name="Tracé 1136" d="M388.635,344.48c0,.277-.152.415-.45.415-.069,0-.341-.012-.814-.035s-.916-.035-1.331-.035h-3.01q-1,0-1,.9v13.284q0,1.73,1,1.73a1.783,1.783,0,0,0,.777-.242,1.9,1.9,0,0,1,.607-.242c.185,0,.277.118.277.346v1.557q.208,1.384.208,1.594a.607.607,0,0,1-.346.517,6.211,6.211,0,0,1-3.321.761q-4.082,0-4.082-4.359V346.9a3.916,3.916,0,0,1,.189-.934,4.674,4.674,0,0,0,.191-.8q0-.38-.657-.381c-.415,0-1.292.014-2.629.035q-.277,0-1.626.069t-1.488.069q-.415,0-.415-.381,0-.277.069-1.868-.1-1.173-.1-1.451c0-.325.185-.487.554-.487h15.014a4.53,4.53,0,0,0,1.488-.208,4.774,4.774,0,0,1,.657-.208.183.183,0,0,1,.208.208q0,.38-.069,1.8Z" transform="translate(-237.042 -305.805)" fill="#fff" />
                                            <path id="Tracé_1137" data-name="Tracé 1137" d="M397.266,362.379a.336.336,0,0,1-.381.381q-.173,0-1.349-.069a1.642,1.642,0,0,0-.934.38,11.356,11.356,0,0,1-6.954,1.937,9.215,9.215,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.283-5.69,8.781,8.781,0,0,1,1-4.064,11.2,11.2,0,0,0,1-2.232,8.362,8.362,0,0,0-.311-1.591,8.638,8.638,0,0,1-.311-2.108,6.238,6.238,0,0,1,2.422-5.189,9.09,9.09,0,0,1,5.708-1.73,12.614,12.614,0,0,1,6.019,1.139.693.693,0,0,1,.415.59,11.49,11.49,0,0,1-.45,1.73,11.278,11.278,0,0,1-.277,2.108,1.972,1.972,0,0,1-.554-.277,6.662,6.662,0,0,0-4.324-1.245q-4.186,0-4.186,3.252,0,2.179,2.041,2.179h5.431a.459.459,0,0,1,.519.519c0,.161-.023.671-.069,1.522q.1,1.384.1,1.73,0,.415-.553.415h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.853,4.853,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.352q0-.415.311-.415c.161,0,.623.014,1.384.035l1.834-.069q.38,0,.38.415,0,.484-.069,2.352Q397.266,361.86,397.266,362.379Z" transform="translate(-226.128 -305.785)" fill="#fff" />
                                            <path id="Tracé_1138" data-name="Tracé 1138" d="M410.136,363.174a.476.476,0,0,1-.242.381l-2.041,1.314a.8.8,0,0,1-.45.138,1.6,1.6,0,0,1-.9-.346l-1.315-.969a1.309,1.309,0,0,0-.761-.311,3.081,3.081,0,0,0-1.349.484,8.489,8.489,0,0,1-4.117,1.142q-8.372,0-8.372-10.966v-7.784q0-1.591-1-1.591a1.817,1.817,0,0,0-.71.173,1.742,1.742,0,0,1-.535.173c-.187,0-.277-.115-.277-.346q0-.277.1-1.626-.1-1.176-.1-1.488a.561.561,0,0,1,.311-.519,6.216,6.216,0,0,1,3.148-.655q3.944,0,3.944,4.737v8.06a14.939,14.939,0,0,0,.727,5.639,3.326,3.326,0,0,0,3.356,1.9,3.5,3.5,0,0,0,3.425-2.145,12.718,12.718,0,0,0,.865-5.362V341.968q-.035-.519-.035-.623,0-.623.45-.623.173,0,1.972.138,1.9-.138,2.18-.138c.231,0,.346.173.346.521v.655c-.023.3-.035.519-.035.657v11.312a11.033,11.033,0,0,1-1,5.016,2.662,2.662,0,0,0-.346,1.072,1.581,1.581,0,0,0,.692,1.107l1.453,1.211Q410.136,362.794,410.136,363.174Z" transform="translate(-214.332 -305.785)" fill="#fff" />
                                            <path id="Tracé_1139" data-name="Tracé 1139" d="M418.024,360.537c0,.138-.049.749-.138,1.833.046.784.067,1.245.067,1.384a.511.511,0,0,1-.344.519,7.086,7.086,0,0,1-2.631.657,5.223,5.223,0,0,1-4.841-3.287l-2.871-5.708a1.307,1.307,0,0,0-1.107-.761q-1.488-.1-1.591-.1c-.348,0-.519.231-.519.692v8.3c0,.346-.129.519-.381.519a18.861,18.861,0,0,1-2.11-.138,21.6,21.6,0,0,1-2.179.138c-.164,0-.242-.058-.242-.173a1.6,1.6,0,0,1,.1-.415,1.737,1.737,0,0,0,.1-.45v-21.9q0-.554.415-.623a50.23,50.23,0,0,1,8.439-.588,7.936,7.936,0,0,1,5.4,1.7,6.762,6.762,0,0,1,2.11,5.4,7.336,7.336,0,0,1-1.022,3.824,6.1,6.1,0,0,1-2.611,2.472c-.277.138-.417.277-.417.415a1.4,1.4,0,0,0,.175.45q.8,1.557,2.352,4.29c.623,1.084,1.324,1.626,2.11,1.626a2.326,2.326,0,0,0,.932-.191,2.323,2.323,0,0,1,.556-.189Q418.024,360.226,418.024,360.537ZM410.69,347.98q0-3.909-3.632-3.909a16.6,16.6,0,0,0-2.664.208.5.5,0,0,0-.484.553c0,.138.021.457.067.95s.072.929.072,1.3v3.148a1.245,1.245,0,0,0,.9,1.384,7.515,7.515,0,0,0,1.937.208,3.862,3.862,0,0,0,2.768-.985A3.754,3.754,0,0,0,410.69,347.98Z" transform="translate(-199.871 -305.708)" fill="#fff" />
                                            <path id="Tracé_1140" data-name="Tracé 1140" d="M425.873,357.6a6.809,6.809,0,0,1-2.214,5.293,8.865,8.865,0,0,1-6.264,2.11,9.646,9.646,0,0,1-6.054-1.7,1.012,1.012,0,0,0-.655-.173h-1.522q-.519,0-.519-.45,0-.277.138-2.871l-.037-2.629c0-.3.161-.45.484-.45q.177,0,1.455.1c.83-.069,1.289-.1,1.381-.1a.4.4,0,0,1,.452.45,3.556,3.556,0,0,0,1.158,2.733,4.482,4.482,0,0,0,3.167,1.072,4.2,4.2,0,0,0,2.784-.83,2.748,2.748,0,0,0,.985-2.214q0-2.076-3.007-3.183-2.771-1.038-5.57-2.041a5.368,5.368,0,0,1-3.01-5.051,6.646,6.646,0,0,1,2.422-5.4,9.266,9.266,0,0,1,6.019-1.9q4.67,0,7.057,1.8a.777.777,0,0,1,.381.623,6,6,0,0,1-.692,1.833q-.1.692-.279,1.833c-.067.256-.2.381-.378.381a.43.43,0,0,1-.311-.138,7.838,7.838,0,0,0-2.631-1.642,8.638,8.638,0,0,0-3.284-.676,3.832,3.832,0,0,0-2.371.692,2.218,2.218,0,0,0-.916,1.868q0,1.834,3.079,2.871a47.38,47.38,0,0,1,5.673,2.11A6.2,6.2,0,0,1,425.873,357.6Z" transform="translate(-187.448 -305.785)" fill="#fff" />
                                        </g>
                                        <g id="Groupe_213" data-name="Groupe 213" transform="translate(123.909 485.874)">
                                            <path id="Tracé_1141" data-name="Tracé 1141" d="M329.426,486.136c0,.251-.15.378-.45.378q-.035,0-.657-.035h-.692a1.706,1.706,0,0,0-.9.346,11.532,11.532,0,0,1-6.608,1.7q-7.369,0-7.369-7.784V465.17q0-.9.484-.9c.138,0,.726.048,1.764.138l2.283-.138q.415,0,.415.519,0,.1-.035.865T317.63,467V479.6q0,4.566,3.7,4.566,4.151,0,4.151-3.84,0-.45.346-.45c.185,0,.577.014,1.176.035l1.972-.069a.367.367,0,0,1,.415.415q0,.484-.069,2.7Z" transform="translate(-312.752 -463.65)" fill="#fff" />
                                            <path id="Tracé_1142" data-name="Tracé 1142" d="M325.973,466.207a3.126,3.126,0,0,1-.277,1.349l-1.8,3.909a.743.743,0,0,1-.761.38h-1.73q-.45,0-.45-.242a1.06,1.06,0,0,1,.1-.346l.865-2.145a1.925,1.925,0,0,0,.173-.727,1.337,1.337,0,0,0-.5-.9,1.352,1.352,0,0,1-.5-.934,11.664,11.664,0,0,1,.415-1.522,1.478,1.478,0,0,1,.327-.639,1.143,1.143,0,0,1,.641-.122h2.94c.254,0,.408.085.466.258A8.534,8.534,0,0,1,325.973,466.207Z" transform="translate(-302.034 -463.65)" fill="#fff" />
                                            <path id="Tracé_1143" data-name="Tracé 1143" d="M346.434,487.345a.58.58,0,0,1-.344.554,5.716,5.716,0,0,1-2.77.623,3.715,3.715,0,0,1-3.805-3.01l-1.035-3.425c-.072-.3-.138-.6-.21-.9a.748.748,0,0,0-.727-.588,3.6,3.6,0,0,0-.777.242,3.061,3.061,0,0,1-.916.242l-3.6.035a1.707,1.707,0,0,0-.883.155,1.434,1.434,0,0,0-.4.745l-1.278,4.393q-.176.761-.314,1.488c-.092.323-.251.484-.484.484s-.874-.081-1.935-.242h-2.389a.3.3,0,0,1-.344-.346,18.662,18.662,0,0,0,.586-1.8l5.777-18.819c.21-.784.427-1.58.657-2.387.141-.346.542-.519,1.211-.519q.277,0,2.076.1l2.249-.1a1.38,1.38,0,0,1,1.091.348,8.193,8.193,0,0,1,.849,2.143l4.809,15.222q.654,2.044,1.624,2.043a1.844,1.844,0,0,0,.59-.122,3,3,0,0,1,.381-.122q.242,0,.242.38,0,.311-.1,1.453C346.379,486.583,346.434,487.16,346.434,487.345Zm-9.167-10.309a1.633,1.633,0,0,0-.067-.45l-1.559-5.152a13.41,13.41,0,0,0-.793-2.182.7.7,0,0,0-.588-.38c-.092,0-.152.173-.175.519a9.915,9.915,0,0,1-.381,2.043l-1.488,5.221a2.033,2.033,0,0,0-.067.277c0,.231.182.346.551.346h4.082C337.108,477.278,337.267,477.2,337.267,477.035Z" transform="translate(-297.762 -463.65)" fill="#fff" />
                                            <path id="Tracé_1144" data-name="Tracé 1144" d="M351.9,481.333a6.806,6.806,0,0,1-2.214,5.293,8.874,8.874,0,0,1-6.264,2.11,9.632,9.632,0,0,1-6.054-1.7,1.026,1.026,0,0,0-.655-.173h-1.522q-.519,0-.519-.45,0-.277.138-2.871l-.037-2.629q0-.45.484-.45.176,0,1.455.1c.83-.069,1.289-.1,1.381-.1a.4.4,0,0,1,.452.45,3.555,3.555,0,0,0,1.158,2.733,4.482,4.482,0,0,0,3.167,1.075,4.2,4.2,0,0,0,2.784-.833,2.744,2.744,0,0,0,.985-2.214q0-2.072-3.007-3.183-2.771-1.034-5.57-2.039a5.373,5.373,0,0,1-3.01-5.053,6.65,6.65,0,0,1,2.422-5.4,9.267,9.267,0,0,1,6.02-1.9q4.67,0,7.057,1.8a.777.777,0,0,1,.381.623,5.976,5.976,0,0,1-.692,1.833q-.1.692-.279,1.833c-.067.254-.2.381-.378.381a.43.43,0,0,1-.311-.138,7.916,7.916,0,0,0-2.632-1.644,8.723,8.723,0,0,0-3.284-.673,3.832,3.832,0,0,0-2.371.692,2.218,2.218,0,0,0-.916,1.868q0,1.833,3.079,2.871a47.548,47.548,0,0,1,5.673,2.11A6.2,6.2,0,0,1,351.9,481.333Z" transform="translate(-284.125 -463.865)" fill="#fff" />
                                            <path id="Tracé_1145" data-name="Tracé 1145" d="M360.941,481.333a6.806,6.806,0,0,1-2.214,5.293,8.874,8.874,0,0,1-6.264,2.11,9.631,9.631,0,0,1-6.054-1.7,1.027,1.027,0,0,0-.655-.173h-1.522c-.346,0-.521-.15-.521-.45q0-.277.138-2.871l-.035-2.629q0-.45.484-.45.176,0,1.455.1c.83-.069,1.289-.1,1.381-.1a.4.4,0,0,1,.452.45,3.555,3.555,0,0,0,1.158,2.733,4.482,4.482,0,0,0,3.167,1.075,4.2,4.2,0,0,0,2.784-.833,2.744,2.744,0,0,0,.985-2.214q0-2.072-3.007-3.183-2.771-1.034-5.57-2.039a5.375,5.375,0,0,1-3.012-5.053,6.651,6.651,0,0,1,2.424-5.4,9.262,9.262,0,0,1,6.019-1.9q4.67,0,7.057,1.8a.777.777,0,0,1,.381.623,5.944,5.944,0,0,1-.694,1.833q-.1.692-.277,1.833c-.067.254-.2.381-.378.381a.426.426,0,0,1-.311-.138,7.916,7.916,0,0,0-2.631-1.644,8.723,8.723,0,0,0-3.284-.673,3.832,3.832,0,0,0-2.371.692,2.217,2.217,0,0,0-.916,1.868q0,1.833,3.079,2.871a47.541,47.541,0,0,1,5.673,2.11A6.2,6.2,0,0,1,360.941,481.333Z" transform="translate(-272.31 -463.865)" fill="#fff" />
                                            <path id="Tracé_1146" data-name="Tracé 1146" d="M372.457,476.386q0,6.5-3.252,9.686a9.4,9.4,0,0,1-6.951,2.664q-9.721,0-9.723-12.108a17.278,17.278,0,0,1,1-5.83,10.766,10.766,0,0,1,2.629-4.338,6.816,6.816,0,0,0,2.89-1.195,7.335,7.335,0,0,1,3.891-1.16Q372.457,464.105,372.457,476.386Zm-5.328.208q0-8.476-4.532-8.476a3.848,3.848,0,0,0-3.356,1.868q-1.418,2.145-1.416,6.5,0,8.168,4.633,8.164a3.722,3.722,0,0,0,3.286-1.9A11.267,11.267,0,0,0,367.13,476.594Z" transform="translate(-260.789 -463.865)" fill="#fff" />
                                            <path id="Tracé_1147" data-name="Tracé 1147" d="M380.65,486.455a.337.337,0,0,1-.381.381c-.115,0-.473-.018-1.07-.053s-.96-.053-1.075-.053a1.209,1.209,0,0,0-.761.277,8.639,8.639,0,0,1-5.6,1.73q-4.5,0-6.746-2.8-2.387-3.01-2.387-9.477,0-6.161,2.767-9.447a8.047,8.047,0,0,1,6.4-2.906,8.59,8.59,0,0,1,5.674,1.661,1.328,1.328,0,0,0,.865.346c.113,0,.39-.012.828-.032h.761c.208,0,.311.136.311.413q0,.519-.069,2.456l.069,2.975c0,.3-.115.45-.346.45q-.208,0-1.554-.1-1.283.1-1.524.1a.336.336,0,0,1-.381-.381,3.678,3.678,0,0,0-1.174-2.906,4.06,4.06,0,0,0-2.7-.9q-4.636,0-4.636,8.093,0,4.532,1.245,6.469a3.812,3.812,0,0,0,3.459,1.73,4.164,4.164,0,0,0,2.994-1.038,3.73,3.73,0,0,0,1.089-2.837q0-.381.346-.381.208,0,1.663.1,1.415-.1,1.658-.1a.274.274,0,0,1,.208.071c.046.044.069.171.069.378,0,.346-.035,1.155-.1,2.422Z" transform="translate(-247.602 -463.865)" fill="#fff" />
                                            <path id="Tracé_1148" data-name="Tracé 1148" d="M377.378,488.194q-.242,0-2.249-.138c-1.384.208-2.191.314-2.422.314-.161,0-.242-.164-.242-.484V465.362q0-1.072.519-1.072.242,0,2.041.1,1.73-.138,2.006-.138.415,0,.415.45c0,.069-.012.327-.035.779s-.035.893-.035,1.331v17.47q0,1.315.208,3.6C377.586,488.091,377.517,488.194,377.378,488.194Z" transform="translate(-234.748 -463.669)" fill="#fff" />
                                            <path id="Tracé_1149" data-name="Tracé 1149" d="M398.441,487.345a.579.579,0,0,1-.346.554,5.707,5.707,0,0,1-2.768.623,3.713,3.713,0,0,1-3.805-3.01l-1.036-3.425c-.071-.3-.138-.6-.21-.9a.748.748,0,0,0-.727-.588,3.6,3.6,0,0,0-.777.242,3.054,3.054,0,0,1-.918.242l-3.6.035a1.7,1.7,0,0,0-.881.155,1.435,1.435,0,0,0-.4.745L381.7,486.41q-.176.761-.314,1.488-.138.484-.484.484t-1.935-.242h-2.389a.306.306,0,0,1-.346-.346,18.917,18.917,0,0,0,.588-1.8l5.777-18.819c.21-.784.427-1.58.657-2.387q.208-.519,1.211-.519.277,0,2.076.1l2.249-.1a1.379,1.379,0,0,1,1.091.348,8.278,8.278,0,0,1,.849,2.143l4.809,15.222q.654,2.044,1.624,2.043a1.869,1.869,0,0,0,.59-.122,2.994,2.994,0,0,1,.38-.122c.159,0,.242.127.242.38,0,.208-.037.692-.1,1.453Q398.439,487.068,398.441,487.345Zm-9.168-10.309a1.641,1.641,0,0,0-.069-.45l-1.557-5.152a13.108,13.108,0,0,0-.8-2.182.7.7,0,0,0-.586-.38c-.092,0-.152.173-.175.519a9.907,9.907,0,0,1-.381,2.043l-1.488,5.221a2.021,2.021,0,0,0-.067.277c0,.231.182.346.551.346h4.082C389.112,477.278,389.273,477.2,389.273,477.035Z" transform="translate(-229.83 -463.65)" fill="#fff" />
                                            <path id="Tracé_1150" data-name="Tracé 1150" d="M402.811,468.209c0,.274-.15.413-.45.413-.069,0-.341-.012-.812-.035s-.918-.035-1.333-.035h-3.01q-1,0-1,.9v13.284q0,1.73,1,1.73a1.775,1.775,0,0,0,.777-.242,1.978,1.978,0,0,1,.607-.242q.277,0,.277.346v1.557c.138.922.208,1.455.208,1.591a.6.6,0,0,1-.346.519,6.21,6.21,0,0,1-3.321.761q-4.082,0-4.082-4.359V470.628a3.86,3.86,0,0,1,.191-.934,4.761,4.761,0,0,0,.189-.8q0-.38-.657-.38-.623,0-2.629.035-.277,0-1.626.069c-.9.046-1.4.071-1.488.071-.277,0-.415-.129-.415-.38q0-.28.069-1.87-.1-1.176-.1-1.453,0-.484.554-.484h15.014a4.53,4.53,0,0,0,1.488-.208,4.775,4.775,0,0,1,.657-.208.184.184,0,0,1,.208.208c0,.256-.023.853-.069,1.8Z" transform="translate(-218.563 -463.885)" fill="#fff" />
                                            <path id="Tracé_1151" data-name="Tracé 1151" d="M399.128,488.194q-.242,0-2.249-.138c-1.384.208-2.191.314-2.422.314-.161,0-.242-.164-.242-.484V465.362q0-1.072.519-1.072.242,0,2.041.1,1.73-.138,2.006-.138.415,0,.415.45c0,.069-.012.327-.035.779s-.035.893-.035,1.331v17.47q0,1.315.208,3.6C399.336,488.091,399.267,488.194,399.128,488.194Z" transform="translate(-206.336 -463.669)" fill="#fff" />
                                            <path id="Tracé_1152" data-name="Tracé 1152" d="M417.787,476.386q0,6.5-3.252,9.686a9.4,9.4,0,0,1-6.954,2.664q-9.721,0-9.721-12.108a17.28,17.28,0,0,1,1-5.83,10.767,10.767,0,0,1,2.629-4.338,6.8,6.8,0,0,0,2.887-1.195,7.343,7.343,0,0,1,3.893-1.16Q417.787,464.105,417.787,476.386Zm-5.328.208q0-8.476-4.532-8.476a3.848,3.848,0,0,0-3.356,1.868q-1.418,2.145-1.418,6.5,0,8.168,4.636,8.164a3.725,3.725,0,0,0,3.287-1.9A11.268,11.268,0,0,0,412.46,476.594Z" transform="translate(-201.575 -463.865)" fill="#fff" />
                                            <path id="Tracé_1153" data-name="Tracé 1153" d="M430.165,487.592a.6.6,0,0,1-.346.519,5.643,5.643,0,0,1-2.94.761q-4.255,0-4.255-4.979v-7.821a12.723,12.723,0,0,0-.9-5.639,3.674,3.674,0,0,0-3.563-1.9,3.591,3.591,0,0,0-3.494,2.145,12.718,12.718,0,0,0-.865,5.362v10.586c0,.115.012.337.035.657v.623c0,.417-.15.623-.45.623q-.173,0-1.972-.138-1.9.138-2.179.138-.346,0-.346-.519c0-.115.012-.334.035-.655V475.38a11.1,11.1,0,0,1,.969-5.016,2.919,2.919,0,0,0,.381-1.174,1.6,1.6,0,0,0-.657-1.109l-1.488-1.349a2.689,2.689,0,0,1-.468-.517.813.813,0,0,1-.155-.417.4.4,0,0,1,.242-.346l1.418-.9a7.477,7.477,0,0,1,.83-.5.934.934,0,0,1,.346-.051.96.96,0,0,1,.588.205l1.626,1.245a1.435,1.435,0,0,0,.865.346,3,3,0,0,0,1.28-.381,9.524,9.524,0,0,1,4.151-1.176q8.649,0,8.649,10.966v7.784q0,1.591.969,1.591a1.771,1.771,0,0,0,.83-.242,2,2,0,0,1,.554-.242.282.282,0,0,1,.208.069.471.471,0,0,1,.069.311c0,.187-.035.727-.1,1.628Q430.165,487.28,430.165,487.592Z" transform="translate(-188.975 -464.001)" fill="#fff" />
                                        </g>
                                        <g id="Groupe_214" data-name="Groupe 214" transform="translate(122.618 287.028)">
                                            <path id="Tracé_1154" data-name="Tracé 1154" d="M329.755,395.106a6.721,6.721,0,0,1-2.249,5.431,9.122,9.122,0,0,1-6.089,1.9q-4.255,0-6.227-2.456a1.263,1.263,0,0,0-.969-.554h-1.28q-.45,0-.45-.553V379.019q0-.8.208-.8a1.123,1.123,0,0,1,.242.051,1,1,0,0,0,.242.053q-.073,0,2.957-.191t4.965-.189q7.3,0,7.3,5.916a4.833,4.833,0,0,1-2.352,4.566q-.45.277-.45.553c0,.208.161.357.484.447a4.757,4.757,0,0,1,2.68,2.078A6.575,6.575,0,0,1,329.755,395.106ZM323.6,384.312a2.765,2.765,0,0,0-.883-2.214,3.291,3.291,0,0,0-2.23-.761,21.168,21.168,0,0,0-2.837.138q-.415.069-.415.761v4.809c0,.369.173.551.519.551h2.595Q323.593,387.6,323.6,384.312Zm.934,10.551q0-3.805-4.255-3.808H317.82a.336.336,0,0,1-.261-.155c-.081-.1-.145-.154-.189-.154q-.138,0-.138.865v3.007q0,4.259,3.7,4.257a3.372,3.372,0,0,0,2.645-1.056A4.259,4.259,0,0,0,324.531,394.864Z" transform="translate(-311.8 -377.567)" fill="#fff" />
                                            <path id="Tracé_1155" data-name="Tracé 1155" d="M326.494,401.976c-.161,0-.913-.046-2.249-.138q-2.076.311-2.422.311c-.161,0-.242-.164-.242-.484V379.144c0-.715.173-1.075.519-1.075.159,0,.842.037,2.041.106,1.153-.092,1.82-.138,2.007-.138q.415,0,.415.45c0,.069-.012.33-.035.777s-.035.895-.035,1.333v17.47q0,1.315.208,3.6Q326.7,401.976,326.494,401.976Z" transform="translate(-299.926 -377.449)" fill="#fff" />
                                            <path id="Tracé_1156" data-name="Tracé 1156" d="M343.863,399.887a.336.336,0,0,1-.38.381c-.118,0-.567-.021-1.349-.067a1.642,1.642,0,0,0-.934.38,11.361,11.361,0,0,1-6.953,1.937,9.215,9.215,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.283-5.69,8.778,8.778,0,0,1,1-4.064,11.2,11.2,0,0,0,1-2.232,8.3,8.3,0,0,0-.309-1.594,8.623,8.623,0,0,1-.314-2.108,6.246,6.246,0,0,1,2.424-5.189,9.086,9.086,0,0,1,5.708-1.73,12.625,12.625,0,0,1,6.019,1.139.7.7,0,0,1,.413.59,16.111,16.111,0,0,0-.727,3.838,1.907,1.907,0,0,1-.551-.274,6.667,6.667,0,0,0-4.324-1.245q-4.186,0-4.186,3.25,0,2.183,2.041,2.182h5.431a.46.46,0,0,1,.519.519c0,.161-.025.669-.069,1.52q.1,1.387.1,1.73c0,.279-.185.417-.554.417h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.853,4.853,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.353c0-.277.1-.415.309-.415.161,0,.623.012,1.386.035l1.831-.069q.381,0,.381.415c0,.323-.021,1.107-.067,2.352C343.826,398.621,343.863,399.543,343.863,399.887Z" transform="translate(-294.635 -377.645)" fill="#fff" />
                                            <path id="Tracé_1157" data-name="Tracé 1157" d="M357.126,401.375a.605.605,0,0,1-.346.519,5.648,5.648,0,0,1-2.94.761q-4.255,0-4.255-4.982v-7.818a12.71,12.71,0,0,0-.9-5.639,3.675,3.675,0,0,0-3.563-1.9,3.591,3.591,0,0,0-3.494,2.143,12.737,12.737,0,0,0-.865,5.365V400.4c0,.118.012.337.035.66v.623c0,.415-.152.623-.45.623q-.173,0-1.972-.138-1.9.138-2.179.138c-.231,0-.346-.175-.346-.519q0-.173.035-.657V389.161a11.093,11.093,0,0,1,.969-5.014,2.926,2.926,0,0,0,.381-1.176,1.6,1.6,0,0,0-.657-1.109l-1.487-1.349a2.566,2.566,0,0,1-.468-.517.807.807,0,0,1-.154-.415.4.4,0,0,1,.242-.346l1.418-.9a7.722,7.722,0,0,1,.83-.5,1.045,1.045,0,0,1,.346-.051.963.963,0,0,1,.588.208l1.626,1.245a1.435,1.435,0,0,0,.865.346,3,3,0,0,0,1.28-.381,9.524,9.524,0,0,1,4.151-1.176q8.649,0,8.649,10.966v7.784q0,1.591.969,1.591a1.8,1.8,0,0,0,.83-.242,1.922,1.922,0,0,1,.554-.242.273.273,0,0,1,.208.069.471.471,0,0,1,.069.311q0,.277-.1,1.626C357.078,400.672,357.126,401.165,357.126,401.375Z" transform="translate(-283.094 -377.782)" fill="#fff" />
                                            <path id="Tracé_1158" data-name="Tracé 1158" d="M330.216,415.235a.336.336,0,0,1-.38.381c-.115,0-.473-.018-1.072-.053s-.957-.051-1.072-.051a1.191,1.191,0,0,0-.761.277,8.657,8.657,0,0,1-5.6,1.73q-4.5,0-6.746-2.8-2.387-3.01-2.387-9.479,0-6.158,2.768-9.444a8.046,8.046,0,0,1,6.4-2.906,8.582,8.582,0,0,1,5.674,1.661,1.334,1.334,0,0,0,.865.344c.115,0,.392-.009.83-.032h.761c.208,0,.311.136.311.413q0,.522-.069,2.458l.069,2.975q0,.45-.346.45-.208,0-1.557-.106-1.28.107-1.522.106a.335.335,0,0,1-.381-.381,3.68,3.68,0,0,0-1.176-2.908,4.066,4.066,0,0,0-2.7-.9q-4.636,0-4.636,8.095,0,4.532,1.245,6.469a3.812,3.812,0,0,0,3.459,1.73,4.159,4.159,0,0,0,2.991-1.038,3.732,3.732,0,0,0,1.091-2.837q0-.38.346-.381.207,0,1.661.1,1.418-.1,1.661-.1a.282.282,0,0,1,.208.069.63.63,0,0,1,.069.38q0,.519-.1,2.422Z" transform="translate(-312.192 -358.051)" fill="#fff" />
                                            <path id="Tracé_1159" data-name="Tracé 1159" d="M341.477,405.168q0,6.5-3.252,9.686a9.4,9.4,0,0,1-6.951,2.664q-9.721,0-9.723-12.108a17.244,17.244,0,0,1,1-5.828,10.77,10.77,0,0,1,2.629-4.343,6.772,6.772,0,0,0,2.89-1.195,7.332,7.332,0,0,1,3.891-1.158Q341.477,392.887,341.477,405.168Zm-5.328.205q0-8.472-4.53-8.473a3.849,3.849,0,0,0-3.358,1.868q-1.418,2.145-1.416,6.5,0,8.168,4.633,8.164a3.72,3.72,0,0,0,3.286-1.9A11.276,11.276,0,0,0,336.15,405.373Z" transform="translate(-299.966 -358.051)" fill="#fff" />
                                            <path id="Tracé_1160" data-name="Tracé 1160" d="M353.856,416.375a.606.606,0,0,1-.346.519,5.649,5.649,0,0,1-2.941.761q-4.255,0-4.255-4.982v-7.818q0-3.978-.9-5.639a3.674,3.674,0,0,0-3.563-1.9,3.591,3.591,0,0,0-3.494,2.142,12.739,12.739,0,0,0-.865,5.365V415.4c0,.118.012.337.035.66v.623c0,.415-.152.623-.45.623q-.173,0-1.972-.138-1.9.138-2.179.138c-.231,0-.346-.175-.346-.519q0-.173.035-.657V404.161a11.093,11.093,0,0,1,.969-5.014,2.926,2.926,0,0,0,.381-1.176,1.6,1.6,0,0,0-.657-1.109l-1.488-1.349a2.563,2.563,0,0,1-.468-.517.807.807,0,0,1-.155-.415.4.4,0,0,1,.242-.346l1.418-.9a7.72,7.72,0,0,1,.83-.5,1.044,1.044,0,0,1,.346-.051.964.964,0,0,1,.588.208l1.626,1.245a1.436,1.436,0,0,0,.865.346,3,3,0,0,0,1.28-.381,9.525,9.525,0,0,1,4.151-1.176q8.649,0,8.649,10.966v7.784q0,1.591.969,1.591a1.807,1.807,0,0,0,.83-.242,1.922,1.922,0,0,1,.553-.242.272.272,0,0,1,.208.069.47.47,0,0,1,.069.311q0,.277-.1,1.626C353.808,415.672,353.856,416.166,353.856,416.375Z" transform="translate(-287.366 -358.188)" fill="#fff" />
                                            <path id="Tracé_1161" data-name="Tracé 1161" d="M359.361,410.115a6.809,6.809,0,0,1-2.214,5.293,8.879,8.879,0,0,1-6.264,2.11,9.646,9.646,0,0,1-6.054-1.7,1.016,1.016,0,0,0-.655-.175H342.65c-.344,0-.519-.148-.519-.447q0-.277.138-2.871l-.035-2.631c0-.3.161-.447.484-.447.118,0,.6.035,1.455.1q1.242-.1,1.381-.1a.4.4,0,0,1,.452.447,3.56,3.56,0,0,0,1.158,2.735,4.489,4.489,0,0,0,3.167,1.072,4.191,4.191,0,0,0,2.784-.833,2.741,2.741,0,0,0,.985-2.214q0-2.072-3.007-3.18-2.771-1.038-5.57-2.041a5.367,5.367,0,0,1-3.012-5.053,6.64,6.64,0,0,1,2.424-5.394,9.258,9.258,0,0,1,6.02-1.9q4.67,0,7.057,1.8a.783.783,0,0,1,.381.623,5.963,5.963,0,0,1-.694,1.833q-.1.692-.277,1.834c-.069.254-.2.38-.378.38a.434.434,0,0,1-.314-.141,7.838,7.838,0,0,0-2.629-1.64,8.642,8.642,0,0,0-3.286-.676,3.844,3.844,0,0,0-2.369.69,2.225,2.225,0,0,0-.916,1.87q0,1.834,3.079,2.871a47.543,47.543,0,0,1,5.673,2.11A6.193,6.193,0,0,1,359.361,410.115Z" transform="translate(-273.083 -358.051)" fill="#fff" />
                                            <path id="Tracé_1162" data-name="Tracé 1162" d="M370.877,405.168q0,6.5-3.252,9.686a9.4,9.4,0,0,1-6.951,2.664q-9.725,0-9.723-12.108a17.244,17.244,0,0,1,1-5.828,10.769,10.769,0,0,1,2.629-4.343,6.783,6.783,0,0,0,2.89-1.195,7.332,7.332,0,0,1,3.891-1.158Q370.877,392.887,370.877,405.168Zm-5.328.205q0-8.472-4.532-8.473a3.848,3.848,0,0,0-3.356,1.868q-1.418,2.145-1.416,6.5,0,8.168,4.633,8.164a3.722,3.722,0,0,0,3.286-1.9A11.276,11.276,0,0,0,365.55,405.373Z" transform="translate(-261.561 -358.051)" fill="#fff" />
                                            <path id="Tracé_1163" data-name="Tracé 1163" d="M392.926,416.36a.628.628,0,0,1-.346.553,5.761,5.761,0,0,1-2.941.761q-4.255,0-4.255-4.878v-8.3a13.918,13.918,0,0,0-.78-5.517,2.841,2.841,0,0,0-2.818-1.644,2.621,2.621,0,0,0-2.595,1.554,11.532,11.532,0,0,0-.623,4.465v13.354a1.145,1.145,0,0,1-.069.535c-.046.06-.208.088-.484.088q-.45,0-2.076-.138-1.418.138-1.73.138-.519,0-.519-.692V403.353a9.525,9.525,0,0,0-.83-4.619,3.065,3.065,0,0,0-2.871-1.4,2.711,2.711,0,0,0-2.733,1.972,16.131,16.131,0,0,0-.623,5.293v10.826c0,.118.012.337.035.66v.623q0,.623-.415.623-.173,0-2.352-.138-1.626.138-1.9.138-.311,0-.311-.484c0-.115.012-.334.035-.66v-12a11,11,0,0,1,1.176-5.394,1.771,1.771,0,0,0,.242-.83q0-.381-.692-1.075l-1.7-1.658a.774.774,0,0,1-.277-.519.53.53,0,0,1,.242-.45l1.972-1.317a.941.941,0,0,1,.45-.171,1.013,1.013,0,0,1,.623.242l1.522,1.245a1.165,1.165,0,0,0,.727.309,1.718,1.718,0,0,0,.8-.24,9.013,9.013,0,0,1,3.875-1.28,7.28,7.28,0,0,1,4.359,1.624,2.116,2.116,0,0,0,1.245.484,2.153,2.153,0,0,0,1.176-.447,8.835,8.835,0,0,1,4.912-1.661q7.887,0,7.887,10.344v8.268q0,1.73,1.038,1.73a1.559,1.559,0,0,0,.761-.242,1.46,1.46,0,0,1,.588-.242c.092,0,.15.021.173.069a.861.861,0,0,1,.035.311q0,.277-.069,1.7Q392.926,416.049,392.926,416.36Z" transform="translate(-249.119 -358.207)" fill="#fff" />
                                            <path id="Tracé_1164" data-name="Tracé 1164" d="M407.1,416.36a.631.631,0,0,1-.348.553,5.755,5.755,0,0,1-2.941.761q-4.255,0-4.253-4.878v-8.3a13.961,13.961,0,0,0-.779-5.517,2.845,2.845,0,0,0-2.818-1.644,2.622,2.622,0,0,0-2.595,1.554,11.489,11.489,0,0,0-.623,4.465v13.354a1.135,1.135,0,0,1-.072.535c-.046.06-.208.088-.484.088q-.45,0-2.076-.138-1.418.138-1.73.138c-.344,0-.519-.231-.519-.692V403.353a9.525,9.525,0,0,0-.83-4.619,3.065,3.065,0,0,0-2.871-1.4,2.711,2.711,0,0,0-2.733,1.972,16.192,16.192,0,0,0-.623,5.293v10.826c0,.118.011.337.035.66v.623q0,.623-.415.623c-.115,0-.9-.046-2.352-.138q-1.622.138-1.9.138c-.208,0-.314-.161-.314-.484,0-.115.012-.334.035-.66v-12a10.974,10.974,0,0,1,1.178-5.394,1.8,1.8,0,0,0,.242-.83q0-.381-.694-1.075l-1.7-1.658a.774.774,0,0,1-.277-.519.535.535,0,0,1,.242-.45l1.972-1.317a.94.94,0,0,1,.452-.171,1.023,1.023,0,0,1,.623.242l1.52,1.245a1.166,1.166,0,0,0,.727.309,1.711,1.711,0,0,0,.8-.24,9.013,9.013,0,0,1,3.877-1.28,7.274,7.274,0,0,1,4.357,1.624,2.116,2.116,0,0,0,1.245.484,2.165,2.165,0,0,0,1.178-.447,8.823,8.823,0,0,1,4.91-1.661q7.887,0,7.888,10.344v8.268q0,1.73,1.04,1.73a1.567,1.567,0,0,0,.761-.242,1.436,1.436,0,0,1,.588-.242c.09,0,.15.021.171.069a.808.808,0,0,1,.035.311q0,.277-.067,1.7C407.054,415.692,407.1,416.153,407.1,416.36Z" transform="translate(-230.602 -358.207)" fill="#fff" />
                                            <path id="Tracé_1165" data-name="Tracé 1165" d="M407.6,414.887a.336.336,0,0,1-.381.381c-.115,0-.565-.021-1.349-.067a1.635,1.635,0,0,0-.932.38,11.362,11.362,0,0,1-6.954,1.937,9.226,9.226,0,0,1-6.333-2.025,7.2,7.2,0,0,1-2.281-5.69,8.778,8.778,0,0,1,1-4.064,11.2,11.2,0,0,0,1-2.232,8.494,8.494,0,0,0-.309-1.594,8.622,8.622,0,0,1-.314-2.108,6.24,6.24,0,0,1,2.424-5.189,9.086,9.086,0,0,1,5.708-1.73,12.625,12.625,0,0,1,6.019,1.139.7.7,0,0,1,.413.59,15.811,15.811,0,0,0-.727,3.838,1.876,1.876,0,0,1-.551-.274,6.667,6.667,0,0,0-4.324-1.245q-4.186,0-4.186,3.25,0,2.183,2.039,2.182h5.431a.459.459,0,0,1,.519.519q0,.242-.067,1.52c.067.925.1,1.5.1,1.73,0,.279-.184.417-.556.417h-6.4q-2.076,0-2.073,2.422,0,4.324,4.668,4.324a4.859,4.859,0,0,0,3.358-1.142,3.091,3.091,0,0,0,1.107-2.352c0-.277.1-.415.309-.415q.242,0,1.384.035l1.834-.069q.38,0,.381.415c0,.323-.021,1.107-.067,2.352Q407.595,414.37,407.6,414.887Z" transform="translate(-211.379 -358.051)" fill="#fff" />
                                            <path id="Tracé_1166" data-name="Tracé 1166" d="M417.6,413.046q0,.208-.138,1.833c.046.782.069,1.245.069,1.384a.514.514,0,0,1-.346.519,7.076,7.076,0,0,1-2.629.657,5.229,5.229,0,0,1-4.843-3.286l-2.871-5.708a1.3,1.3,0,0,0-1.107-.761q-1.488-.1-1.591-.1c-.346,0-.519.231-.519.69v8.3c0,.344-.127.519-.381.519a18.9,18.9,0,0,1-2.11-.138,21.521,21.521,0,0,1-2.179.138c-.161,0-.242-.058-.242-.175a1.716,1.716,0,0,1,.1-.413,1.655,1.655,0,0,0,.1-.452v-21.9q0-.553.415-.623a50.24,50.24,0,0,1,8.441-.588,7.925,7.925,0,0,1,5.4,1.7,6.763,6.763,0,0,1,2.11,5.4,7.325,7.325,0,0,1-1.019,3.821,6.107,6.107,0,0,1-2.613,2.475q-.415.208-.415.415a1.428,1.428,0,0,0,.173.45q.8,1.557,2.352,4.29.934,1.626,2.11,1.626a2.335,2.335,0,0,0,.934-.191,2.238,2.238,0,0,1,.553-.189Q417.6,412.735,417.6,413.046Zm-7.334-12.56q0-3.906-3.632-3.907a16.356,16.356,0,0,0-2.664.208.5.5,0,0,0-.484.553c0,.138.023.454.069.95s.069.929.069,1.3v3.148a1.245,1.245,0,0,0,.9,1.384,7.545,7.545,0,0,0,1.937.205,3.86,3.86,0,0,0,2.768-.983A3.759,3.759,0,0,0,410.266,400.486Z" transform="translate(-199.173 -357.972)" fill="#fff" />
                                            <path id="Tracé_1167" data-name="Tracé 1167" d="M328.9,429.917q0,.381-.45.38-.034,0-.657-.035H327.1a1.705,1.705,0,0,0-.9.346,11.55,11.55,0,0,1-6.608,1.7q-7.369,0-7.369-7.784V408.953c0-.6.161-.9.484-.9.138,0,.727.048,1.764.141l2.283-.141c.277,0,.415.175.415.521q0,.1-.035.865t-.035,1.349v12.592q0,4.566,3.7,4.564,4.151,0,4.151-3.838,0-.45.346-.45.277,0,1.176.035l1.972-.072a.369.369,0,0,1,.415.417q0,.484-.069,2.7Z" transform="translate(-312.153 -338.242)" fill="#fff" />
                                            <path id="Tracé_1168" data-name="Tracé 1168" d="M338.568,429.887a.336.336,0,0,1-.381.381c-.118,0-.565-.021-1.349-.067a1.641,1.641,0,0,0-.934.38,11.356,11.356,0,0,1-6.953,1.937,9.216,9.216,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.283-5.69,8.778,8.778,0,0,1,1-4.064,11.132,11.132,0,0,0,1.006-2.232,8.378,8.378,0,0,0-.311-1.594,8.606,8.606,0,0,1-.311-2.108,6.244,6.244,0,0,1,2.422-5.189,9.086,9.086,0,0,1,5.708-1.73,12.626,12.626,0,0,1,6.019,1.139.7.7,0,0,1,.413.59,11.176,11.176,0,0,1-.447,1.73,11.632,11.632,0,0,1-.277,2.108,1.881,1.881,0,0,1-.554-.274,6.667,6.667,0,0,0-4.324-1.245q-4.186,0-4.186,3.25,0,2.183,2.041,2.182h5.431a.46.46,0,0,1,.519.519c0,.161-.025.669-.069,1.52q.1,1.387.1,1.73c0,.279-.184.417-.553.417h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.852,4.852,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.352q0-.415.311-.415c.159,0,.623.012,1.384.035l1.834-.069c.251,0,.38.138.38.415q0,.484-.069,2.352Q338.568,429.37,338.568,429.887Z" transform="translate(-301.552 -338.456)" fill="#fff" />
                                            <path id="Tracé_1169" data-name="Tracé 1169" d="M365.643,431.36a.631.631,0,0,1-.348.554,5.755,5.755,0,0,1-2.94.761q-4.255,0-4.253-4.878v-8.3a13.959,13.959,0,0,0-.78-5.517,2.845,2.845,0,0,0-2.818-1.644,2.621,2.621,0,0,0-2.595,1.554,11.486,11.486,0,0,0-.623,4.465v13.353a1.136,1.136,0,0,1-.071.535c-.046.06-.205.088-.484.088-.3,0-.992-.046-2.076-.138q-1.418.138-1.73.138c-.344,0-.519-.231-.519-.692V418.353a9.557,9.557,0,0,0-.828-4.619,3.072,3.072,0,0,0-2.874-1.4,2.711,2.711,0,0,0-2.733,1.972,16.193,16.193,0,0,0-.623,5.293v10.826c0,.118.012.337.035.66v.623q0,.623-.415.623c-.115,0-.9-.046-2.353-.138q-1.622.138-1.9.138c-.208,0-.314-.161-.314-.484,0-.115.012-.334.035-.66v-12a10.973,10.973,0,0,1,1.179-5.394,1.772,1.772,0,0,0,.242-.83q0-.38-.694-1.075l-1.693-1.658a.768.768,0,0,1-.279-.519.535.535,0,0,1,.242-.45l1.972-1.317a.94.94,0,0,1,.452-.171,1.018,1.018,0,0,1,.623.242l1.52,1.245a1.165,1.165,0,0,0,.727.309,1.726,1.726,0,0,0,.8-.24,9,9,0,0,1,3.875-1.28,7.274,7.274,0,0,1,4.357,1.624,2.116,2.116,0,0,0,1.245.484,2.165,2.165,0,0,0,1.179-.447,8.824,8.824,0,0,1,4.912-1.661q7.884,0,7.885,10.344v8.268q0,1.73,1.04,1.73a1.566,1.566,0,0,0,.761-.242,1.436,1.436,0,0,1,.588-.242c.09,0,.15.021.171.069a.808.808,0,0,1,.035.311q0,.277-.067,1.7C365.594,430.692,365.643,431.153,365.643,431.36Z" transform="translate(-284.761 -338.613)" fill="#fff" />
                                            <path id="Tracé_1170" data-name="Tracé 1170" d="M353.256,431.976q-.246,0-2.249-.138-2.079.311-2.424.311c-.161,0-.242-.164-.242-.484V409.144c0-.715.175-1.075.519-1.075.161,0,.844.037,2.043.106,1.153-.092,1.82-.138,2-.138q.415,0,.415.45c0,.069-.009.33-.035.777s-.032.895-.032,1.333v17.47c0,.876.067,2.076.208,3.6Q353.463,431.976,353.256,431.976Z" transform="translate(-264.971 -338.26)" fill="#fff" />
                                            <path id="Tracé_1171" data-name="Tracé 1171" d="M370.621,429.887a.335.335,0,0,1-.38.381c-.115,0-.565-.021-1.349-.067a1.635,1.635,0,0,0-.932.38A11.364,11.364,0,0,1,361,432.518a9.224,9.224,0,0,1-6.331-2.025,7.2,7.2,0,0,1-2.281-5.69,8.778,8.778,0,0,1,1-4.064,11.2,11.2,0,0,0,1-2.232,8.371,8.371,0,0,0-.311-1.594,8.718,8.718,0,0,1-.311-2.108,6.24,6.24,0,0,1,2.424-5.189,9.081,9.081,0,0,1,5.708-1.73,12.609,12.609,0,0,1,6.017,1.139.694.694,0,0,1,.415.59,11.342,11.342,0,0,1-.45,1.73,11.218,11.218,0,0,1-.277,2.108,1.883,1.883,0,0,1-.553-.274,6.658,6.658,0,0,0-4.324-1.245q-4.182,0-4.184,3.25,0,2.183,2.039,2.182h5.431a.459.459,0,0,1,.519.519q0,.242-.067,1.52c.067.925.1,1.5.1,1.73,0,.279-.187.417-.556.417h-6.4q-2.076,0-2.076,2.422,0,4.324,4.67,4.324a4.853,4.853,0,0,0,3.356-1.142,3.083,3.083,0,0,0,1.107-2.352q0-.415.311-.415.242,0,1.384.035l1.833-.069q.381,0,.381.415,0,.484-.069,2.352Q370.621,429.37,370.621,429.887Z" transform="translate(-259.679 -338.456)" fill="#fff" />
                                            <path id="Tracé_1172" data-name="Tracé 1172" d="M378.5,429.917q0,.381-.45.38-.035,0-.657-.035H376.7a1.706,1.706,0,0,0-.9.346,11.545,11.545,0,0,1-6.608,1.7q-7.369,0-7.369-7.784V408.953c0-.6.161-.9.484-.9.138,0,.727.048,1.764.141l2.285-.141c.275,0,.413.175.413.521q0,.1-.035.865t-.035,1.349v12.592q0,4.566,3.7,4.564,4.151,0,4.151-3.838,0-.45.346-.45.277,0,1.176.035l1.972-.072a.37.37,0,0,1,.417.417c0,.323-.025,1.222-.072,2.7Z" transform="translate(-247.355 -338.242)" fill="#fff" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="col mr-200">
                        <div className="img-c">
                            <img className="visual w-80 full-height move" id="3" onClick={openVisual} src="../image/miels/mielspots.webp" data-scroll data-scroll-speed={0.4} data-scroll-direction="horizontal"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}