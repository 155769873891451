import { useState, useEffect } from "react";
import './homepage.css';
import Socials from './Socials';

export default function HomePage({scroll = null, scrollTo= function(newX){}}) {
    const [windowDimenion, detectHW] = useState({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });

    const detectSize = () => {
      detectHW({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
    };

    useEffect(() => {
      window.addEventListener("resize", detectSize);

      return () => {
        window.removeEventListener("resize", detectSize);
      };
    }, [windowDimenion]);

    const [videoVisibility, setVideoVisibilty] = useState("vid-container hidden");
    const [bgSize, setBgSize] = useState("background");

    const openVideo = () => {
        setVideoVisibilty("vid-container");
        setBgSize("background little");
    };

    const closeVideo = () => {
        setVideoVisibilty("vid-container hidden");
        setBgSize("background");
    };
//vegkdfg

    return (
        <div className="home page" data-scroll data-scroll-speed={-8} data-scroll-direction="horizontal">

            <img className={bgSize} src="./image/poissonpapier.webp" alt="Poisson fond d'écran Imagic"></img>
            <h1>
                <span className="line-container"><span className="line-text">Vous emmener</span></span>
                <span className="line-container"><span className="line-text">au delà de vos</span></span>
                <span className="line-container"><span className="line-text">ambitions</span></span>
            </h1>
            <button className="btnVideo" onClick={openVideo}>Voir notre vidéo de présentation
                <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                    <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#fff"/>
                </svg>
            </button>
            <Socials></Socials>
            <button onClick={function(){scrollTo(windowDimenion.winWidth)}} className="scrollBtn">Scroll
                <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                    <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#fff"/>
                </svg>
            </button>
            <div className={videoVisibility}>
                <svg onClick={closeVideo} xmlns="http://www.w3.org/2000/svg" width="26.414" height="26.414" viewBox="0 0 26.414 26.414">
                    <g id="Groupe_2" data-name="Groupe 2" transform="translate(-470.293 -91.928)">
                        <line id="Ligne_7" data-name="Ligne 7" x2="25" y2="25" transform="translate(471 92.635)" fill="none" stroke="#fff" strokeWidth="2"/>
                        <line id="Ligne_8" data-name="Ligne 8" x2="25" y2="25" transform="translate(496 92.635) rotate(90)" fill="none" stroke="#fff" strokeWidth="2"/>
                    </g>
                </svg>
                {/* <video controls width="250" preload='auto'>
                    <source src="../video/showreel.mp4" type="video/mp4"></source>
                    <source src="../video/showreel.webm" type="video/webm"></source>
                </video> */}
                <iframe className="video" src="https://player.vimeo.com/video/711680725?h=50eede172f" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    );
}