import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import Burger from "../component/Burger";
import { NavLink } from "react-router-dom";
import Draggable from "react-draggable";

export default function Aquaschool() {
    const ref = useRef(null);
    const [x, setX] = useState(null);
    const [scrollObj, setScrollObj] = useState(null);

    useEffect(() => {
        //console.log(x);
        if (ref && !scrollObj) {
            const scroll = new LocomotiveScroll({
                el: ref.current,
                smooth: true,
                direction: "horizontal",
                lerp: 0.05,
                reloadOnContextChange: true,
                tablet: {
                    breakpoint: 1224,
                    lerp: 0.1,
                    multiplier: 3
                },
            });
            scroll.on("scroll", (args) => {
                // Get all current elements : args.currentElements
                setX(args.scroll.x);
                //console.log(args);
            });

            setScrollObj(scroll);
        }
    }, []);

    const [classBig, setClassBig] = useState("");
    const visualizer = document.querySelector(".visualizer");
    const imgVisual = document.querySelector(".img-visual");
    const tabSrc = ["../image/aquaschool/aquaschoolstart.webp", "../image/aquaschool/aquaschoolsmartphone.webp", "../image/aquaschool/aquaschoolsmartphone2.webp"];
    const [imgCounter, setImgCounter] = useState("");

    const openVisual = e => {
        if (window.innerWidth <= 800) {
            visualizer.style.display = "block";
            setImgCounter(parseInt(e.currentTarget.id) + 1);
            visualizer.setAttribute("id", e.currentTarget.id);
            imgVisual.src = tabSrc[e.currentTarget.id];
        }

    };

    const closeVisual = () => {
        visualizer.style.display = "none";
        setClassBig("");
    };

    const previous = () => {
        if (imgCounter > 1) {
            setImgCounter(imgCounter - 1);
            visualizer.setAttribute("id", imgCounter - 2);
            imgVisual.src = tabSrc[imgCounter - 2];
            setClassBig("");
        }

    };

    const next = () => {
        if (imgCounter < tabSrc.length) {
            setImgCounter(imgCounter + 1);
            visualizer.setAttribute("id", imgCounter);
            imgVisual.src = tabSrc[imgCounter];
            setClassBig("");
        }
    };

    return (
        <div className="projet">
            <div className="visualizer">
                <div className="count">
                    <span>{imgCounter}</span>
                    <span>/</span>
                    <span>{tabSrc.length}</span>
                </div>
                <button className="close" onClick={closeVisual}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.414" height="26.414" viewBox="0 0 26.414 26.414">
                        <g id="Groupe_2" data-name="Groupe 2" transform="translate(-470.293 -91.928)">
                            <line id="Ligne_7" data-name="Ligne 7" x2="25" y2="25" transform="translate(471 92.635)" fill="none" stroke="var(--white)" strokeWidth="2" />
                            <line id="Ligne_8" data-name="Ligne 8" x2="25" y2="25" transform="translate(496 92.635) rotate(90)" fill="none" stroke="var(--white)" strokeWidth="2" />
                        </g>
                    </svg>
                </button>
                <button className="right" onClick={next}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <button className="left" onClick={previous}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <div className="img-ctn">
                    <img className="img-visual" id={classBig} src="../image/poissonpapier.webp"></img>
                </div>
            </div>
            <CustomCursor />
            <Burger />
            <div className="fixed">
                <h1>Aquaschool</h1>
                <h2>
                    <span className="color">Devenu expert de l'éducation aquatique,</span> le centre AQUASCHOOL avait besoin de marquer cette évolution.
                </h2>
                <p>En proposant un lifting d'identité visuelle, un reportage photo suivi d'une refonte intégrale du site web nous avons pu ancrer cette reconnaissance et améliorer le référencement du site de façon notable.</p>
                <div className="nav">
                    <button className="back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                        Retour
                        <div className="back-choice">
                            <NavLink to="../realisations/sbc-dol">Projet précédent</NavLink>
                            <NavLink to="../realisations">Galerie principale</NavLink>
                        </div>
                    </button>
                    <NavLink className="forward" to="../realisations/actua-geveze">Projet suivant<svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                        <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#fff" />
                    </svg>
                    </NavLink>
                </div>
            </div>
            <div className="scroll-container" data-scroll-container ref={ref}>

                <div className="start-img">
                    <img className="visual" id="0" onClick={openVisual} data-scroll data-scroll-speed={-1} data-scroll-direction="horizontal" src="../image/aquaschool/aquaschoolstart.webp"></img>
                </div>
                <div className="projet-gallery-ctn">
                    <div className="col elm-center mr-100">
                            <div className="logotype xxl" data-scroll data-scroll-speed={0.4} data-scroll-direction="horizontal">
                            <svg xmlns="http://www.w3.org/2000/svg" width="602.557" height="199.037" viewBox="0 0 602.557 199.037">
                                    <g id="Groupe_79" data-name="Groupe 79" transform="translate(-261.202 -223.289)">
                                        <g id="Groupe_54" data-name="Groupe 54" transform="translate(261.202 352.944)">
                                            <path id="Tracé_252" data-name="Tracé 252" d="M571.056,301.784c-15.207,0-25.057,12.01-25.057,26.267,0,14.171,9.849,26.18,25.057,26.18,15.294,0,25.144-12.01,25.144-26.18C596.2,313.794,586.35,301.784,571.056,301.784Z" transform="translate(-42.037 -292.538)" fill="none" />
                                            <path id="Tracé_253" data-name="Tracé 253" d="M322.933,301.784c-15.208,0-25.057,12.01-25.057,26.267a27.143,27.143,0,0,0,5.875,17.28c5.7-5.183,13.652-8.294,23.416-8.294a37,37,0,0,1,17.625,4.406,27.93,27.93,0,0,0,3.284-13.392C348.076,313.794,338.227,301.784,322.933,301.784Z" transform="translate(-232.98 -292.538)" fill="none" />
                                            <path id="Tracé_254" data-name="Tracé 254" d="M529.3,301.784c-15.207,0-25.057,12.01-25.057,26.267,0,14.171,9.849,26.18,25.057,26.18,15.294,0,25.144-12.01,25.144-26.18C554.446,313.794,544.6,301.784,529.3,301.784Z" transform="translate(-74.168 -292.538)" fill="none" />
                                            <path id="Tracé_255" data-name="Tracé 255" d="M376.4,334.462h21.342l-10.714-26.526Z" transform="translate(-172.552 -287.803)" fill="none" />
                                            <path id="Tracé_256" data-name="Tracé 256" d="M271.066,334.462h21.342l-10.714-26.526Z" transform="translate(-253.611 -287.803)" fill="none" />
                                            <path id="Tracé_257" data-name="Tracé 257" d="M309.315,334.814a26.558,26.558,0,0,0,6.429.78,24.308,24.308,0,0,0,17.195-6.653A28.629,28.629,0,0,0,319.978,326a27.425,27.425,0,0,0-12.76,2.932" transform="translate(-225.79 -273.9)" fill="none" />
                                            <path id="Tracé_258" data-name="Tracé 258" d="M261.2,364.819h8.814l5.617-14h27.389l5.617,14h8.9l-28.253-68.26Zm17.455-21.6,10.628-26.527L300,343.218Z" transform="translate(-261.202 -296.559)" fill="#4b95af" />
                                            <path id="Tracé_259" data-name="Tracé 259" d="M359.855,331.358c0-18.663-13.22-33.871-33.352-33.871-20.045,0-33.266,15.207-33.266,33.871,0,11.575,5.093,21.778,13.739,27.867a31.355,31.355,0,0,1,11-8.345,27.426,27.426,0,0,1,12.76-2.932,28.628,28.628,0,0,1,12.96,2.938,24.308,24.308,0,0,1-17.195,6.653,26.558,26.558,0,0,1-6.429-.78,25.222,25.222,0,0,0-7.6,5.547,34.831,34.831,0,0,0,14.029,2.837c9.849,0,18.058-3.629,23.848-9.5a26.212,26.212,0,0,1,5.962,9.244l7.257-2.851a32.754,32.754,0,0,0-8.554-12.442A35.671,35.671,0,0,0,359.855,331.358ZM348.362,344.75a37,37,0,0,0-17.625-4.406c-9.764,0-17.713,3.111-23.416,8.294a27.143,27.143,0,0,1-5.875-17.28c0-14.257,9.849-26.267,25.057-26.267,15.294,0,25.143,12.01,25.143,26.267A27.93,27.93,0,0,1,348.362,344.75Z" transform="translate(-236.55 -295.845)" fill="#4b95af" />
                                            <path id="Tracé_260" data-name="Tracé 260" d="M373.521,342.225c0,4.321-1.382,14.949-13.824,14.949-12.528,0-13.91-10.628-13.91-14.949V298.073h-8.209v44.152c0,13.394,8.467,22.551,22.119,22.551s22.033-9.157,22.033-22.551V298.073h-8.209Z" transform="translate(-202.427 -295.394)" fill="#4b95af" />
                                            <path id="Tracé_261" data-name="Tracé 261" d="M366.536,364.819h8.814l5.617-14h27.389l5.617,14h8.9l-28.253-68.26Zm17.455-21.6,10.628-26.527,10.713,26.527Z" transform="translate(-180.142 -296.559)" fill="#4b95af" />
                                            <path id="Tracé_262" data-name="Tracé 262" d="M423.251,326c-12.268-5.358-13.737-8.3-13.737-12.443,0-3.629,2.332-8.554,11.231-8.554s11.32,6.4,11.32,10.023h8.209c0-5.443-3.716-17.54-19.182-17.54-18.058-.087-19.787,12.1-19.787,16.59,0,7.949,5.1,13.394,18.318,19.355,12.268,5.53,14.774,7.775,14.861,13.91,0,5.356-4.666,10.2-12.96,10.2a16.145,16.145,0,0,1-4.173-.524,25.2,25.2,0,0,0-7.88,5.064,24.16,24.16,0,0,0,12.658,3.063c17.193,0,20.564-12.183,20.564-17.887C442.693,336.628,436.213,331.616,423.251,326Z" transform="translate(-153.386 -295.845)" fill="#104986" />
                                            <path id="Tracé_263" data-name="Tracé 263" d="M408.977,324.489h-8.209a21.9,21.9,0,0,0,4.436,12.73,31.256,31.256,0,0,1,6.385-4.882A12.345,12.345,0,0,1,408.977,324.489Z" transform="translate(-153.799 -275.065)" fill="#104986" />
                                            <path id="Tracé_264" data-name="Tracé 264" d="M462.455,357.538c-15.207,0-24.97-12.01-24.97-26.18,0-14.257,9.763-26.267,24.97-26.267a24.306,24.306,0,0,1,18.405,7.862l5.7-5.615c-5.788-6.135-14.084-9.851-24.107-9.851-20.045,0-33.266,15.207-33.266,33.871s13.22,33.784,33.266,33.784a33.026,33.026,0,0,0,24.193-9.851l-5.788-5.7A23.936,23.936,0,0,1,462.455,357.538Z" transform="translate(-131.928 -295.845)" fill="#104986" />
                                            <path id="Tracé_265" data-name="Tracé 265" d="M504.8,323.254H476.025v-25.23h-8.208v65.667h8.208V330.858H504.8v32.833h8.208V298.024H504.8Z" transform="translate(-102.202 -295.431)" fill="#104986" />
                                            <path id="Tracé_266" data-name="Tracé 266" d="M532.873,297.487c-20.045,0-33.266,15.207-33.266,33.871s13.22,33.784,33.266,33.784c20.132,0,33.352-15.121,33.352-33.784S553,297.487,532.873,297.487Zm0,60.051c-15.208,0-25.057-12.01-25.057-26.18,0-14.257,9.849-26.267,25.057-26.267,15.294,0,25.143,12.01,25.143,26.267C558.016,345.528,548.167,357.538,532.873,357.538Z" transform="translate(-77.738 -295.845)" fill="#104986" />
                                            <path id="Tracé_267" data-name="Tracé 267" d="M574.626,297.487c-20.045,0-33.266,15.207-33.266,33.871s13.22,33.784,33.266,33.784c20.132,0,33.353-15.121,33.353-33.784S594.758,297.487,574.626,297.487Zm0,60.051c-15.208,0-25.057-12.01-25.057-26.18,0-14.257,9.849-26.267,25.057-26.267,15.294,0,25.143,12.01,25.143,26.267C599.769,345.528,589.92,357.538,574.626,357.538Z" transform="translate(-45.607 -295.845)" fill="#104986" />
                                            <path id="Tracé_268" data-name="Tracé 268" d="M593.666,352.416V298.024h-8.209v60.855A31.348,31.348,0,0,1,593.666,352.416Z" transform="translate(-11.672 -295.432)" fill="#104986" />
                                            <path id="Tracé_269" data-name="Tracé 269" d="M612.476,330.837H598.831a25.256,25.256,0,0,0-11.095,7.6h24.74Z" transform="translate(-9.918 -270.18)" fill="#104986" />
                                        </g>
                                        <g id="Groupe_78" data-name="Groupe 78" transform="translate(454.41 223.289)">
                                            <g id="Groupe_55" data-name="Groupe 55" transform="translate(89.352 19.819)">
                                                <path id="Tracé_270" data-name="Tracé 270" d="M422.9,244.291a24.561,24.561,0,0,1-2.021-9.8h5.309a19.3,19.3,0,0,0,1.585,7.7Z" transform="translate(-420.881 -234.489)" fill="#4b95af" />
                                            </g>
                                            <g id="Groupe_56" data-name="Groupe 56" transform="translate(99.399 32.305)">
                                                <path id="Tracé_271" data-name="Tracé 271" d="M441.214,253.762a24.5,24.5,0,0,1-14.655-4.817l3.152-4.272a19.394,19.394,0,0,0,26.343-3.129l4.059,3.421A24.66,24.66,0,0,1,441.214,253.762Z" transform="translate(-426.559 -241.545)" fill="#104986" />
                                            </g>
                                            <g id="Groupe_57" data-name="Groupe 57" transform="translate(102.719 8.756)">
                                                <path id="Tracé_272" data-name="Tracé 272" d="M440.39,251.975A12.153,12.153,0,0,1,433.9,250.1a11.5,11.5,0,0,1-5.452-10.232c.011-.3.035-.614.071-.929a12.27,12.27,0,0,1,10.55-10.7l.664,5.268a6.908,6.908,0,0,0-5.939,6.027c-.019.179-.034.356-.041.531a6.169,6.169,0,0,0,2.982,5.549,6.707,6.707,0,0,0,6.758.3,81.357,81.357,0,0,0,11.493-7.9,21.146,21.146,0,0,1,13.666-4.7l-.142,5.307a15.857,15.857,0,0,0-10.216,3.546,86.305,86.305,0,0,1-12.337,8.453A11.987,11.987,0,0,1,440.39,251.975Z" transform="translate(-428.435 -228.237)" fill="#104986" />
                                            </g>
                                            <g id="Groupe_58" data-name="Groupe 58" transform="translate(142.657 13.866)">
                                                <path id="Tracé_273" data-name="Tracé 273" d="M451,258.047v-5.309a8.152,8.152,0,0,0,0-16.3v-5.309a13.461,13.461,0,0,1,0,26.922Z" transform="translate(-451.005 -231.125)" fill="#104986" />
                                            </g>
                                            <g id="Groupe_59" data-name="Groupe 59" transform="translate(40.517 47.528)">
                                                <path id="Tracé_274" data-name="Tracé 274" d="M461.748,287.328c-.518,0-1.044-.014-1.58-.042-29.981-1.6-65.392-31.829-66.884-33.115l3.465-4.022c.35.3,35.536,30.335,63.7,31.836,14.006.77,20.576-11.077,20.849-11.582l.715-1.318,2.371-.108c3.095-.1,5.624-.964,5.756-1.97l.871-9.3,5.284.5-.881,9.391c-.513,3.948-4.235,6.324-10.251,6.657C482.8,277.857,475.283,287.328,461.748,287.328Z" transform="translate(-393.284 -250.148)" fill="#4b95af" />
                                            </g>
                                            <g id="Groupe_60" data-name="Groupe 60" transform="translate(51.853 42.772)">
                                                <path id="Tracé_275" data-name="Tracé 275" d="M454.672,266.426c-4.151-6.717-11.982-5.99-14.289-5.6a49.2,49.2,0,0,1-24.694-2.04l-16-6.393,1.971-4.928,15.91,6.362a43.9,43.9,0,0,0,21.939,1.764c3.127-.52,13.781-1.483,19.676,8.048Z" transform="translate(-399.69 -247.46)" fill="#104986" />
                                            </g>
                                            <g id="Groupe_61" data-name="Groupe 61" transform="translate(83.942 87.014)">
                                                <rect id="Rectangle_34" data-name="Rectangle 34" width="5.309" height="11.151" transform="translate(0 5.074) rotate(-72.861)" fill="#4b95af" />
                                            </g>
                                            <g id="Groupe_68" data-name="Groupe 68" transform="translate(0 38.08)">
                                                <g id="Groupe_64" data-name="Groupe 64" transform="translate(1.662 1.081)">
                                                    <g id="Groupe_62" data-name="Groupe 62" transform="translate(1.49)">
                                                        <path id="Tracé_276" data-name="Tracé 276" d="M409.658,256.475a27.847,27.847,0,0,0-3.206-2.7,27.456,27.456,0,0,0-32.413.173l-1.87-2.539a30.608,30.608,0,0,1,36.124-.193,31.047,31.047,0,0,1,3.571,3Z" transform="translate(-372.168 -245.42)" fill="#4b95af" />
                                                    </g>
                                                    <g id="Groupe_63" data-name="Groupe 63">
                                                        <path id="Tracé_277" data-name="Tracé 277" d="M394.153,262.613a30.86,30.86,0,0,1-21.5-8.8l-1.324-1.3,1.49-1.1a30.608,30.608,0,0,1,36.124-.193,31.047,31.047,0,0,1,3.571,3l1.324,1.3-1.492,1.1A30.633,30.633,0,0,1,394.153,262.613Zm-17.872-9.752c.575.487,1.17.952,1.78,1.391a27.455,27.455,0,0,0,30.817.92c-.573-.488-1.168-.954-1.778-1.393A27.455,27.455,0,0,0,376.281,252.861Z" transform="translate(-371.326 -245.42)" fill="#4b95af" />
                                                    </g>
                                                </g>
                                                <g id="Groupe_67" data-name="Groupe 67">
                                                    <g id="Groupe_65" data-name="Groupe 65" transform="translate(2.513)">
                                                        <path id="Tracé_278" data-name="Tracé 278" d="M409.182,257.716a27.031,27.031,0,0,0-3.083-2.592,26.389,26.389,0,0,0-31.144.166l-3.148-4.275a31.678,31.678,0,0,1,37.392-.2,32.05,32.05,0,0,1,3.695,3.107Z" transform="translate(-371.807 -244.809)" fill="#4b95af" />
                                                    </g>
                                                    <g id="Groupe_66" data-name="Groupe 66">
                                                        <path id="Tracé_279" data-name="Tracé 279" d="M394.876,264.163a31.719,31.719,0,0,1-18.563-6.006,32.177,32.177,0,0,1-3.7-3.107l-2.23-2.184,2.513-1.851a31.678,31.678,0,0,1,37.392-.2,32.053,32.053,0,0,1,3.695,3.107l2.231,2.184-2.513,1.851A31.707,31.707,0,0,1,394.876,264.163Zm-15.986-10.7c.173.129.349.258.524.386a26.373,26.373,0,0,0,28.3,1.663c-.172-.131-.347-.26-.522-.388A26.376,26.376,0,0,0,378.89,253.462Z" transform="translate(-370.387 -244.809)" fill="#4b95af" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Groupe_75" data-name="Groupe 75" transform="translate(31.272)">
                                                <g id="Groupe_71" data-name="Groupe 71" transform="translate(1.078 1.603)">
                                                    <g id="Groupe_69" data-name="Groupe 69" transform="translate(3.365 1.037)">
                                                        <path id="Tracé_280" data-name="Tracé 280" d="M403.049,263.1l-2.863-1.324a27.572,27.572,0,0,0-9.616-34.388l1.762-2.612a30.954,30.954,0,0,1,3.659,2.9A30.6,30.6,0,0,1,403.049,263.1Z" transform="translate(-390.57 -224.781)" fill="#4b95af" />
                                                    </g>
                                                    <g id="Groupe_70" data-name="Groupe 70">
                                                        <path id="Tracé_281" data-name="Tracé 281" d="M403.735,265.236,402.2,264.2a30.931,30.931,0,0,1-3.658-2.9,30.515,30.515,0,0,1-9.072-15.556,30.864,30.864,0,0,1,2.016-19.868l.777-1.683,1.534,1.037a30.956,30.956,0,0,1,3.659,2.9,30.6,30.6,0,0,1,7.057,35.426ZM393.6,228.983a27.738,27.738,0,0,0-1.062,16.048,27.376,27.376,0,0,0,8.142,13.955q.828.767,1.72,1.463a27.451,27.451,0,0,0-7.082-30C394.766,229.935,394.19,229.447,393.6,228.983Z" transform="translate(-388.668 -224.195)" fill="#4b95af" />
                                                    </g>
                                                </g>
                                                <g id="Groupe_74" data-name="Groupe 74">
                                                    <g id="Groupe_72" data-name="Groupe 72" transform="translate(3.839 1.747)">
                                                        <path id="Tracé_282" data-name="Tracé 282" d="M404.29,263.944l-4.82-2.226a26.383,26.383,0,0,0-6.084-30.542,26.756,26.756,0,0,0-3.157-2.5l2.971-4.4a31.8,31.8,0,0,1,11.09,39.668Z" transform="translate(-390.229 -224.276)" fill="#4b95af" />
                                                    </g>
                                                    <g id="Groupe_73" data-name="Groupe 73">
                                                        <path id="Tracé_283" data-name="Tracé 283" d="M404.65,267.537l-2.585-1.747a31.943,31.943,0,0,1-3.787-3,31.588,31.588,0,0,1-9.391-16.1,31.965,31.965,0,0,1,2.088-20.566l1.308-2.833,2.587,1.747A31.8,31.8,0,0,1,405.96,264.7Zm-10.072-36.031a26.666,26.666,0,0,0-.52,13.978,26.278,26.278,0,0,0,7.821,13.408q.236.22.483.435a26.374,26.374,0,0,0-7.306-27.391Q394.819,231.717,394.578,231.505Z" transform="translate(-388.059 -223.289)" fill="#4b95af" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Groupe_76" data-name="Groupe 76" transform="translate(179.109 22.365)">
                                                <rect id="Rectangle_35" data-name="Rectangle 35" width="8.833" height="5.308" transform="matrix(0.679, -0.734, 0.734, 0.679, 0, 6.481)" fill="#4b95af" />
                                            </g>
                                            <g id="Groupe_77" data-name="Groupe 77" transform="translate(146.641 33.434)">
                                                <path id="Tracé_284" data-name="Tracé 284" d="M462.261,264.417a13.277,13.277,0,0,1-9.005-3.5l3.58-3.921a8.071,8.071,0,0,0,11.445-.566l13.194-14.248,3.895,3.606L472.2,260.008a13.265,13.265,0,0,1-9.264,4.394Q462.6,264.417,462.261,264.417Z" transform="translate(-453.256 -242.183)" fill="#104986" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                    </div>
                    <div className="col mr-100">
                        <div className="img-c">
                            <img className="visual w-80 full-height" id="1" onClick={openVisual} src="../image/aquaschool/aquaschoolsmartphone.webp"></img>
                        </div>
                    </div>
                    <div className="col mr-200">
                        <div className="img-c">
                            <img className="visual w-80 full-height" id="2" onClick={openVisual} src="../image/aquaschool/aquaschoolsmartphone2.webp"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}