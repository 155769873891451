import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import Burger from "../component/Burger";
import { NavLink } from "react-router-dom";
import Draggable from "react-draggable";

export default function ConseilDev() {
    const ref = useRef(null);
    const [x, setX] = useState(null);
    const [scrollObj, setScrollObj] = useState(null);

    useEffect(() => {
        //console.log(x);
        if (ref && !scrollObj) {
            const scroll = new LocomotiveScroll({
                el: ref.current,
                smooth: true,
                direction: "horizontal",
                lerp: 0.05,
                reloadOnContextChange: true,
                tablet: {
                    breakpoint: 1224,
                    lerp: 0.1,
                    multiplier: 3
                },
            });
            scroll.on("scroll", (args) => {
                // Get all current elements : args.currentElements
                setX(args.scroll.x);
                //console.log(args);
            });

            setScrollObj(scroll);
        }
    }, []);

    const [classBig, setClassBig] = useState("");
    const visualizer = document.querySelector(".visualizer");
    const imgVisual = document.querySelector(".img-visual");
    const tabSrc = ["../image/consdev/consdevstart.webp", "../image/consdev/consdevsite.webp", "../image/consdev/consdevsite2.webp", "../image/consdev/consdevsite3.webp"];
    const [imgCounter, setImgCounter] = useState("");


    const openVisual = e => {
        if (window.innerWidth <= 800) {
            visualizer.style.display = "block";
            setImgCounter(parseInt(e.currentTarget.id) + 1);
            visualizer.setAttribute("id", e.currentTarget.id);
            imgVisual.src = tabSrc[e.currentTarget.id];
        }

    };

    const closeVisual = () => {
        visualizer.style.display = "none";
        setClassBig("");
    };

    const previous = () => {
        if (imgCounter > 1) {
            setImgCounter(imgCounter - 1);
            visualizer.setAttribute("id", imgCounter - 2);
            imgVisual.src = tabSrc[imgCounter - 2];
            setClassBig("");
        }

    };

    const next = () => {
        if (imgCounter < tabSrc.length) {
            setImgCounter(imgCounter + 1);
            visualizer.setAttribute("id", imgCounter);
            imgVisual.src = tabSrc[imgCounter];
            setClassBig("");
        }
    };

    return (
        <div className="projet">
            <div className="visualizer">
                <div className="count">
                    <span>{imgCounter}</span>
                    <span>/</span>
                    <span>{tabSrc.length}</span>
                </div>
                <button className="close" onClick={closeVisual}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.414" height="26.414" viewBox="0 0 26.414 26.414">
                        <g id="Groupe_2" data-name="Groupe 2" transform="translate(-470.293 -91.928)">
                            <line id="Ligne_7" data-name="Ligne 7" x2="25" y2="25" transform="translate(471 92.635)" fill="none" stroke="var(--white)" strokeWidth="2" />
                            <line id="Ligne_8" data-name="Ligne 8" x2="25" y2="25" transform="translate(496 92.635) rotate(90)" fill="none" stroke="var(--white)" strokeWidth="2" />
                        </g>
                    </svg>
                </button>
                <button className="right" onClick={next}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <button className="left" onClick={previous}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <div className="img-ctn">
                    <img className="img-visual" id={classBig} src="../image/poissonpapier.webp"></img>
                </div>
            </div>
            <CustomCursor />
            <Burger />
            <div className="fixed">
                <h1>Le Conseil de Développement</h1>
                <h2>
                    <span className="color">Chargés d'imaginer l'identité visuelle du CODEV</span> (lieu d'échange pour le mieux travailler et vivre en métropole de Rennes),
                </h2>
                <p>nous avons traduit la concertation avec ce "C" où se regroupent les multiples visages des conseillers.</p>
                <div className="nav">
                    <button className="back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                        Retour
                        <div className="back-choice">
                            <NavLink to="../realisations/fede-peche-35">Projet précédent</NavLink>
                            <NavLink to="../realisations">Galerie principale</NavLink>
                        </div>
                    </button>
                    <NavLink className="forward" to="../realisations/approbio">Projet suivant<svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                        <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#fff" />
                    </svg>
                    </NavLink>
                </div>
            </div>
            <div className="scroll-container" data-scroll-container ref={ref}>

                <div className="start-img">
                    <img className="visual" id="0" onClick={openVisual} data-scroll data-scroll-speed={-1} data-scroll-direction="horizontal" src="../image/consdev/consdevstart.webp"></img>
                </div>
                <div className="projet-gallery-ctn">
                    <div className="col elm-center mr-100">
                            <div className="logotype xxl" data-scroll data-scroll-speed={0.4} data-scroll-direction="horizontal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="555.553" height="170.301" viewBox="0 0 555.553 170.301">
                                    <g id="Groupe_80" data-name="Groupe 80" transform="translate(-272.532 -255.187)">
                                        <path id="Tracé_285" data-name="Tracé 285" d="M419.22,356.427a74.207,74.207,0,1,1-92.906-93.957" transform="translate(0 6.014)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                        <path id="Tracé_286" data-name="Tracé 286" d="M365.674,329.086l-.552,22.883h-3.517l.977-28.226H367.2l8.136,21.828,8.138-21.828H388.1l.975,28.226h-3.519L385,329.086l-7.2,19.239H372.88Z" transform="translate(83.342 64.661)" fill="#fff" />
                                        <path id="Tracé_287" data-name="Tracé 287" d="M386.9,349.817a26.229,26.229,0,0,0,6.529-.973l.464,2.838a22.436,22.436,0,0,1-7.288,1.188c-6.1,0-8.179-2.926-8.179-7.588v-6.189c0-4.28,1.949-7.711,8.095-7.711,6.1,0,7.756,3.517,7.756,7.8V343.5H381.985v1.74c0,3.265.891,4.576,4.916,4.576m-4.916-9.281h8.858v-1.695c0-2.881-.975-4.494-4.321-4.494s-4.537,1.613-4.537,4.494ZM383.85,327.1a46.366,46.366,0,0,0,5.807-3.858l1.527,2.372a43.663,43.663,0,0,1-5.891,3.687Z" transform="translate(99.443 64.184)" fill="#fff" />
                                        <path id="Tracé_288" data-name="Tracé 288" d="M401.285,350.656a12.82,12.82,0,0,1-4.323.765c-3.474,0-5.13-1.276-5.13-4.7V333.323H388.19v-2.967h3.642V325.27l3.56-.509v5.6h5.85l-.3,2.967h-5.554v12.8c0,1.527.213,2.292,2.122,2.292a13.137,13.137,0,0,0,3.3-.511Z" transform="translate(108.788 65.635)" fill="#fff" />
                                        <path id="Tracé_289" data-name="Tracé 289" d="M406.818,330.666a45.913,45.913,0,0,0-5.891,3.56v14.242h-3.56v-20.64h3.094l.213,2.879a26.407,26.407,0,0,1,5.678-3.3Z" transform="translate(117.572 68.164)" fill="#fff" />
                                        <path id="Tracé_290" data-name="Tracé 290" d="M411.886,348.891c-6.018,0-8.392-3.431-8.392-7.754v-5.977c0-4.323,2.374-7.756,8.392-7.756s8.39,3.433,8.39,7.756v5.977c0,4.323-2.372,7.754-8.39,7.754m0-18.393c-3.221,0-4.832,1.523-4.832,4.535v6.23c0,3.008,1.611,4.535,4.832,4.535s4.83-1.527,4.83-4.535v-6.23c0-3.012-1.609-4.535-4.83-4.535" transform="translate(123.436 68.164)" fill="#fff" />
                                        <path id="Tracé_291" data-name="Tracé 291" d="M431.138,342.154c0,4.108-1.865,6.738-6.7,6.738a33.394,33.394,0,0,1-5.891-.634v8.557l-3.56.509v-29.5H418l.3,1.863a11,11,0,0,1,6.823-2.288c4.069,0,6.02,2.165,6.02,6.318Zm-12.588,2.963a33.018,33.018,0,0,0,5.68.638c2.374,0,3.347-1.1,3.347-3.6v-8.433c0-2.12-.677-3.18-3.178-3.18A9.524,9.524,0,0,0,418.55,333Z" transform="translate(134.439 68.164)" fill="#fff" />
                                        <path id="Tracé_292" data-name="Tracé 292" d="M434.162,348.891c-6.018,0-8.392-3.431-8.392-7.754v-5.977c0-4.323,2.374-7.756,8.392-7.756s8.39,3.433,8.39,7.756v5.977c0,4.323-2.374,7.754-8.39,7.754m0-18.393c-3.221,0-4.832,1.523-4.832,4.535v6.23c0,3.008,1.611,4.535,4.832,4.535s4.83-1.527,4.83-4.535v-6.23c0-3.012-1.609-4.535-4.83-4.535" transform="translate(144.757 68.164)" fill="#fff" />
                                        <path id="Tracé_293" data-name="Tracé 293" d="M437.266,352.633V323.561l3.558-.511v29.582Z" transform="translate(155.76 63.998)" fill="#fff" />
                                        <path id="Tracé_294" data-name="Tracé 294" d="M450.482,345.839a26.235,26.235,0,0,0,6.527-.973l.466,2.836a22.456,22.456,0,0,1-7.29,1.19c-6.1,0-8.179-2.926-8.179-7.588v-6.189c0-4.28,1.949-7.713,8.093-7.713,6.1,0,7.756,3.519,7.756,7.8v4.321H445.566v1.738c0,3.265.888,4.578,4.916,4.578m-4.916-9.281h8.856v-1.695c0-2.881-.975-4.494-4.323-4.494s-4.533,1.613-4.533,4.494Z" transform="translate(160.298 68.164)" fill="#fff" />
                                        <path id="Tracé_295" data-name="Tracé 295" d="M457.223,338.309c0-4.114,1.865-6.74,6.7-6.74a33.237,33.237,0,0,1,5.891.634V323.56l3.558-.509v29.582h-3.008l-.3-1.863a11,11,0,0,1-6.823,2.288c-4.071,0-6.02-2.165-6.02-6.318Zm12.588-2.967a33.165,33.165,0,0,0-5.678-.636c-2.374,0-3.349,1.1-3.349,3.6v8.431c0,2.12.677,3.18,3.178,3.18a9.524,9.524,0,0,0,5.848-2.46Z" transform="translate(174.863 63.999)" fill="#fff" />
                                        <path id="Tracé_296" data-name="Tracé 296" d="M476.871,345.839a26.223,26.223,0,0,0,6.527-.973l.466,2.836a22.456,22.456,0,0,1-7.29,1.19c-6.1,0-8.179-2.926-8.179-7.588v-6.189c0-4.28,1.951-7.713,8.093-7.713,6.1,0,7.758,3.519,7.758,7.8v4.321H471.955v1.738c0,3.265.891,4.578,4.916,4.578m-4.916-9.281h8.856v-1.695c0-2.881-.973-4.494-4.323-4.494s-4.533,1.613-4.533,4.494Z" transform="translate(185.556 68.164)" fill="#fff" />
                                        <path id="Tracé_297" data-name="Tracé 297" d="M494.662,341.757h-6.907v10.214h-3.644V323.743h10.764c6.061,0,8.179,2.632,8.179,6.954v4.069c0,3.347-1.229,5.635-4.492,6.564l7.331,10.641H501.7Zm.086-14.749h-6.993v11.53h6.993c3.476,0,4.662-.977,4.662-3.73v-4.069c0-2.8-1.186-3.73-4.662-3.73" transform="translate(200.598 64.661)" fill="#fff" />
                                        <path id="Tracé_298" data-name="Tracé 298" d="M505.468,345.839a26.236,26.236,0,0,0,6.527-.973l.466,2.836a22.456,22.456,0,0,1-7.29,1.19c-6.1,0-8.179-2.926-8.179-7.588v-6.189c0-4.28,1.949-7.713,8.095-7.713,6.1,0,7.754,3.519,7.754,7.8v4.321H500.55v1.738c0,3.265.891,4.578,4.918,4.578m-4.918-9.281h8.858v-1.695c0-2.881-.975-4.494-4.321-4.494s-4.537,1.613-4.537,4.494Z" transform="translate(212.928 68.164)" fill="#fff" />
                                        <path id="Tracé_299" data-name="Tracé 299" d="M520.406,348.467V332.7c0-1.354-.677-1.9-1.992-1.9a19.174,19.174,0,0,0-6.909,2.077v15.6h-3.56v-20.64h2.883l.339,1.781a22.433,22.433,0,0,1,8.3-2.206c3.308,0,4.494,2.078,4.494,5.259v15.806Z" transform="translate(223.411 68.163)" fill="#fff" />
                                        <path id="Tracé_300" data-name="Tracé 300" d="M531.943,348.467V332.7c0-1.354-.679-1.9-1.992-1.9a19.177,19.177,0,0,0-6.907,2.077v15.6h-3.56v-20.64h2.881l.341,1.781a22.412,22.412,0,0,1,8.306-2.206c3.306,0,4.494,2.078,4.494,5.259v15.806Z" transform="translate(234.456 68.163)" fill="#fff" />
                                        <path id="Tracé_301" data-name="Tracé 301" d="M538.956,345.839a26.237,26.237,0,0,0,6.529-.973l.464,2.836a22.446,22.446,0,0,1-7.29,1.19c-6.1,0-8.179-2.926-8.179-7.588v-6.189c0-4.28,1.951-7.713,8.095-7.713,6.1,0,7.756,3.519,7.756,7.8v4.321H534.04v1.738c0,3.265.891,4.578,4.916,4.578m-4.916-9.281H542.9v-1.695c0-2.881-.975-4.494-4.323-4.494s-4.535,1.613-4.535,4.494Z" transform="translate(244.981 68.164)" fill="#fff" />
                                        <path id="Tracé_302" data-name="Tracé 302" d="M547.569,348.891a21.146,21.146,0,0,1-6.568-1.1l.466-2.8a23.448,23.448,0,0,0,5.893.847c3.261,0,3.813-.591,3.813-2.795s-.17-2.46-4.323-3.478c-5.128-1.27-5.594-2.331-5.594-6.609,0-3.816,1.7-5.552,7.12-5.552a23.173,23.173,0,0,1,5.846.72l-.252,2.924a33.04,33.04,0,0,0-5.721-.593c-3.053,0-3.476.72-3.476,2.585,0,2.288.041,2.713,3.517,3.56,5.932,1.485,6.4,2.2,6.4,6.312,0,4.028-1.227,5.979-7.12,5.979" transform="translate(255.051 68.164)" fill="#fff" />
                                        <path id="Tracé_303" data-name="Tracé 303" d="M361.369,269.533c0-6.926,4.032-10.958,13.457-10.958a42.75,42.75,0,0,1,10.277,1.251l-.795,6.189a55.607,55.607,0,0,0-9.253-.967c-4.942,0-6.531,1.7-6.531,5.736v14.536c0,4.034,1.589,5.734,6.531,5.734a55.807,55.807,0,0,0,9.253-.965l.795,6.19a42.845,42.845,0,0,1-10.277,1.251c-9.426,0-13.457-4.034-13.457-10.962Z" transform="translate(83.116 2.286)" fill="#fff" />
                                        <path id="Tracé_304" data-name="Tracé 304" d="M387.975,292.559c-9.482,0-12.038-5.227-12.038-10.9V274.67c0-5.678,2.556-10.9,12.038-10.9s12.038,5.224,12.038,10.9v6.985c0,5.676-2.556,10.9-12.038,10.9m0-22.885c-3.691,0-5.11,1.646-5.11,4.771v7.435c0,3.124,1.419,4.771,5.11,4.771s5.11-1.648,5.11-4.771v-7.435c0-3.126-1.419-4.771-5.11-4.771" transform="translate(97.06 7.257)" fill="#fff" />
                                        <path id="Tracé_305" data-name="Tracé 305" d="M408.019,291.988V272.683c0-1.476-.624-2.216-2.214-2.216-1.7,0-4.715,1.024-7.212,2.327v19.194h-6.926V264.334h5.28l.681,2.331a27.67,27.67,0,0,1,11.072-2.9c4.6,0,6.247,3.235,6.247,8.177v20.043Z" transform="translate(112.116 7.256)" fill="#fff" />
                                        <path id="Tracé_306" data-name="Tracé 306" d="M416.529,292.558a32.608,32.608,0,0,1-9.539-1.593l.967-5.28a30.965,30.965,0,0,0,8.234,1.192c3.065,0,3.519-.679,3.519-2.781,0-1.7-.339-2.554-4.826-3.634-6.758-1.646-7.551-3.349-7.551-8.688,0-5.562,2.441-8,10.332-8a36.322,36.322,0,0,1,8.292.965l-.681,5.508a48.959,48.959,0,0,0-7.611-.795c-3.008,0-3.519.683-3.519,2.384,0,2.216.17,2.386,3.918,3.351,7.721,2.045,8.459,3.065,8.459,8.746,0,5.335-1.646,8.631-9.993,8.631" transform="translate(126.782 7.256)" fill="#fff" />
                                        <path id="Tracé_307" data-name="Tracé 307" d="M432.137,286.876a29.009,29.009,0,0,0,8.463-1.3l1.078,5.224a29.7,29.7,0,0,1-10.163,1.761c-8.69,0-11.7-4.032-11.7-10.678v-7.324c0-5.85,2.611-10.788,11.469-10.788s10.845,5.169,10.845,11.13v5.9H426.745v1.419c0,3.351,1.192,4.654,5.392,4.654m-5.392-11.639h8.8v-1.362c0-2.611-.793-4.427-4.2-4.427s-4.6,1.816-4.6,4.427Z" transform="translate(139.059 7.256)" fill="#fff" />
                                        <path id="Tracé_308" data-name="Tracé 308" d="M434.415,257.966h6.926v8h-6.926Zm0,11.923h6.926v27.654h-6.926Z" transform="translate(153.032 1.703)" fill="#fff" />
                                        <path id="Tracé_309" data-name="Tracé 309" d="M442.018,297.709V258.758l6.926-.967v39.918Z" transform="translate(160.309 1.536)" fill="#fff" />
                                        <path id="Tracé_310" data-name="Tracé 310" d="M455.135,279.257c0-6.132,2.724-9.766,9.142-9.766a38.172,38.172,0,0,1,7.38.793V258.76l6.928-.969v39.92h-5.507l-.683-2.327a14.569,14.569,0,0,1-8.745,2.9c-5.564,0-8.516-3.3-8.516-9.6Zm16.522-3.124a30.6,30.6,0,0,0-6.134-.738c-2.5,0-3.462,1.192-3.462,3.691v9.766c0,2.27.851,3.521,3.407,3.521a9.8,9.8,0,0,0,6.188-2.5Z" transform="translate(172.864 1.535)" fill="#fff" />
                                        <path id="Tracé_311" data-name="Tracé 311" d="M482.865,286.876a29.009,29.009,0,0,0,8.463-1.3l1.078,5.224a29.7,29.7,0,0,1-10.163,1.761c-8.69,0-11.7-4.032-11.7-10.678v-7.324c0-5.85,2.613-10.788,11.469-10.788s10.845,5.169,10.845,11.13v5.9H477.473v1.419c0,3.351,1.192,4.654,5.392,4.654m-5.392-11.639h8.8v-1.362c0-2.611-.793-4.427-4.2-4.427s-4.6,1.816-4.6,4.427Z" transform="translate(187.614 7.256)" fill="#fff" />
                                        <text id="_" data-name=" " transform="translate(752.595 297.187)" fill="#fff" font-size="54" font-family="Helvetica"><tspan x="0" y="0"> </tspan></text>
                                        <path id="Tracé_312" data-name="Tracé 312" d="M361.414,304.1c0-6.132,2.726-9.766,9.142-9.766a38.214,38.214,0,0,1,7.382.793V283.6l6.926-.965v39.92h-5.507l-.681-2.329a14.535,14.535,0,0,1-8.745,2.895c-5.564,0-8.518-3.292-8.518-9.6Zm16.524-3.124a30.578,30.578,0,0,0-6.132-.738c-2.5,0-3.464,1.192-3.464,3.691V313.7c0,2.272.849,3.517,3.405,3.517a9.8,9.8,0,0,0,6.19-2.493Z" transform="translate(83.159 25.314)" fill="#fff" />
                                        <path id="Tracé_313" data-name="Tracé 313" d="M389.148,317.5a28.929,28.929,0,0,0,8.459-1.307l1.08,5.224a29.7,29.7,0,0,1-10.165,1.758c-8.686,0-11.7-4.028-11.7-10.67v-7.328c0-5.846,2.613-10.788,11.471-10.788s10.846,5.165,10.846,11.13v5.9H383.752v1.419c0,3.351,1.192,4.658,5.4,4.658m-5.68-29.359c2.67-1.646,5.394-3.462,8.289-5.564l2.726,4.2c-3.18,2.1-5.507,3.58-8.516,5.226Zm.284,17.716h8.8V304.5c0-2.611-.8-4.427-4.2-4.427s-4.6,1.816-4.6,4.427Z" transform="translate(97.909 25.259)" fill="#fff" />
                                        <path id="Tracé_314" data-name="Tracé 314" d="M407.537,316.555h-9.144L389.535,288.9h7.1l6.3,21.01L409.3,288.9h7.1Z" transform="translate(110.075 31.312)" fill="#fff" />
                                        <path id="Tracé_315" data-name="Tracé 315" d="M417.12,311.724a28.945,28.945,0,0,0,8.463-1.307l1.078,5.224A29.684,29.684,0,0,1,416.5,317.4c-8.688,0-11.7-4.028-11.7-10.67V299.4c0-5.846,2.613-10.788,11.471-10.788s10.845,5.165,10.845,11.13v5.9H411.728v1.419c0,3.351,1.192,4.658,5.392,4.658m-5.392-11.643h8.8v-1.362c0-2.611-.795-4.427-4.2-4.427s-4.6,1.816-4.6,4.427Z" transform="translate(124.686 31.035)" fill="#fff" />
                                        <path id="Tracé_316" data-name="Tracé 316" d="M419.4,322.553V283.6l6.928-.963v39.918Z" transform="translate(138.658 25.314)" fill="#fff" />
                                        <path id="Tracé_317" data-name="Tracé 317" d="M438.4,317.4c-9.482,0-12.036-5.22-12.036-10.9v-6.983c0-5.678,2.554-10.9,12.036-10.9s12.038,5.224,12.038,10.9V306.5c0,5.682-2.554,10.9-12.038,10.9m0-22.881c-3.689,0-5.108,1.646-5.108,4.77v7.439c0,3.122,1.419,4.766,5.108,4.766s5.11-1.644,5.11-4.766v-7.439c0-3.124-1.419-4.77-5.11-4.77" transform="translate(145.325 31.035)" fill="#fff" />
                                        <path id="Tracé_318" data-name="Tracé 318" d="M465.541,307.634c0,6.13-2.724,9.764-9.142,9.764a38.038,38.038,0,0,1-7.38-.795v11.412l-6.926.967v-39.8H447.6l.683,2.327a14.568,14.568,0,0,1,8.746-2.893c5.562,0,8.514,3.29,8.514,9.594Zm-16.522,3.122a30.74,30.74,0,0,0,6.134.736c2.5,0,3.462-1.19,3.462-3.689v-9.766c0-2.272-.851-3.519-3.405-3.519a9.809,9.809,0,0,0-6.19,2.5Z" transform="translate(160.38 31.035)" fill="#fff" />
                                        <path id="Tracé_319" data-name="Tracé 319" d="M481.011,307.634c0,6.13-2.724,9.764-9.142,9.764a38.037,38.037,0,0,1-7.38-.795v11.412l-6.928.967v-39.8h5.507l.681,2.327a14.577,14.577,0,0,1,8.747-2.893c5.564,0,8.516,3.29,8.516,9.594Zm-16.522,3.122a30.72,30.72,0,0,0,6.132.736c2.5,0,3.464-1.19,3.464-3.689v-9.766c0-2.272-.851-3.519-3.405-3.519a9.8,9.8,0,0,0-6.19,2.5Z" transform="translate(175.185 31.035)" fill="#fff" />
                                        <path id="Tracé_320" data-name="Tracé 320" d="M484.711,311.724a28.942,28.942,0,0,0,8.461-1.307l1.078,5.224a29.691,29.691,0,0,1-10.163,1.758c-8.688,0-11.7-4.028-11.7-10.67V299.4c0-5.846,2.613-10.788,11.471-10.788s10.845,5.165,10.845,11.13v5.9H479.317v1.419c0,3.351,1.192,4.658,5.394,4.658m-5.394-11.643h8.8v-1.362c0-2.611-.793-4.427-4.2-4.427s-4.6,1.816-4.6,4.427Z" transform="translate(189.379 31.035)" fill="#fff" />
                                        <path id="Tracé_321" data-name="Tracé 321" d="M503.339,316.832V297.527c0-1.48-.624-2.217-2.216-2.217-1.7,0-4.711,1.026-7.21,2.331v19.192h-6.926V289.178h5.278l.681,2.329a27.731,27.731,0,0,1,11.076-2.895c2.668,0,4.314,1.076,5.224,2.953a27.068,27.068,0,0,1,11.128-2.953c4.6,0,6.247,3.235,6.247,8.177v20.043h-6.93V297.527c0-1.48-.624-2.217-2.214-2.217a18.312,18.312,0,0,0-7.21,2.331v19.192Z" transform="translate(203.351 31.035)" fill="#fff" />
                                        <path id="Tracé_322" data-name="Tracé 322" d="M522.844,311.724a28.951,28.951,0,0,0,8.461-1.307l1.078,5.224A29.7,29.7,0,0,1,522.22,317.4c-8.69,0-11.7-4.028-11.7-10.67V299.4c0-5.846,2.613-10.788,11.469-10.788s10.844,5.165,10.844,11.13v5.9H517.45v1.419c0,3.351,1.192,4.658,5.394,4.658m-5.394-11.643h8.8v-1.362c0-2.611-.793-4.427-4.2-4.427s-4.6,1.816-4.6,4.427Z" transform="translate(225.878 31.035)" fill="#fff" />
                                        <path id="Tracé_323" data-name="Tracé 323" d="M541.473,316.832V297.527c0-1.48-.624-2.217-2.215-2.217-1.7,0-4.713,1.026-7.21,2.331v19.192h-6.926V289.178H530.4l.681,2.329a27.714,27.714,0,0,1,11.073-2.895c4.6,0,6.245,3.235,6.245,8.177v20.043Z" transform="translate(239.851 31.035)" fill="#fff" />
                                        <path id="Tracé_324" data-name="Tracé 324" d="M557.172,319.876a19.338,19.338,0,0,1-6.075,1.076c-5.055,0-7.609-2.384-7.609-7.324V298.24H539.34v-5.507h4.147v-6.87l6.926-.965v7.834h7.1l-.454,5.507h-6.643v14.481a2.182,2.182,0,0,0,2.5,2.443,13.254,13.254,0,0,0,3.464-.569Z" transform="translate(253.461 27.481)" fill="#fff" />
                                        <text id="_2" data-name=" " transform="translate(812.085 345.783)" fill="#fff" font-size="54" font-family="Helvetica"><tspan x="0" y="0"> </tspan></text>
                                        <path id="Tracé_325" data-name="Tracé 325" d="M319.54,300.919a17.548,17.548,0,1,1-17.548-17.548,17.547,17.547,0,0,1,17.548,17.548" transform="translate(9.487 26.019)" fill="#006993" />
                                        <path id="Tracé_326" data-name="Tracé 326" d="M366.382,312.1a22.14,22.14,0,1,1-22.141-22.141A22.141,22.141,0,0,1,366.382,312.1" transform="translate(45.531 32.329)" fill="#a8c142" />
                                        <path id="Tracé_327" data-name="Tracé 327" d="M314.279,322.357a8.495,8.495,0,1,1-8.494-8.494,8.5,8.5,0,0,1,8.494,8.494" transform="translate(21.782 55.204)" fill="#fff" />
                                        <path id="Tracé_328" data-name="Tracé 328" d="M325.2,302.441a8.5,8.5,0,1,1-8.494-8.494,8.494,8.494,0,0,1,8.494,8.494" transform="translate(32.237 36.142)" fill="#fff" />
                                        <path id="Tracé_329" data-name="Tracé 329" d="M369.223,283.8a26.9,26.9,0,1,1-26.9-26.9,26.9,26.9,0,0,1,26.9,26.9" transform="translate(39.137 0.677)" fill="#e02b1b" />
                                        <path id="Tracé_330" data-name="Tracé 330" d="M342.269,338.679a13.16,13.16,0,1,1-13.16-13.162,13.16,13.16,0,0,1,13.16,13.162" transform="translate(39.642 66.359)" fill="#006993" />
                                        <line id="Ligne_27" data-name="Ligne 27" x2="364.629" transform="translate(444.78 372.427)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                    </g>
                                </svg>
                            </div>
                            <div className="text">
                                <h3>Réalisation du site web</h3>
                                <p>Cela nous a amenés à créer une bibliothèque thématique d'illustrations couvrant l'ensemble des problématiques traitées.</p>
                            </div>
                    </div>
                    <div className="col elm-bottom mr-100">
                        <img className="w-40 visual" id="1" onClick={openVisual} src="../image/consdev/consdevsite.webp" data-scroll data-scroll-speed={0.1} data-scroll-direction="vertical"></img>
                    </div>
                    <div className="col elm-top mr-100">
                        <img className="w-80 visual" id="2" onClick={openVisual} src="../image/consdev/consdevsite2.webp" data-scroll data-scroll-speed={-0.1} data-scroll-direction="vertical"></img>
                    </div>
                    <div className="col elm-bottom mr-200">
                        <img className="w-40 visual" id="3" onClick={openVisual} src="../image/consdev/consdevsite3.webp" data-scroll data-scroll-speed={0.1} data-scroll-direction="vertical"></img>
                    </div>

                </div>

            </div>
        </div>
    );
}