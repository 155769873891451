import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import Burger from "../component/Burger";
import { NavLink } from "react-router-dom";
import Draggable from "react-draggable";

export default function Heolian() {
    const ref = useRef(null);
    const [x, setX] = useState(null);
    const [scrollObj, setScrollObj] = useState(null);

    useEffect(() => {
        //console.log(x);
        if (ref && !scrollObj) {
            const scroll = new LocomotiveScroll({
                el: ref.current,
                smooth: true,
                direction: "horizontal",
                lerp: 0.05,
                reloadOnContextChange: true,
                tablet: {
                    breakpoint: 1224,
                    lerp: 0.1,
                    multiplier: 3
                },
            });
            scroll.on("scroll", (args) => {
                // Get all current elements : args.currentElements
                setX(args.scroll.x);
                //console.log(args);
            });

            setScrollObj(scroll);
        }
    }, []);

    const [classBig, setClassBig] = useState("");
    const visualizer = document.querySelector(".visualizer");
    const imgVisual = document.querySelector(".img-visual");
    const tabSrc = ["../image/heolian/heolianstart.webp", "../image/heolian/heoliancatalogue.webp", "../image/heolian/heolianplaquette.webp", "../image/heolian/heoliansalon.webp", "../image/heolian/heolianweb.webp"];
    const [imgCounter, setImgCounter] = useState("");

    const handleClick = event => {
        switch (event.detail) {
            case 2: {
                if (classBig !== "") {
                    setClassBig("");
                } else {
                    setClassBig("big");
                }
                break;
            }
            default: {
                break;
            }
        }
    };

    const openVisual = e => {
        if (window.innerWidth <= 800) {
            visualizer.style.display = "block";
            setImgCounter(parseInt(e.currentTarget.id) + 1);
            visualizer.setAttribute("id", e.currentTarget.id);
            imgVisual.src = tabSrc[e.currentTarget.id];
        }

    };

    const closeVisual = () => {
        visualizer.style.display = "none";
        setClassBig("");
    };

    const previous = () => {
        if (imgCounter > 1) {
            setImgCounter(imgCounter - 1);
            visualizer.setAttribute("id", imgCounter - 2);
            imgVisual.src = tabSrc[imgCounter - 2];
            setClassBig("");
        }

    };

    const next = () => {
        if (imgCounter < tabSrc.length) {
            setImgCounter(imgCounter + 1);
            visualizer.setAttribute("id", imgCounter);
            imgVisual.src = tabSrc[imgCounter];
            setClassBig("");
        }
    };

    return (
        <div className="projet">
            <div className="visualizer">
                <div className="count">
                    <span>{imgCounter}</span>
                    <span>/</span>
                    <span>{tabSrc.length}</span>
                </div>
                <button className="close" onClick={closeVisual}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.414" height="26.414" viewBox="0 0 26.414 26.414">
                        <g id="Groupe_2" data-name="Groupe 2" transform="translate(-470.293 -91.928)">
                            <line id="Ligne_7" data-name="Ligne 7" x2="25" y2="25" transform="translate(471 92.635)" fill="none" stroke="var(--white)" strokeWidth="2" />
                            <line id="Ligne_8" data-name="Ligne 8" x2="25" y2="25" transform="translate(496 92.635) rotate(90)" fill="none" stroke="var(--white)" strokeWidth="2" />
                        </g>
                    </svg>
                </button>
                <button className="right" onClick={next}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <button className="left" onClick={previous}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <div className="img-ctn">
                    <img className="img-visual" id={classBig} src="../image/poissonpapier.webp"></img>
                </div>
            </div>
            <CustomCursor />
            <Burger />
            <div className="fixed">
                <h1>Heolian</h1>
                <h2>
                    <span className="color blue">Fabricant distributeur de pergolas, Heolian</span> a confié la construction de son identité visuelle, son site internet et l'ensemble de ses outils de communication à IMAGIC.
                </h2>
                <div className="nav">
                    <button className="back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                        Retour
                        <div className="back-choice">
                            <NavLink to="../realisations/bodet">Projet précédent</NavLink>
                            <NavLink to="../realisations">Galerie principale</NavLink>
                        </div>
                    </button>
                    <NavLink className="forward" to="../realisations/eaux-et-vilaine">Projet suivant<svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                        <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#fff" />
                    </svg>
                    </NavLink>
                </div>
            </div>
            <div className="scroll-container" data-scroll-container ref={ref}>

                <div className="start-img">
                    <img className="visual" id="0" onClick={openVisual} data-scroll data-scroll-speed={-1} data-scroll-direction="horizontal" src="../image/heolian/heolianstart.webp"></img>
                </div>
                <div className="projet-gallery-ctn">
                    <div className="col elm-center mr-100">
                        <div className="logotype xl" data-scroll data-scroll-speed={0.4} data-scroll-direction="horizontal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="390.29" height="85.186" viewBox="0 0 390.29 85.186">
                                <g id="Groupe_191" data-name="Groupe 191" transform="translate(-2077 -579.236)">
                                    <g id="Groupe_190" data-name="Groupe 190" transform="translate(2078 580.236)">
                                        <g id="Groupe_89" data-name="Groupe 89" transform="translate(0 0)">
                                            <rect id="Rectangle_37" data-name="Rectangle 37" width="388.29" height="63.911" rx="31.955" transform="translate(0)" fill="none" stroke="#fff" strokeWidth="2" />
                                            <g id="Groupe_86" data-name="Groupe 86" transform="translate(66.527 74.138)">
                                                <path id="Tracé_382" data-name="Tracé 382" d="M61.943,70.989h1.779v6.344c0,.568.638.557,1.2.557h5.628v.956h-5.9c-1.334,0-2.706-.193-2.706-1.4Z" transform="translate(-61.943 -68.885)" fill="#fff" />
                                                <path id="Tracé_383" data-name="Tracé 383" d="M76.73,70.989h1.875l4.873,7.857h-2.03l-1.16-2.072H74.932l-1.218,2.072h-2.07Zm3.016,4.851-2.088-3.435L75.492,75.84Z" transform="translate(-61.225 -68.885)" fill="#fff" />
                                                <path id="Tracé_384" data-name="Tracé 384" d="M90.219,70.989h2.456L96.059,77.2l3.307-6.215H101.8l1.142,7.857h-1.76l-.889-6.1-3.268,6.1H95.131l-3.365-6.1-.794,6.1H89.194Z" transform="translate(-59.926 -68.885)" fill="#fff" />
                                                <path id="Tracé_385" data-name="Tracé 385" d="M109.111,70.989h1.876l4.873,7.857h-2.031l-1.16-2.072h-5.357l-1.218,2.072h-2.069Zm3.017,4.851-2.089-3.435-2.165,3.435Z" transform="translate(-58.829 -68.885)" fill="#fff" />
                                                <path id="Tracé_386" data-name="Tracé 386" d="M117.517,69.03h1.548l1.972,1.406h-1.489l-1.315-.729-1.432.729h-1.509Zm-.232,2.1h1.78v7.857h-1.78Z" transform="translate(-57.995 -69.03)" fill="#fff" />
                                                <path id="Tracé_387" data-name="Tracé 387" d="M120.891,70.989h10v.955h-4.157v6.9h-1.779v-6.9h-4.062Z" transform="translate(-57.581 -68.885)" fill="#fff" />
                                                <path id="Tracé_388" data-name="Tracé 388" d="M132.034,70.989h6.071c1.934,0,3.423.58,3.423,2.382a2.283,2.283,0,0,1-2.4,2.277l2.9,3.2h-1.953l-2.8-3.146-3.462-.149v3.3h-1.779Zm5.994,3.725c.909,0,1.7-.043,1.7-1.343,0-1.265-.851-1.437-1.7-1.437h-4.215v2.78Z" transform="translate(-56.756 -68.885)" fill="#fff" />
                                                <path id="Tracé_389" data-name="Tracé 389" d="M143.428,70.989h1.779v7.857h-1.779Z" transform="translate(-55.913 -68.885)" fill="#fff" />
                                                <path id="Tracé_390" data-name="Tracé 390" d="M147.531,72.872c0-1.556,1.663-1.943,3.829-1.943,1.3-.011,4.157.183,5.008.268v.794c-1.237-.043-4.621-.106-4.815-.106-1.3-.032-2.244.043-2.244,1.019v.623c0,.88.7.967,1.838.967h2.61c2.63,0,3.114,1.041,3.114,1.911v.676c0,1.6-1.991,1.857-3.906,1.857-1.353,0-3.964-.149-5.008-.279v-.795c.773.043,3.056.119,4.853.119.812,0,2.282-.033,2.282-.891v-.643c0-.569-.309-.968-1.509-.968h-2.552c-3.191,0-3.5-1.062-3.5-1.91Z" transform="translate(-55.609 -68.89)" fill="#fff" />
                                                <path id="Tracé_391" data-name="Tracé 391" d="M158.655,72.449c0-1.137,1.3-1.492,2.611-1.46,0,0,5.8.15,6.149.172v.795H161.44c-1.006,0-1.006.31-1.006.6v1.888l6.169.107v.805l-6.169.107v1.888c.019.57.639.56,1.2.56h5.781V78.7c-.309.021-5.955.183-5.955.183-1.277.043-2.8-.139-2.8-1.417Z" transform="translate(-54.786 -68.885)" fill="#fff" />
                                                <path id="Tracé_392" data-name="Tracé 392" d="M174.1,70.989h5.453c4.275,0,5.009,2.254,5.009,3.961,0,1.921-1.354,3.9-5.009,3.9H174.1Zm5.395,6.892c2.34,0,3.288-1.31,3.288-2.942,0-2.243-1.237-3.005-3.288-3.005h-3.616v5.947Z" transform="translate(-53.643 -68.885)" fill="#fff" />
                                                <path id="Tracé_393" data-name="Tracé 393" d="M185.925,72.449c0-1.137,1.3-1.492,2.61-1.46,0,0,5.8.15,6.15.172v.795h-5.976c-1.005,0-1.005.31-1.005.6v1.888l6.169.107v.805l-6.169.107v1.888c.019.57.638.56,1.2.56h5.781V78.7c-.309.021-5.955.183-5.955.183-1.277.043-2.8-.139-2.8-1.417Z" transform="translate(-52.768 -68.885)" fill="#fff" />
                                                <path id="Tracé_394" data-name="Tracé 394" d="M196.256,72.872c0-1.556,1.664-1.943,3.83-1.943,1.3-.011,4.157.183,5.008.268v.794c-1.238-.043-4.621-.106-4.816-.106-1.3-.032-2.243.043-2.243,1.019v.623c0,.88.7.967,1.837.967h2.611c2.63,0,3.114,1.041,3.114,1.911v.676c0,1.6-1.992,1.857-3.906,1.857-1.353,0-3.964-.149-5.008-.279v-.795c.773.043,3.054.119,4.853.119.812,0,2.281-.033,2.281-.891v-.643c0-.569-.309-.968-1.508-.968h-2.553c-3.191,0-3.5-1.062-3.5-1.91Z" transform="translate(-52.004 -68.89)" fill="#fff" />
                                                <path id="Tracé_395" data-name="Tracé 395" d="M212.24,72.593c0-1.137,1.3-1.492,2.611-1.46,0,0,5.8.15,6.149.172V72.1h-5.975c-1.006,0-1.006.31-1.006.6v1.888l6.169.107V75.5l-6.169.107V77.5c.019.57.639.56,1.2.56H221v.783c-.309.021-5.955.183-5.955.183-1.277.043-2.8-.139-2.8-1.417Zm6.034-3.553h1.991l-2.514,1.438H216.3Z" transform="translate(-50.821 -69.029)" fill="#fff" />
                                                <path id="Tracé_396" data-name="Tracé 396" d="M222.824,70.989H224.6v6.344c0,.568.639.557,1.2.557h5.627v.956h-5.9c-1.335,0-2.708-.193-2.708-1.4Z" transform="translate(-50.038 -68.885)" fill="#fff" />
                                                <path id="Tracé_397" data-name="Tracé 397" d="M233.1,72.593c0-1.137,1.3-1.492,2.611-1.46,0,0,5.8.15,6.149.172V72.1h-5.975c-1.006,0-1.006.31-1.006.6v1.888l6.169.107V75.5l-6.169.107V77.5c.019.57.638.56,1.2.56h5.782v.783c-.309.021-5.955.183-5.955.183-1.277.043-2.8-.139-2.8-1.417Zm6.034-3.553h1.991l-2.514,1.438h-1.45Z" transform="translate(-49.277 -69.029)" fill="#fff" />
                                                <path id="Tracé_398" data-name="Tracé 398" d="M244.278,70.989h2.456l3.384,6.215,3.307-6.215h2.437L257,78.846h-1.76l-.889-6.1-3.268,6.1H249.19l-3.365-6.1-.793,6.1h-1.78Z" transform="translate(-48.526 -68.885)" fill="#fff" />
                                                <path id="Tracé_399" data-name="Tracé 399" d="M258.3,72.449c0-1.137,1.3-1.492,2.61-1.46,0,0,5.8.15,6.15.172v.795h-5.976c-1.005,0-1.005.31-1.005.6v1.888l6.169.107v.805l-6.169.107v1.888c.019.57.638.56,1.2.56h5.781V78.7c-.309.021-5.956.183-5.956.183-1.276.043-2.8-.139-2.8-1.417Z" transform="translate(-47.412 -68.885)" fill="#fff" />
                                                <path id="Tracé_400" data-name="Tracé 400" d="M268.885,70.989H270.7l7.021,6.022V70.989H279.5v7.857h-1.779l-7.04-6.011v6.011h-1.8Z" transform="translate(-46.629 -68.885)" fill="#fff" />
                                                <path id="Tracé_401" data-name="Tracé 401" d="M280.855,70.989h10v.955h-4.158v6.9h-1.779v-6.9h-4.061Z" transform="translate(-45.743 -68.885)" fill="#fff" />
                                                <path id="Tracé_402" data-name="Tracé 402" d="M291.511,72.872c0-1.556,1.662-1.943,3.829-1.943,1.3-.011,4.157.183,5.008.268v.794c-1.237-.043-4.621-.106-4.815-.106-1.3-.032-2.244.043-2.244,1.019v.623c0,.88.7.967,1.838.967h2.61c2.63,0,3.114,1.041,3.114,1.911v.676c0,1.6-1.991,1.857-3.906,1.857-1.353,0-3.964-.149-5.008-.279v-.795c.773.043,3.055.119,4.853.119.812,0,2.282-.033,2.282-.891v-.643c0-.569-.309-.968-1.509-.968h-2.552c-3.191,0-3.5-1.062-3.5-1.91Z" transform="translate(-44.955 -68.89)" fill="#fff" />
                                            </g>
                                            <path id="Tracé_403" data-name="Tracé 403" d="M325.242,38.648c0,4.29,4.3,4.3,4.3,4.3v-4.3Z" transform="translate(24.068 2.86)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" />
                                            <g id="Groupe_87" data-name="Groupe 87" transform="translate(278.254 37.61)">
                                                <path id="Tracé_404" data-name="Tracé 404" d="M268.848,44.253h-7.959a1.806,1.806,0,0,1-1.478-2.844l3.979-5.669a1.874,1.874,0,0,1,2.956,0l3.98,5.669a1.806,1.806,0,0,1-1.478,2.844Z" transform="translate(-259.082 -35.019)" fill="#e23734" />
                                            </g>
                                            <path id="Tracé_405" data-name="Tracé 405" d="M325.242,38.648c0,4.29,4.3,4.3,4.3,4.3v-4.3Z" transform="translate(24.068 2.86)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" />
                                            <g id="Groupe_88" data-name="Groupe 88" transform="translate(37.622 17.2)">
                                                <path id="Tracé_406" data-name="Tracé 406" d="M223.818,39.56H193.207V16.913a.752.752,0,0,0-.753-.752h-4.3a.752.752,0,0,0-.752.752V42.46a2.9,2.9,0,0,0,2.9,2.9h29.216a4.874,4.874,0,0,0,5.049-5.047.752.752,0,0,0-.753-.753Z" transform="translate(-23.754 -16.004)" fill="#fff" />
                                                <path id="Tracé_407" data-name="Tracé 407" d="M124.317,16.144H85.882a.752.752,0,0,0-.752.752v4.3a.752.752,0,0,0,.752.752h34.139a4.873,4.873,0,0,0,5.048-5.047.754.754,0,0,0-.752-.753Z" transform="translate(-31.323 -16.005)" fill="#fff" />
                                                <path id="Tracé_408" data-name="Tracé 408" d="M235.539,16.161h-4.3a.752.752,0,0,0-.752.752v23.4a4.873,4.873,0,0,0,5.047,5.048h0a.752.752,0,0,0,.752-.752v-27.7A.751.751,0,0,0,235.539,16.161Z" transform="translate(-20.566 -16.004)" fill="#fff" />
                                                <path id="Tracé_409" data-name="Tracé 409" d="M124.319,27.056H90.194c-.008,0-.013,0-.02,0l-4.3-.04a.693.693,0,0,0-.534.216.749.749,0,0,0-.224.529l-.02,2.172c0,.005,0,.01,0,.014l0,.013A14.634,14.634,0,0,0,99.716,44.574h24.6a.753.753,0,0,0,.752-.752v-4.3a.62.62,0,0,0-.013-.064.787.787,0,0,0-.045-.224.754.754,0,0,0-.149-.223c-.005-.006-.008-.014-.013-.019h0a.746.746,0,0,0-.243-.162.736.736,0,0,0-.289-.059h-24.6A8.825,8.825,0,0,1,91.4,32.857h32.92a.753.753,0,0,0,.752-.753v-4.3A.753.753,0,0,0,124.319,27.056Z" transform="translate(-31.325 -15.201)" fill="#fff" />
                                                <path id="Tracé_410" data-name="Tracé 410" d="M165.767,16.144H146.433a14.485,14.485,0,0,0-10.552,4.528.752.752,0,0,0,.543,1.272h29.343a8.817,8.817,0,1,1,0,17.634H146.433a8.827,8.827,0,0,1-8.816-8.818,8.736,8.736,0,0,1,.242-1.972.751.751,0,0,0-.732-.92h-4.374a.753.753,0,0,0-.743.636,14.518,14.518,0,0,0,14.425,16.875h19.334a14.617,14.617,0,1,0,0-29.234Z" transform="translate(-27.868 -16.005)" fill="#fff" />
                                                <path id="Tracé_411" data-name="Tracé 411" d="M76.4,16.161H72.1a.751.751,0,0,0-.752.752V27.859H40.831V16.913a.752.752,0,0,0-.753-.752h-4.3a.752.752,0,0,0-.752.752V44.607a.754.754,0,0,0,.752.752h0a4.873,4.873,0,0,0,5.048-5.048V33.66h30.52V44.607a.751.751,0,0,0,.752.752h4.3a.752.752,0,0,0,.752-.752V16.913A.752.752,0,0,0,76.4,16.161Z" transform="translate(-35.03 -16.004)" fill="#fff" />
                                                <rect id="Rectangle_38" data-name="Rectangle 38" width="5.8" height="17.507" rx="0.7" transform="translate(276.761 11.849)" fill="#fff" />
                                                <path id="Tracé_412" data-name="Tracé 412" d="M318.066,16.015h0l-22.458.139a2.907,2.907,0,0,0-2.882,2.9v2.134a.752.752,0,0,0,.752.753l24.606-.126a8.828,8.828,0,0,1,8.816,8.818v9.691a.751.751,0,0,0,.752.752h4.3a.751.751,0,0,0,.752-.752V30.632A14.641,14.641,0,0,0,318.066,16.015Z" transform="translate(-15.961 -16.015)" fill="#fff" />
                                                <path id="Tracé_413" data-name="Tracé 413" d="M269.161,18.741l-.858-1.223a2.977,2.977,0,0,0-4.747,0L244.846,44.163a.751.751,0,0,0,.615,1.184h5.249a.751.751,0,0,0,.615-.32l10.5-14.951a.7.7,0,0,0,.057-.093L269.162,19.6A.751.751,0,0,0,269.161,18.741Z" transform="translate(-19.514 -15.991)" fill="#fff" />
                                                <path id="Tracé_414" data-name="Tracé 414" d="M271.561,24.022a.779.779,0,0,0-1.23,0l-2.624,3.74a.751.751,0,0,0,0,.865L278.839,44.48a.751.751,0,0,0,.615.32H284.7a.752.752,0,0,0,.615-1.184Z" transform="translate(-17.822 -15.445)" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="text">
                            <h3>Réalisation du logo</h3>
                            <p>Inspirés par le métal constituant les structures de pergolas, nous avons dessiné un logotype pouvant être extrudé sur les joues de gouttière en aluminium, les protections de ses valises d'échantillons,… etc</p>
                        </div>
                    </div>
                    <div className="col elm-top mr-100">
                        <div className="img-c full-height">
                            <img className="w-80 full-img move visual" id="1" onClick={openVisual} src="../image/heolian/heoliancatalogue.webp" data-scroll data-scroll-speed={-0.4} data-scroll-direction="horizontal"></img>
                        </div>
                    </div>
                    <div className="col elm-top mr-100">
                        <div className="img-c">
                            <img className="w-40 visual" id="2" onClick={openVisual} src="../image/heolian/heolianplaquette.webp"></img>
                        </div>
                    </div>
                    <div className="col elm-bottom mr-100">
                        <div className="img-c">
                            <img className="w-80 visual" id="3" onClick={openVisual} src="../image/heolian/heoliansalon.webp"></img>
                        </div>
                    </div>
                    <div className="col elm-top mr-100">
                        <div className="img-c">
                            <video className="w-60" autoPlay muted loop>
                                <source src="../image/heolian/heolianvideo.mp4" type="video/mp4"></source>
                            </video>
                        </div>
                        <div className="text">
                            <h3>Réalisation du site internet</h3>
                            <p>Dans l'ADN de l'identité visuelle et de la société, Imagic a réalisé un site vitrine Wordpress afin de connecter Heolian et ses clients.</p>
                        </div>
                    </div>
                    <div className="col elm-top mr-100">
                        <div className="img-c full-height">
                            <img className="w-80 full-img move visual" id="4" onClick={openVisual} src="../image/heolian/heolianweb.webp" data-scroll data-scroll-speed={-0.4} data-scroll-direction="horizontal"></img>
                        </div>
                    </div>
                    <div className="col elm-center mr-100">
                        <div className="img-c" data-scroll data-scroll-speed={0.5} data-scroll-direction="horizontal">
                            <img className="w-40 visual" src="../image/heolian/heolian3-3x.webp"></img>
                        </div>
                    </div>
                    <div className="col elm-center b-40 mr-400">
                        <div className="img-c" data-scroll data-scroll-speed={1.5} data-scroll-direction="horizontal">
                            <img className="w-40 visual" src="../image/heolian/heolian2-3x.webp"></img>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}