import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import Burger from "../component/Burger";
import { Link } from "react-router-dom";

export default function Politique() {

  return (
    <Fragment>
      <CustomCursor />
      <Burger />
        <div className="politique">
          <h1>Politique de confidentialité</h1>

          <h2>Utilisation des données collectées</h2>
          <p>Les données saisies dans le formulaire de contact par l’utilisateur du site sont transmises par mail à notre agence de Pacé (35). En remplissant le formulaire, l’utilisateur autorise l’utilisation des données saisies afin de recevoir une réponse à sa demande. Les données collectées sont : nom, adresse mail, message, consentement. Ces données ne sont en aucun cas transmises à des tiers et sont utilisées à la seule fin de cette correspondance. Aucune autre donnée n’est collectée à l’insu de l’utilisateur.</p>

          <h2>Durées de stockage de vos données</h2>
          <p>Les adresses e-mail et messages reçus par le formulaire de contact ne sont pas effacés par nos bases de données et ceux dans à la seule fin de cette corespondance.</p>

          <h2>Les droits que vous avez sur vos données</h2>
          <p>Si vous avez déposé des données personnelles sur notre site, conformément à la RGPD (Règlement Général sur la Protection des Données), vous avez les droits suivants sur ces données :</p>
          <ul>
            <li>consultation</li>
            <li>modification</li>
            <li>suppression</li>
            <li>exportation</li>
          </ul>
          <p>Afin de faire valoir vos droits sur vos données veuillez nous contacter via <Link to="../contact">notre formulaire de contact</Link></p>

          <h2>Procédure mise en oeuvre en cas de fuite de données</h2>
          <p>En cas de violation de données personnelles, IMAGIC procèdera à une déclaration à la CNIL dans les 72 heures. Vous serez informé en cas de menace grave de vos droits et libertés.</p>
        </div>
    </Fragment>
  );
}
