import { NavLink } from "react-router-dom";
import "./savoir.css";
import Socials from "./Socials";

export default function NosSavoirFaire() {
  return (
    <div
      className="savoir page"
      data-scroll
      data-scroll-speed={0}
      data-scroll-direction="horizontal"
    >
      <div className="text-container">
        <h1>
          Nos<br></br>savoir-faire
        </h1>
        <p>
        De l'étude de positionnement, la création de votre identité visuelle, jusqu'à votre succès, il y a tout un cheminement mobilisant nos multiples savoirs faire (stratégie d'identité, rédaction, création graphique, illustration, photographie, vidéo et développement web).
        </p>
        <Socials></Socials>
      </div>
      <div className="gallery">
        <NavLink to="../realisations/identite-visuelle" data-scroll data-scroll-speed={0.2} data-scroll-direction="vertical">
          <div className="img-container"><img src="./image/savoir/identitevisuelle.webp" alt="identité-visuelle" data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img></div>
          <h2>Identité visuelle</h2>
        </NavLink>
        <NavLink to="../realisations/edition" data-scroll data-scroll-speed={-0.2} data-scroll-direction="vertical">
          <div className="img-container"><img src="./image/savoir/edition.webp" alt="édition" data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img></div>
          <h2>Édition</h2>
        </NavLink>
        <NavLink to="../realisations/web" data-scroll data-scroll-speed={0.2} data-scroll-direction="vertical">
          <div className="img-container"><img src="./image/savoir/web.webp" alt="site web" data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img></div>
          <h2>Web</h2>
        </NavLink>
        <NavLink to="../realisations/video" data-scroll data-scroll-speed={-0.2} data-scroll-direction="vertical">
          <div className="img-container"><img src="./image/savoir/video.webp" alt="vidéo" data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img></div>
          <h2>Vidéo</h2>
        </NavLink>
        <NavLink to="../realisations/signaletique" data-scroll data-scroll-speed={0.2} data-scroll-direction="vertical">
          <div className="img-container"><img src="./image/savoir/signaletique.webp" alt="panneau" data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img></div>
          <h2>Signalétique</h2>
        </NavLink>
        <NavLink to="../realisations/packaging" data-scroll data-scroll-speed={-0.2} data-scroll-direction="vertical">
          <div className="img-container"><img src="./image/savoir/packaging.webp" alt="packaging" data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img></div>
          <h2>Packaging</h2>
        </NavLink>
        <NavLink to="../realisations/illustration" data-scroll data-scroll-speed={0.2} data-scroll-direction="vertical">
          <div className="img-container"><img src="./image/savoir/illustration.webp" alt="illustration aquarelle" data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img></div>
          <h2>Illustration</h2>
        </NavLink>
        <NavLink to="../realisations/photographie" data-scroll data-scroll-speed={-0.2} data-scroll-direction="vertical">
          <div className="img-container"><img src="./image/savoir/photographie.webp" alt="photographie gastronomie" data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img></div>
          <h2>Photographie</h2>
        </NavLink>
      </div>
    </div>
  );
}
