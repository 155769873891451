import { useState, useEffect } from "react";
import Draggable from "react-draggable";
import Socials from './Socials';
import "./lab.css"


export default function Lab({ scroll = null, scrollTo = function (newX) { } }) {
  
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  const [activeDrag, setActiveDrag] = useState(false);

  useEffect(() => {
    if (windowDimenion.winWidth <= 800) {
      setActiveDrag(true);
      let drags = document.querySelectorAll(".react-draggable-dragged");
      for (let drag of drags) {
        drag.style = "transform : translate(0);"
      }
    } else {
      setActiveDrag(false);
    }

    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  return (
    <div className="lab page">
      <div className="text-container">
        <h1>
          Laboratoire<br></br>graphique
        </h1>
        <p>
          Vos imagiciens aiment expérimenter, innover. Il arrive que nous allions au delà de la vision du client et que le projet reste dans les cartons. Enfin… pas tout à fait. Nous gardons précieusement certaines de ces idées pour vous les présenter ici. Peut-être y trouverez-vous un intérêt applicable à l'un de vos projets ?
        </p>
        <Socials></Socials>
      </div>
      <div className="gallery">
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/escargot.webp" alt="fond d'écran escargot"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/rhino.webp" alt="dessin rhinceros"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/illustrationcouleur.webp" alt="illustration colorée"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/cabane.webp" alt="cabane en 3d"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/chienbd.webp" alt="illustration type bd"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/velo.webp" alt="photo cycliste"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/maitrechateau.webp" alt="affiche maître château"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/ambulance.webp" alt="ambulance en 3d"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/camion.webp" alt="camion en 3d"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/illustrationcouleur2.webp" alt="illustration cyan"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/planche.webp" alt="expérimentation dessin au crayon"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/salon.webp" alt="salon moderne en 3d"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/hodor.webp" alt="dessin hodor"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/plaquette.webp" alt="plaquette l'été des plages"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/bonobo.webp" alt="dessin bonobo niveau de gris"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/illustration.webp" alt="illustration au traît noir"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/espacil.webp" alt="guide espacil"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/martins.webp" alt="martin-pêcheur différents types de dessins"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/cookie.webp" alt="vidéo cookie"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/charlotte.webp" alt="planche de bd charlotte"></img></div></Draggable></div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/persoprofil.webp" alt="profil au traît noir"></img></div></Draggable></div>
        <div className="gallery-item">
          <Draggable disabled={activeDrag}>
            <div className="imgContainer">
              <video autoPlay muted loop>
                <source src="../image/labo/canard-envol.mp4" type="video/mp4"></source>
              </video>
            </div>
          </Draggable>
        </div>
        <div className="gallery-item"><Draggable disabled={activeDrag}><div className="imgContainer"><img src="../image/labo/vache.webp" alt="vache croquis"></img></div></Draggable></div>
      </div>
      <button
        onClick={function () {
          scrollTo(windowDimenion.winWidth);
        }}
        className="scrollBtn"
      >
        Scroll
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.312"
          height="12"
          viewBox="0 0 12.312 12"
        >
          <path
            id="Icon_awesome-arrow-right"
            data-name="Icon awesome-arrow-right"
            d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z"
            transform="translate(0 -2.647)"
            fill="#fff"
          />
        </svg>
      </button>
    </div>
  );
}