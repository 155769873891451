import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import Burger from "../component/Burger";
import { NavLink } from "react-router-dom";
import Draggable from "react-draggable";

export default function Ponant() {
    const ref = useRef(null);
    const [x, setX] = useState(null);
    const [scrollObj, setScrollObj] = useState(null);

    useEffect(() => {
        //console.log(x);
        if (ref && !scrollObj) {
            const scroll = new LocomotiveScroll({
                el: ref.current,
                smooth: true,
                direction: "horizontal",
                lerp: 0.05,
                reloadOnContextChange: true,
                tablet: {
                    breakpoint: 1224,
                    lerp: 0.1,
                    multiplier: 3
                },
            });
            scroll.on("scroll", (args) => {
                // Get all current elements : args.currentElements
                setX(args.scroll.x);
                //console.log(args);
            });

            setScrollObj(scroll);
        }
    }, []);

    const [classBig, setClassBig] = useState("");
    const visualizer = document.querySelector(".visualizer");
    const imgVisual = document.querySelector(".img-visual");
    const tabSrc = ["../image/leponant/ponantstart.webp", "../image/leponant/ponantmag.webp", "../image/leponant/ponantlogo.webp"];
    const [imgCounter, setImgCounter] = useState("");

    const openVisual = e => {
        if (window.innerWidth <= 800) {
            visualizer.style.display = "block";
            setImgCounter(parseInt(e.currentTarget.id) + 1);
            visualizer.setAttribute("id", e.currentTarget.id);
            imgVisual.src = tabSrc[e.currentTarget.id];
        }

    };

    const closeVisual = () => {
        visualizer.style.display = "none";
        setClassBig("");
    };

    const previous = () => {
        if (imgCounter > 1) {
            setImgCounter(imgCounter - 1);
            visualizer.setAttribute("id", imgCounter - 2);
            imgVisual.src = tabSrc[imgCounter - 2];
            setClassBig("");
        }

    };

    const next = () => {
        if (imgCounter < tabSrc.length) {
            setImgCounter(imgCounter + 1);
            visualizer.setAttribute("id", imgCounter);
            imgVisual.src = tabSrc[imgCounter];
            setClassBig("");
        }
    };

    return (
        <div className="projet">
            <div className="visualizer">
                <div className="count">
                    <span>{imgCounter}</span>
                    <span>/</span>
                    <span>{tabSrc.length}</span>
                </div>
                <button className="close" onClick={closeVisual}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.414" height="26.414" viewBox="0 0 26.414 26.414">
                        <g id="Groupe_2" data-name="Groupe 2" transform="translate(-470.293 -91.928)">
                            <line id="Ligne_7" data-name="Ligne 7" x2="25" y2="25" transform="translate(471 92.635)" fill="none" stroke="var(--white)" strokeWidth="2" />
                            <line id="Ligne_8" data-name="Ligne 8" x2="25" y2="25" transform="translate(496 92.635) rotate(90)" fill="none" stroke="var(--white)" strokeWidth="2" />
                        </g>
                    </svg>
                </button>
                <button className="right" onClick={next}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <button className="left" onClick={previous}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                </button>
                <div className="img-ctn">
                    <img className="img-visual" id={classBig} src="../image/poissonpapier.webp"></img>
                </div>
            </div>
            <CustomCursor />
            <Burger />
            <div className="fixed">
                <h1>Le Ponant</h1>
                <h2>
                    <span className="color">La salle de spectacle Le Ponant</span> gérée par Citedia a fait peau neuve. À commencer par son identité visuelle que nous avons lifté avec une typographie animée autour du O, symbolisant le halo du projecteur de poursuite.
                </h2>
                <p>L'étape suivante a été la création du catalogue de la saison culturelle annuelle avec un visuel en décalage avec les codes habituels de ce type de support, mais riche d'un symbole évocateur avec cet oiseau (en)chanteur.</p>
                <div className="nav">
                    <button className="back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(12.312 14.647) rotate(180)" fill="#fff" />
                        </svg>
                        Retour
                        <div className="back-choice">
                            <NavLink to="../realisations/les-cafes-breizhiliens">Projet précédent</NavLink>
                            <NavLink to="../realisations">Galerie principale</NavLink>
                        </div>
                    </button>
                    <NavLink className="forward" to="../realisations/sevenadur">Projet suivant<svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12">
                        <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#fff" />
                    </svg>
                    </NavLink>
                </div>
            </div>
            <div className="scroll-container" data-scroll-container ref={ref}>

                <div className="start-img">
                    <img className="visual" id="0" onClick={openVisual} data-scroll data-scroll-speed={-1} data-scroll-direction="horizontal" src="../image/leponant/ponantstart.webp"></img>
                </div>
                <div className="projet-gallery-ctn">
                    <div className="col elm-center">
                        <div className="text bg-black">
                            <h3>Lifting du logo<br></br>Réalisation du catalogue</h3>
                            <p>Le Ponant est un espace culturel polyvalent qui accueille des spectacles, des congrès et séminaires ainsi que de nombreux événements.</p>
                        </div>
                    </div>
                    <div className="col elm-top b-20">
                        <div className="img-c" data-scroll data-scroll-speed={-0.1} data-scroll-direction="vertical">
                            <img className="w-100 visual" id="1" onClick={openVisual} src="../image/leponant/ponantmag.webp"></img>
                        </div>
                    </div>
                    <div className="col elm-bottom b-20 mr-100">
                        <div className="img-c" data-scroll data-scroll-speed={0.1} data-scroll-direction="vertical">
                            <img className="w-80 visual" id="1" onClick={openVisual} src="../image/leponant/ponantmagzoom.webp"></img>
                        </div>
                    </div>
                    <div className="col-full">
                        <img className="full-img visual" id="2" onClick={openVisual} src="../image/leponant/ponantlogo.webp"></img>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}