import "./socials.css";

export default function Socials() {
    return (
        <ul className="socials">
            <li>
                <a href="https://www.facebook.com/agence.imagic/" title='facebook'>
                    <svg id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
                        <g id="Calque_1-2" data-name="Calque 1">
                            <path className="cls-1" d="M17,0C7.61,0,0,7.61,0,17s7.61,17,17,17,17-7.61,17-17S26.39,0,17,0Zm4.83,10.98h-1.42c-.12-.02-.24-.02-.36,0-.9,.08-1.56,.87-1.48,1.77v2.12h3.13l-.5,3.27h-2.63v7.89h-3.53v-7.89h-2.87v-3.27h2.87v-2.49c0-2.83,1.69-4.4,4.27-4.4,.85,.01,1.69,.09,2.53,.22v2.78Z" />
                        </g>
                    </svg>
                </a>
            </li>
            <li>
                <a href="https://vimeo.com/imagic35" title='vimeo'>
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="cls-1" d="M17 0C7.6 0 0 7.6 0 17C0 26.4 7.6 34 17 34C26.4 34 34 26.4 34 17C34 7.6 26.4 0 17 0ZM22.9 20.3C20.2 23.8 17.9 25.6 16.1 25.6C14.9 25.6 14 24.5 13.2 22.4C11.6 16.7 11 13.3 9.7 13.3C9.1 13.5 8.6 13.8 8.1 14.2L7.1 13C9.4 11 11.6 8.8 12.9 8.6C14.4 8.4 15.4 9.5 15.7 11.8C17 19.8 17.5 21 19.8 17.4C20.5 16.5 20.9 15.5 21.1 14.4C21.3 12.4 19.5 12.5 18.3 13C19.3 9.8 21.1 8.3 23.9 8.4C25.9 8.5 26.9 9.8 26.8 12.4C26.8 14.3 25.5 17 22.9 20.3Z" fill="white" />
                    </svg>
                </a>
            </li>
            <li>
                <a href="https://fr.linkedin.com/company/imagic-communication" title='linkedin'>
                    <svg id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
                        <g id="Calque_1-2" data-name="Calque 1">
                            <path className="cls-1" d="M17,0C7.61,0,0,7.61,0,17s7.61,17,17,17,17-7.61,17-17S26.39,0,17,0Zm-6.77,23.25V13.9h2.9v9.35s-2.9,0-2.9,0Zm1.45-10.62c-.93,0-1.69-.75-1.7-1.68s.75-1.69,1.68-1.7c.93,0,1.69,.75,1.7,1.68,0,.93-.75,1.69-1.68,1.7Zm12.32,10.62h-2.9v-4.55c0-1.08-.02-2.47-1.51-2.47s-1.74,1.18-1.74,2.4v4.62h-2.9V13.9h2.79v1.28h.04c.57-.97,1.62-1.55,2.75-1.51,2.94,0,3.48,1.93,3.48,4.45v5.13Z" />
                        </g>
                    </svg>
                </a>
            </li>
        </ul>
    );
}
