import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import Filtres from "../component/Filtres";
import realimg from "../data/realisation.json";
import { NavLink } from "react-router-dom";
import Burger from "../component/Burger";

export default function RealisationIdentiteVisuelle() {
  const ref = useRef(null);
  const [x, setX] = useState(null);
  const [scrollObj, setScrollObj] = useState(null);
  

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  let projects = document.querySelectorAll(".gallery a");
  for (let project of projects){
    if (project.offsetLeft < windowDimenion.winWidth){
      project.classList.remove("hidden")
    }
  }

  useEffect(() => {
    //console.log(x);
    if (ref && !scrollObj) {
      const scroll = new LocomotiveScroll({
        el: ref.current,
        smooth: true,
        direction: "horizontal",
        lerp: 0.05,
        reloadOnContextChange: true,
        scrollFromAnywhere: true,
        tablet: {
          breakpoint: 1224,
          lerp: 0.1,
          multiplier: 3
        },
      });
      scroll.on("scroll", (args) => {
        // Get all current elements : args.currentElements
        setX(args.scroll.x);
        let projects = document.querySelectorAll(".gallery a");
        for (let project of projects){
          if (project.offsetLeft < args.scroll.x + windowDimenion.winWidth){
            project.classList.remove("hidden")
          }
        }
      });

      setScrollObj(scroll);
    }
  }, []);

  const textPlus = (e) => {
    if (windowDimenion.winWidth <= 1224){
      let texte = document.querySelector(".real .text-container p");
      texte.style.display = "block";
      e.target.classList.add("more");
    }
  }

  const galleryItems = realimg.map(item =>
      <NavLink to={item.linkproject} className={item.filtre + " hidden"}>
        <p>{item.title}</p>
        <img src={item.signaletique} alt={item.title} data-scroll data-scroll-speed={-0.2} data-scroll-direction="horizontal"></img>
      </NavLink>  
  );

  return (
    <Fragment>
      <div className="real-anim anim-signaletique">
        <div className="title-container"><h3>Signalétique</h3></div>
        <div className="title-container"><h4>Porter haut vos couleurs</h4></div>
      </div>
      <CustomCursor />
      <Burger/>
      <div className="scroll-container" data-scroll-container ref={ref}>
        <div className="real page">
          <div className="text-container">
            <h1>
              Nos<br></br>réalisations
            </h1>
            <Filtres />
            <h2 onClick={textPlus}>Porter haut vos couleurs</h2>
            <p>Depuis 2005, nous accompagnons institutions, PME et grands groupes dans leur stratégie d'identité (logotype, charte graphique, packaging, signalétique) et leur communication opérationnelle print ou digitale (publicité, prospection, information, community management,…). Voici quelques exemples de nos créations pour vous mettre l'eau à la bouche et vous inspirer à parler de vous, vos projets, vos ambitions autour d'un café.</p>
          </div>
          <div className="gallery" id="signaletique">
              { galleryItems }
          </div>
        </div>
      </div>
    </Fragment>
  );
}
