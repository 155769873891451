import "./filtres.css";
import { NavLink } from "react-router-dom";

export default function Filtres() {
  return (
    <ul className="filtres">
      <li>
        <NavLink exact="true" to="../realisations">Toutes</NavLink>
      </li>
      <li>
        <NavLink exact="true" to="../realisations/identite-visuelle">Identité visuelle</NavLink>
      </li>
      <li>
        <NavLink exact="true" to="../realisations/edition">Edition</NavLink>
      </li>
      <li>
        <NavLink exact="true" to="../realisations/web">Web</NavLink>
      </li>
      <li>
        <NavLink exact="true" to="../realisations/video">Vidéo</NavLink>
      </li>
      <li>
        <NavLink exact="true" to="../realisations/signaletique">Signalétique</NavLink>
      </li>
      <li>
        <NavLink exact="true" to="../realisations/packaging">Packaging</NavLink>
      </li>
      <li>
        <NavLink exact="true" to="../realisations/illustration">Illustration</NavLink>
      </li>
      <li>
        <NavLink exact="true" to="../realisations/photographie">Photographie</NavLink>
      </li>
    </ul>
  );
}
