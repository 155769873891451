import React, { Fragment, useRef, useEffect, useState } from "react";
import Lab from "../component/Lab";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import Burger from "../component/Burger";

export default function MainPage() {
  const ref = useRef(null);
  const [x, setX] = useState(null);
  const [scrollObj, setScrollObj] = useState(null);

  useEffect(() => {
    //console.log(x);
    if (ref && !scrollObj) {
      const scroll = new LocomotiveScroll({
        el: ref.current,
        smooth: true,
        direction: "horizontal",
        lerp: 0.05,
        reloadOnContextChange: true,
        scrollFromAnywhere: true,
        tablet: {
          breakpoint: 1224,
          lerp: 0.1,
          multiplier: 3
        },
      });
      scroll.on("scroll", (args) => {
        // Get all current elements : args.currentElements
        setX(args.scroll.x);
        //console.log(args);
      });

      setScrollObj(scroll);
    }
  }, []);

  return (
    <Fragment>
      <CustomCursor />
      <Burger class={"menu-labo"}/>
      <div className="scroll-container" data-scroll-container ref={ref}>
        <Lab
          scroll={x}
          scrollTo={function (newX) {
            console.log("test", x);
            scrollObj.scrollTo(x + newX);
          }}
        />
      </div>
    </Fragment>
  );
}
