import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./burger.css";
import Socials from "./Socials";

export default function Burger(props) {
  const [idMenu, setIdMenu] = useState("imagic");
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      console.log(window.pageYOffset);
      setScrollY(window.pageYOffset);
    };
    
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const survolMenu = (e) => {
    setIdMenu(e.currentTarget.id);
  }

  const openMenu = () => {
    document.querySelector(".pop").classList.remove("close");
    document.querySelector(".pop").classList.add("open");
  }

  const closeMenu = () => {
    document.querySelector(".pop").classList.remove("open");
    document.querySelector(".pop").classList.add("close");
  }

  const toTopClass = scrollY > 10 ? "" : "totopelm";

  return (
    <nav className={`${props.class} ${toTopClass}`}>
      <NavLink className="logo" to={"/"}>
        <svg id="logo-imagic" xmlns="http://www.w3.org/2000/svg" width="135" height="31.469" viewBox="0 0 135 31.469">
          <path id="Tracé_1" data-name="Tracé 1" d="M204.278,0h-3.732a.518.518,0,0,0-.518.518V4.251a.518.518,0,0,0,.518.518h3.732a.518.518,0,0,0,.518-.518V.518A.518.518,0,0,0,204.278,0Z" transform="translate(-96.351)" fill="#c9d22c" />
          <path id="Tracé_2" data-name="Tracé 2" d="M204.3,43.526h-1.679a.419.419,0,0,0-.419.42v1.679a.419.419,0,0,0,.419.42H204.3a.42.42,0,0,0,.42-.42V43.946A.421.421,0,0,0,204.3,43.526Z" transform="translate(-97.397 -20.966)" fill="#fff" />
          <path id="Tracé_3" data-name="Tracé 3" d="M204.275,31.519h-2.332a.467.467,0,0,0-.466.466v2.332a.467.467,0,0,0,.466.466h2.332a.466.466,0,0,0,.466-.466V31.985A.466.466,0,0,0,204.275,31.519Z" transform="translate(-97.049 -15.182)" fill="#fff" />
          <path id="Tracé_4" data-name="Tracé 4" d="M204.254,17.059h-3.11a.518.518,0,0,0-.518.518v3.11a.518.518,0,0,0,.518.518h3.11a.518.518,0,0,0,.518-.518v-3.11A.518.518,0,0,0,204.254,17.059Z" transform="translate(-96.64 -8.217)" fill="#fff" />
          <g id="Groupe_1" data-name="Groupe 1" transform="translate(0 8.839)">
            <path id="Tracé_5" data-name="Tracé 5" d="M165.572,17.053H153.7c-2.908,0-3.222,5.678-3.222,8.119s.314,8.119,3.222,8.119h11.793a14.968,14.968,0,0,1-.664,2.764H152.647a.518.518,0,0,0-.518.518v2.592a.518.518,0,0,0,.518.518h12.926c3.644,0,3.928-8.694,3.928-11.359C169.5,25.68,169.216,17.053,165.572,17.053Zm.3,11.272c0,.443-.009.891-.027,1.339H154.625a17.74,17.74,0,0,1-.524-4.491,17.74,17.74,0,0,1,.524-4.491h10.211A24.093,24.093,0,0,1,165.871,28.325Z" transform="translate(-72.482 -17.053)" fill="#fff" />
            <path id="Tracé_6" data-name="Tracé 6" d="M3.11,17.057H.518A.518.518,0,0,0,0,17.575v15.2a.518.518,0,0,0,.518.518H3.11a.518.518,0,0,0,.518-.518v-15.2A.518.518,0,0,0,3.11,17.057Z" transform="translate(0 -17.055)" fill="#fff" />
            <path id="Tracé_7" data-name="Tracé 7" d="M241.951,32.6,241,30.007a.518.518,0,0,0-.487-.34H226.251a17.726,17.726,0,0,1-.525-4.492,17.713,17.713,0,0,1,.525-4.491h10.6a.519.519,0,0,0,.487-.7l-.949-2.592a.518.518,0,0,0-.487-.34H225.321c-2.91,0-3.223,5.678-3.223,8.119s.314,8.119,3.223,8.119h16.143a.519.519,0,0,0,.487-.7Z" transform="translate(-106.983 -17.055)" fill="#fff" />
            <path id="Tracé_8" data-name="Tracé 8" d="M115.292,20.323a4.912,4.912,0,0,0-4.979-3.267h-9.2a.518.518,0,0,0-.518.518v2.592a.518.518,0,0,0,.518.518h9.2c1.148,0,1.372.362,1.58.908l.616,1.69H101.557c-1.153,0-2.528.869-2.528,5.006s1.375,5.006,2.528,5.006l15.878.008a1.814,1.814,0,0,0,1.7-2.436Zm-1.459,6.587,1.006,2.759-12.085,0a9.795,9.795,0,0,1,0-2.756Z" transform="translate(-47.701 -17.054)" fill="#fff" />
            <path id="Tracé_9" data-name="Tracé 9" d="M51.975,20.323A4.914,4.914,0,0,0,47,17.056H23.809a.518.518,0,0,0-.518.518v15.2a.518.518,0,0,0,.518.518H26.4a.518.518,0,0,0,.518-.518V20.685H36.55V32.771a.518.518,0,0,0,.518.518H39.66a.518.518,0,0,0,.518-.518V20.685H47c1.145,0,1.379.379,1.58.908L52.724,32.95a.519.519,0,0,0,.487.341h2.758a.519.519,0,0,0,.487-.7Z" transform="translate(-11.219 -17.054)" fill="#fff" />
          </g>
        </svg>
      </NavLink>
      <button onClick={openMenu} className="burgerIcon">
        <span className="topel"></span>
        <span className="bottomel"></span>
      </button>
      <div className="pop close">
        <button onClick={closeMenu} className="closeIcon">
          <svg xmlns="http://www.w3.org/2000/svg" width="26.414" height="26.414" viewBox="0 0 26.414 26.414">
            <g id="Groupe_2" data-name="Groupe 2" transform="translate(-470.293 -91.928)">
              <line id="Ligne_7" data-name="Ligne 7" x2="25" y2="25" transform="translate(471 92.635)" fill="none" stroke="var(--grey)" strokeWidth="2" />
              <line id="Ligne_8" data-name="Ligne 8" x2="25" y2="25" transform="translate(496 92.635) rotate(90)" fill="none" stroke="var(--grey)" strokeWidth="2" />
            </g>
          </svg>
        </button>
        <div className="mentions">
          <Link to="../politique-de-confidentialite" target="_blank" rel="noopener noreferrer">Politique de Confidentialité</Link>
          <Link to="../mentions-legales" target="_blank" rel="noopener noreferrer">Mentions légales</Link>
        </div>
        <NavLink onMouseOver={survolMenu} className="logo" id="imagic" to={"/"}>
          <svg id="logo-imagic" xmlns="http://www.w3.org/2000/svg" width="135" height="31.469" viewBox="0 0 135 31.469">
            <path id="Tracé_1" data-name="Tracé 1" d="M204.278,0h-3.732a.518.518,0,0,0-.518.518V4.251a.518.518,0,0,0,.518.518h3.732a.518.518,0,0,0,.518-.518V.518A.518.518,0,0,0,204.278,0Z" transform="translate(-96.351)" fill="#c9d22c" />
            <path id="Tracé_2" data-name="Tracé 2" d="M204.3,43.526h-1.679a.419.419,0,0,0-.419.42v1.679a.419.419,0,0,0,.419.42H204.3a.42.42,0,0,0,.42-.42V43.946A.421.421,0,0,0,204.3,43.526Z" transform="translate(-97.397 -20.966)" fill="#fff" />
            <path id="Tracé_3" data-name="Tracé 3" d="M204.275,31.519h-2.332a.467.467,0,0,0-.466.466v2.332a.467.467,0,0,0,.466.466h2.332a.466.466,0,0,0,.466-.466V31.985A.466.466,0,0,0,204.275,31.519Z" transform="translate(-97.049 -15.182)" fill="#fff" />
            <path id="Tracé_4" data-name="Tracé 4" d="M204.254,17.059h-3.11a.518.518,0,0,0-.518.518v3.11a.518.518,0,0,0,.518.518h3.11a.518.518,0,0,0,.518-.518v-3.11A.518.518,0,0,0,204.254,17.059Z" transform="translate(-96.64 -8.217)" fill="#fff" />
            <g id="Groupe_1" data-name="Groupe 1" transform="translate(0 8.839)">
              <path id="Tracé_5" data-name="Tracé 5" d="M165.572,17.053H153.7c-2.908,0-3.222,5.678-3.222,8.119s.314,8.119,3.222,8.119h11.793a14.968,14.968,0,0,1-.664,2.764H152.647a.518.518,0,0,0-.518.518v2.592a.518.518,0,0,0,.518.518h12.926c3.644,0,3.928-8.694,3.928-11.359C169.5,25.68,169.216,17.053,165.572,17.053Zm.3,11.272c0,.443-.009.891-.027,1.339H154.625a17.74,17.74,0,0,1-.524-4.491,17.74,17.74,0,0,1,.524-4.491h10.211A24.093,24.093,0,0,1,165.871,28.325Z" transform="translate(-72.482 -17.053)" fill="#fff" />
              <path id="Tracé_6" data-name="Tracé 6" d="M3.11,17.057H.518A.518.518,0,0,0,0,17.575v15.2a.518.518,0,0,0,.518.518H3.11a.518.518,0,0,0,.518-.518v-15.2A.518.518,0,0,0,3.11,17.057Z" transform="translate(0 -17.055)" fill="#fff" />
              <path id="Tracé_7" data-name="Tracé 7" d="M241.951,32.6,241,30.007a.518.518,0,0,0-.487-.34H226.251a17.726,17.726,0,0,1-.525-4.492,17.713,17.713,0,0,1,.525-4.491h10.6a.519.519,0,0,0,.487-.7l-.949-2.592a.518.518,0,0,0-.487-.34H225.321c-2.91,0-3.223,5.678-3.223,8.119s.314,8.119,3.223,8.119h16.143a.519.519,0,0,0,.487-.7Z" transform="translate(-106.983 -17.055)" fill="#fff" />
              <path id="Tracé_8" data-name="Tracé 8" d="M115.292,20.323a4.912,4.912,0,0,0-4.979-3.267h-9.2a.518.518,0,0,0-.518.518v2.592a.518.518,0,0,0,.518.518h9.2c1.148,0,1.372.362,1.58.908l.616,1.69H101.557c-1.153,0-2.528.869-2.528,5.006s1.375,5.006,2.528,5.006l15.878.008a1.814,1.814,0,0,0,1.7-2.436Zm-1.459,6.587,1.006,2.759-12.085,0a9.795,9.795,0,0,1,0-2.756Z" transform="translate(-47.701 -17.054)" fill="#fff" />
              <path id="Tracé_9" data-name="Tracé 9" d="M51.975,20.323A4.914,4.914,0,0,0,47,17.056H23.809a.518.518,0,0,0-.518.518v15.2a.518.518,0,0,0,.518.518H26.4a.518.518,0,0,0,.518-.518V20.685H36.55V32.771a.518.518,0,0,0,.518.518H39.66a.518.518,0,0,0,.518-.518V20.685H47c1.145,0,1.379.379,1.58.908L52.724,32.95a.519.519,0,0,0,.487.341h2.758a.519.519,0,0,0,.487-.7Z" transform="translate(-11.219 -17.054)" fill="#fff" />
            </g>
          </svg>
        </NavLink>
        <div className="menuIllustration" id={idMenu}>
          <img className="imagic-img" src="../image/poissonpapier.webp" alt="Imagic, illustration papier"></img>
          <img className="agence-img" src="../image/imagiciens2023.webp" alt="Imagiciens, agence de communication"></img>
          <img className="realisations-img" src="../image/nav/realisations.webp" alt="Réalisations graphiques"></img>
          <img className="laboratoire-img" src="../image/nav/laboratoire.webp" alt="Expérimentations de réalisations"></img>
          <img className="contact-img" src="../image/nav/contact.webp" alt="Contacter l'agence"></img>
        </div>
        <div className="menuNav">
          <ul className="menuList">
            <li className="main-item">
              <a onMouseOver={survolMenu} id="agence" href="/agence/">L'agence</a>
            </li>
            <li className="main-item">
              <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations">Réalisations</NavLink>
            </li>
            <li className="under-item">
              <ul className="real-items">
                <li className="real-item">
                  <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations/identite-visuelle">Identité visuelle</NavLink>
                </li>
                <li className="real-item">
                  <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations/edition">Edition</NavLink>
                </li>
                <li className="real-item">
                  <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations/web">Web</NavLink>
                </li>
                <li className="real-item">
                  <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations/video">Vidéo</NavLink>
                </li>
                <li className="real-item">
                  <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations/signaletique">Signalétique</NavLink>
                </li>
                <li className="real-item">
                  <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations/packaging">Packaging</NavLink>
                </li>
                <li className="real-item">
                  <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations/illustration">Illustration</NavLink>
                </li>
                <li className="real-item">
                  <NavLink onMouseOver={survolMenu} id="realisations" to="../realisations/photographie">Photographie</NavLink>
                </li>
              </ul>
            </li>
            <li className="main-item">
              <NavLink onMouseOver={survolMenu} id="laboratoire" to="../laboratoire">Laboratoire</NavLink>
            </li>
            <li className="main-item">
              <NavLink onMouseOver={survolMenu} id="contact" to="../contact">Contact</NavLink>
            </li>
          </ul>
          <Socials />
        </div>
      </div>
    </nav>
  );
}
