import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import "./App.css";
import MainPage from "./view/MainPage";
import Laboratoire from "./view/Laboratoire";
import Realisation from "./view/Realisation";
import RealisationIdentiteVisuelle from "./view/RealisationIdentiteVisuelle";
import RealisationEdition from "./view/RealisationEdition";
import RealisationWeb from "./view/RealisationWeb";
import RealisationVideo from "./view/RealisationVideo";
import RealisationSignaletique from "./view/RealisationSignaletique";
import RealisationPackaging from "./view/RealisationPackaging";
import RealisationIllustration from "./view/RealisationIllustration";
import RealisationPhotographie from "./view/RealisationPhotographie";
import Contact from "./view/Contact";
import Agence from "./view/Agence";
import Ponant from "./view/Ponant";
import Sciellour from "./view/Sciellour";
import MilGoul from "./view/MilGoul";
import Sevenadur from "./view/Sevenadur";
import Heolian from "./view/Heolian";
import Politique from "./view/Politique";
import Mentions from "./view/Mentions";
import FedePeche from "./view/FedePeche";
import Approbio from "./view/Approbio";
import Barococo from "./view/Barococo";
import ConsLittoral from "./view/ConsLittoral";
import ConseilDev from "./view/ConseilDev";
import Aquaschool from "./view/Aquaschool";
import Cesta from "./view/Cesta";
import BretagneGM from "./view/BretagneGM";
import Oxybiotop from "./view/Oxybiotop";
import InfuseMe from "./view/InfuseMe";
import ActuaG from "./view/ActuaG";
import Edago from "./view/Edago";
import LesRuchers from "./view/LesRuchers";
import Dol from "./view/Dol";
import LesMiels from "./view/LesMiels";
import Liffre from "./view/Liffre";
import Cafes from "./view/Cafes";
import Bayman from "./view/Bayman";
import Smictom from "./view/Smictom";
import Pommeraie from "./view/Pommeraie";
import PetitBreton from "./view/PetitBreton";
import Tetc from "./view/Tetc";
import Bodet from "./view/Bodet";
import Eaux from "./view/Eaux";

var lastWindowWidth = window.innerWidth;

function App() {

  /*Sans ça, lorsqu'on va réduire la fenêtre du contenu va disparaître
  car la largeur du conteneur de la zone scrollable horizontalement
  va se réduire; pour se faire quand l'utilisateur modifie la largeur
  de la fenêtre on recharge la page pour actualiser la largeur de la zone scrollable*/
  window.addEventListener("resize", (event) => {
    var windowWidth = window.innerWidth;
    if ((lastWindowWidth <= 1224 && windowWidth >= 1224) || (lastWindowWidth >= 1224 && windowWidth <= 1224)){
      window.location.reload();
    }
    
  });

  return (
    <div className="App">
      <HashRouter>
        {/* Ici se trouve tous les urls des pages que l'on souhaite créer :
        https://nomdedomaine.ext/#/nomdelapage 
        ce qui se trouve avant le # est géré côté serveur et ce qui se trouve après est géré côté client*/}
        <Routes>
          <Route element={<MainPage />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/realisations" element={<Realisation />} />
          <Route path="/realisations/identite-visuelle" element={<RealisationIdentiteVisuelle />} />
          <Route path="/realisations/edition" element={<RealisationEdition />} />
          <Route path="/realisations/web" element={<RealisationWeb />} />
          <Route path="/realisations/video" element={<RealisationVideo />} />
          <Route path="/realisations/signaletique" element={<RealisationSignaletique />} />
          <Route path="/realisations/packaging" element={<RealisationPackaging />} />
          <Route path="/realisations/illustration" element={<RealisationIllustration />} />
          <Route path="/realisations/photographie" element={<RealisationPhotographie />} />
          <Route path="/realisations/le-ponant" element={<Ponant />} />
          <Route path="/realisations/le-sciellour" element={<Sciellour />} />
          <Route path="/realisations/mil-goul" element={<MilGoul />} />
          <Route path="/realisations/sevenadur" element={<Sevenadur />} />
          <Route path="/realisations/heolian" element={<Heolian />} />
          <Route path="/realisations/fede-peche-35" element={<FedePeche />} />
          <Route path="/realisations/approbio" element={<Approbio />} />
          <Route path="/realisations/barococo" element={<Barococo />} />
          <Route path="/realisations/conservatoire-littoral" element={<ConsLittoral />} />
          <Route path="/realisations/conseil-developpement" element={<ConseilDev />} />
          <Route path="/realisations/aquaschool" element={<Aquaschool />} />
          <Route path="/realisations/cesta" element={<Cesta />} />
          <Route path="/realisations/bretagne-grands-migrateurs" element={<BretagneGM />} />
          <Route path="/realisations/oxybiotop" element={<Oxybiotop />} />
          <Route path="/realisations/infuse-me" element={<InfuseMe />} />
          <Route path="/realisations/actua-geveze" element={<ActuaG />} />
          <Route path="/realisations/edago" element={<Edago />} />
          <Route path="/realisations/les-ruchers" element={<LesRuchers />} />
          <Route path="/realisations/sbc-dol" element={<Dol />} />
          <Route path="/realisations/les-miels" element={<LesMiels />} />
          <Route path="/realisations/liffre-cormier-communaute" element={<Liffre />} />
          <Route path="/realisations/les-cafes-breizhiliens" element={<Cafes />} />
          <Route path="/realisations/bayman" element={<Bayman />} />
          <Route path="/realisations/smictom" element={<Smictom />} />
          <Route path="/realisations/pommeraie" element={<Pommeraie />} />
          <Route path="/realisations/terres-et-cereales" element={<Tetc />} />
          <Route path="/realisations/petit-breton" element={<PetitBreton />} />
          <Route path="/realisations/bodet" element={<Bodet />} />
          <Route path="/realisations/eaux-et-vilaine" element={<Eaux />} />
          <Route path="/laboratoire" element={<Laboratoire />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/politique-de-confidentialite" element={<Politique />} />
          <Route path="/mentions-legales" element={<Mentions />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
