import React, { Fragment, useRef, useEffect, useState } from "react";
import CustomCursor from "../CustomCursor";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";
import ContactForm from "../component/ContactForm";
import Burger from "../component/Burger";

export default function Contact() {

  return (
    <Fragment>
      <CustomCursor />
      <Burger />
      <ContactForm/>
    </Fragment>
  );
}
